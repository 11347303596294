/** from style guide **/

$sg_button_font-size: 1em;
$sg_button_font-weight: bold;
$sg_button_padding: 0.625rem 0.95rem;
$sg_button_border-radius: 0.4rem;
$sg_button_border:0;

/** The percentage complete indicator **/
$percentage_complete_indicator_width: 9em;
$percentage_complete_indicator_width_smaller: 7em; // used on narrow grids to save space

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// left-right gutters for mobile
// NB: viewport must be set to <standard-width> + 2*padding_mobile_sides
// e.g. <meta name="viewport" content="width=1040, initial-scale=1.0" >
// for $standard_width = 1000px;
// and $padding_mobile_sides = 20px;

$standard_width: 1000px;
$mobile_gutter: 20px; // each side

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// site message
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$site_message_max_height_initial: 20em;
$site_message_animation_delay: 5s;
$site_message_animation_duration: 1.5s;
$site_message_padding_top_initial: 1.5em;
$site_message_padding_bottom_initial: 1.5em;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Video Carousel thumbnail image heights.
// These must be set to fixed values for IE 11 to work.
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$video_carousel_homepage_thumb_height: 224px;
$video_carousel_videohomepage_thumb_height: 400px;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Header used by site versions e.g. preview or collaborate
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$site_version_warning_height: 3rem;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Previewer.
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$previewer_warning_height: 3rem;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Collaborator.
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$collaborate_warning_height: 3rem;
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
