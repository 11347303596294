nav.menu-explore h3{
  margin:0;
}
nav.menu-explore {
  text-align: center;
  width:100%;
}
nav.menu-explore ul {
}
nav.menu-explore ul li{
  display: inline-block;
  margin:10px;
}
nav.menu-explore ul li a{
  font-weight: 900;
}
