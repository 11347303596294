#main-menu-dialog {
    width: 100%!important;
    color: yellow;
}


.ui-dialog.xmas-message {

  background: none;
  border: none;
  padding: 0;

  #xmas-message-modal, #popup-message-modal {
    width: 100% !important;
    padding: 0;

    > img {
      width: 100%;
    }
  }

  // override jquery UI styles
  .ui-dialog-titlebar {

    padding-top: 0;
    position: absolute;
    top: -0.5rem;
    right: 0.5rem;

    .ui-dialog-titlebar-close {
      z-index: 2000;
      width: 1em;
      height: 1em;
      border-radius: 50%;
    }

  }

  a.hidden {
    height: 0;
    width: 0;
  }

  .icons {
    background-color: #bf6666; // #0055B8 is the blue proposed by Mary.
    .col:hover {
      background-color: grey;
    }
  }

}
