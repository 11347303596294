#advanced-search {
    margin-top: 2em;
    margin-bottom: 2em;
}

.category-search-page {
    .search-results {
        margin-top: 4em;
    }

    .accordion {
        padding: 0 0 0 1em;
    }

    .accordion-panel {
        padding: initial;
        //background-color: initial;
        //display: initial;
        overflow: initial;

        .form-control {
            input {
                display: inline;
                margin-right: 0.5em;
                float: none;
                margin-top: inherit;
            }
            label {
                width: initial;
                min-width: 20%;
                display: inline-block;
                padding: initial;
                margin-right: 0.5em;
                white-space: nowrap;
                font-size: 0.7em;
                //height: 20px;
                //width: 200px;
                //margin-top: 10px;
                //margin-left: 10px;
                //text-align: right;
                //clear: both;
                //float:left;
                //margin-right:15px;
            }
        }
    }

    button.get-results {
        min-width: 8em;
        text-align: center;
    }
}