// icons used on multiple pages. Single page icons can be svg elements.

.icon-video-camera::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0.4rem 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{url-encode-colour($black)}'%3e%3cpath d='M15.957 3.056v9.714c0 .25-.116.426-.348.527a.62.62 0 0 1-.223.045.531.531 0 0 1-.402-.17l-3.598-3.598v1.482c0 .708-.252 1.314-.755 1.817a2.478 2.478 0 0 1-1.817.755H2.528a2.478 2.478 0 0 1-1.817-.755 2.477 2.477 0 0 1-.754-1.817V4.77c0-.708.251-1.314.754-1.817a2.477 2.477 0 0 1 1.817-.754h6.286c.708 0 1.314.251 1.817.754.503.503.755 1.109.755 1.817v1.474l3.598-3.59a.53.53 0 0 1 .402-.169.64.64 0 0 1 .223.044.535.535 0 0 1 .348.527Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
}
.icon-chevron-left::after {
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin: 0.15rem 0 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{url-encode-colour($darkblue)}'%3e%3cpath d='M12.643 3.059 7.702 8l4.941 4.941a.572.572 0 0 1 .178.418c0 .162-.06.301-.178.419l-1.544 1.545a.573.573 0 0 1-.419.177.572.572 0 0 1-.419-.177L3.356 8.419A.576.576 0 0 1 3.18 8c0-.162.059-.301.176-.419L10.261.676A.575.575 0 0 1 10.68.5c.161 0 .301.059.419.176l1.544 1.545a.576.576 0 0 1 .178.42c0 .16-.06.3-.178.418Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem;
}
.icon-chevron-right::after {
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin: 0.15rem 0 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{url-encode-colour($darkblue)}'%3e%3cpath d='m12.644 8.419-6.905 6.904a.572.572 0 0 1-.419.177.573.573 0 0 1-.419-.177l-1.544-1.544a.573.573 0 0 1-.177-.419c0-.162.059-.301.177-.419L8.298 8 3.357 3.059a.572.572 0 0 1-.177-.419c0-.161.059-.301.177-.419L4.901.677A.573.573 0 0 1 5.32.5c.162 0 .301.059.419.177l6.905 6.904A.576.576 0 0 1 12.82 8a.576.576 0 0 1-.176.419Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem;
}
.icon-star::after {
  display: inline-block;
  flex-shrink: 0;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.15rem 0 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{url-encode-colour($black)}'%3e%3cpath d='M15.34 6.425c0 .129-.076.27-.229.423l-3.202 3.123.758 4.411c.006.041.009.101.009.177a.515.515 0 0 1-.093.312.31.31 0 0 1-.268.129.714.714 0 0 1-.354-.106l-3.96-2.082-3.961 2.082a.747.747 0 0 1-.353.106c-.124 0-.216-.043-.278-.129a.515.515 0 0 1-.093-.312c0-.036.007-.095.017-.177l.759-4.411L.881 6.848c-.146-.158-.22-.299-.22-.423 0-.218.164-.352.494-.406l4.428-.644 1.986-4.014C7.681 1.12 7.824 1 8.001 1c.175 0 .32.12.432.361l1.985 4.014 4.429.644c.328.054.493.188.493.406Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.8rem;
}
.icon-star-o::after {
  display: inline-block;
  flex-shrink: 0;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.15rem 0 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{url-encode-colour($black)}'%3e%3cpath d='m10.69 9.574 2.7-2.62-3.723-.548-1.668-3.368-1.666 3.368-3.723.548 2.7 2.62-.644 3.715 3.333-1.756 3.327 1.756-.636-3.715Zm4.649-3.149c0 .129-.076.27-.229.423l-3.202 3.123.758 4.412c.007.041.01.1.01.176 0 .293-.122.441-.363.441a.725.725 0 0 1-.352-.106l-3.962-2.082-3.96 2.082a.767.767 0 0 1-.353.106c-.123 0-.217-.043-.278-.128a.52.52 0 0 1-.092-.313c0-.036.006-.095.018-.176l.758-4.412L.881 6.848C.733 6.69.66 6.549.66 6.425c0-.218.165-.353.494-.406l4.428-.644 1.986-4.014C7.679 1.12 7.823 1 7.999 1c.178 0 .322.12.433.361l1.985 4.014 4.428.644c.33.053.494.188.494.406Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.8rem;
}
.icon-search::after {
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin: 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{url-encode-colour($black)}'%3e%3cpath d='M10.885 6.846c0-1.112-.396-2.063-1.186-2.853-.79-.79-1.741-1.185-2.853-1.185s-2.063.395-2.853 1.185-1.185 1.741-1.185 2.853.395 2.063 1.185 2.853 1.741 1.186 2.853 1.186 2.063-.396 2.853-1.186c.79-.79 1.186-1.741 1.186-2.853Zm4.615 7.5c0 .313-.114.583-.343.811a1.105 1.105 0 0 1-.811.343c-.324 0-.595-.114-.811-.343l-3.092-3.082a6.175 6.175 0 0 1-3.597 1.117 6.226 6.226 0 0 1-2.465-.5 6.347 6.347 0 0 1-2.029-1.352A6.37 6.37 0 0 1 1 9.312a6.248 6.248 0 0 1-.5-2.466c0-.859.167-1.681.5-2.465a6.364 6.364 0 0 1 1.352-2.029A6.364 6.364 0 0 1 4.381 1 6.243 6.243 0 0 1 6.846.5c.86 0 1.681.167 2.466.5a6.37 6.37 0 0 1 2.028 1.352 6.347 6.347 0 0 1 1.352 2.029c.334.784.5 1.606.5 2.465a6.175 6.175 0 0 1-1.117 3.597l3.091 3.092c.223.222.334.493.334.811Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem;
}
.icon-user::after {
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin: 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{url-encode-colour($black)}'%3e%3cpath d='M14.666 13.24c0 .757-.216 1.406-.651 1.947-.434.542-.956.813-1.568.813H3.552c-.611 0-1.134-.271-1.568-.813-.434-.541-.651-1.19-.651-1.947 0-.591.029-1.148.088-1.673.06-.524.169-1.051.329-1.583.16-.531.362-.986.609-1.364.247-.378.573-.688.979-.928a2.714 2.714 0 0 1 1.402-.359c.909.889 1.995 1.334 3.26 1.334 1.264 0 2.35-.445 3.26-1.334.528 0 .995.12 1.401.359.406.24.733.55.979.928.247.378.45.833.61 1.364.16.532.269 1.059.328 1.583.059.525.088 1.082.088 1.673ZM12 4a3.854 3.854 0 0 1-1.172 2.828C10.046 7.609 9.104 8 8 8c-1.105 0-2.047-.391-2.829-1.172A3.857 3.857 0 0 1 4 4c0-1.104.391-2.047 1.171-2.828C5.953.391 6.895 0 8 0c1.104 0 2.046.391 2.828 1.172A3.854 3.854 0 0 1 12 4Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem;
}
.icon-times-thin::after {
  display: flex;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin: 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{url-encode-colour($black)}'%3e%3cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 2rem;
}