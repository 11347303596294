.snippet-container.eau-webcasts, .snippet-container.horia-webcasts, .snippet-container.bespoke.webcast.registration {
    text-align: center;

    .card {
        color: white;
        padding: 1em;
        border-radius: 2rem;
        border: none;
    }

    .card.series-title {
        background: rgb(0,77,153);
        background: linear-gradient(87deg, rgba(0,77,153,1) 0%, rgba(114,181,47,1) 100%);
        font-size: 2.7em;
        line-height: 1.1em;
        font-weight: bold;
        font-family: Karbon-bold, sans-serif;
    }

    .card.webcast {
        padding: 0.5em;
        border: solid 2px white;

        .card-title {
            font-family: Karbon-bold, sans-serif;
            font-size: 1.7em;
            min-height: 9em;
            line-height: 1.2em;
            padding-top: 0.5em;

            @media (max-width: 767px) {
                min-height: 4.5em;
            }

        }

        .webcast-date {
            font-family: Karbon-semibold, sans-serif;
            font-size: 1.4em;
            margin-bottom: 0.2em;
            @media (max-width: 767px) {
                margin-bottom: 1em;
            }
        }

        .webcast-time {
            font-family: Karbon-light, sans-serif;
            font-size: 1.3em;
            margin-bottom: 0.4em;
            @media (max-width: 767px) {
                margin-bottom: 1em;
            }
        }

        .webcast-register {
            font-family: Karbon, sans-serif;
            font-size: 1.7em;
            padding: 0.5em;
            background-color: white;
            color: black;
            line-height: 1em;
            width: 70%;
        }

        .webcast-body {
            margin-bottom: 1em;
        }

        &:hover {
            border-color: grey;
            .webcast-register {
                font-family: Karbon-semibold, sans-serif;
            }
        }
    }


    .card.webcast-1 {
        background: rgb(0,140,106);
        background: linear-gradient(87deg, rgba(0,140,106,1) 0%, rgba(106,172,76,1) 100%);
    }

    .card.webcast-2 {
        background: rgb(0,78,153);
        background: linear-gradient(87deg, rgba(0,78,153,1) 0%, rgba(6,135,199,1) 100%);
    }

    .card.webcast-3 {
        background: rgb(108,173,76);
        background: linear-gradient(87deg, rgba(108,173,76,1) 0%, rgba(0,140,106,1) 100%);
    }

}

.snippet-container.horia-webcasts {
    .card.webcast-1, .card.webcast-2 {
        background: rgb(0,78,153);
        background: linear-gradient(87deg, rgba(0,78,153,1) 0%, rgba(6,135,199,1) 100%);
    }
    .card.webcast {
        .card-title {
            min-height: 5em;
        }
    }
}

.snippet-container.bespoke.webcast.registration.ct-mh-5 {
    .card.webcast {
        .card-title {
            min-height: 5em;
        }
    }
}

.snippet-container.bespoke.webcast.registration.bg-blue {
    .card.webcast {
        background: rgb(0,78,153);
        background: linear-gradient(87deg, rgba(0,78,153,1) 0%, rgba(6,135,199,1) 100%);
    }
}

.snippet-container.bespoke.webcast.registration.ct-mh-6 {
    .card.webcast {
        .card-title {
            min-height: 6em;
        }
    }
}

.snippet-container.bespoke.webcast.registration.cp-1 {
    .card.webcast {
        padding: 1em;
    }
}
