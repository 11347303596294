.site-version.warning {
    background-color: black;
    color: gold;
    font-weight: bold;

    opacity: 0.5;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 50;

    // These can be the same whether or not responsive.
    // The idea is to have the text on the middle of three lines.
    // Using rem as unit so fixed regardless of any base font size changes.
    height: 3rem;
    line-height: 1rem;

    // This is the default font size. See below for media query using vw.
    font-size: 1em;


    .warning-text {
        padding: 1rem;
    }

    .content-status {
        .inactive, .embargoed {
            color: red;
        }
    }

    .select.country {
        border: solid 1px gold;
        padding: 3px 6px;
        border-radius: 5px;
        position: relative;
        top: -3px;
    }

    .select.country:hover {
        cursor: pointer;
        border: solid 1px grey;
    }

    .right {
        float: right;
        margin-right: 3em;
    }

}

@media (max-width: 1000px) {
    .site-version.warning {
        // This uses vw (% of viewport width) so the text size changes and does not wrap around on small screens.
        font-size: 1.75vw;
    }
}

.ui-dialog.country.selector {
    background-color: grey;
    border: none;
    padding: 0;
    z-index: 5000;
    font-size: 0.85em;

    .ui-widget-content {
        color: white;
    }

    .ui-dialog-titlebar {
        height: inherit;
    }

    .ui-dialog-titlebar-close {
        width: 30px;
        height: 30px;
    }

    .country.button {
        width: 4em;
        margin: 0.35em;
        text-align: center;
        padding: 0.25em;
    }
    .country.button.current {
        color: white;
        background-color: grey;
    }
    .country.button.active {
        color: white;
        background-color: $sg_blue;
    }
    .country.button:hover {
        cursor: pointer;
    }

    table {
        width: 100%;
        margin-bottom: 2em;
        td {
            line-height: 1.3em;
            padding: 0.35em;
            border: solid 1px darkgrey;
        }
        td.c1 {
            width: 20%;
        }
        td.c2 {
            width: 60%;
        }
        td.c3 {
            width: 10%;
            text-align: center;
        }
        td.c4 {
            width: 10%;
            text-align: center;
        }
    }

    button.browse {
        cursor: pointer;
    }

    button.browse.active {
        color: $sg_green;
        background-color: $sg_blue;
        font-weight: bold;
    }

    .hidden {
        visibility: hidden;
    }

    .region {
        margin-bottom: 1em;
    }

    .flash-message {
        background-color: white;
        color: green;
        padding: 1em;
        font-size: 2em;
        line-height: 2em;
        width: auto;
        text-align: center;
        border-radius: 5px;
    }

    .flash-message.hidden {
        font-size: 0;
        height: 0;
        padding: 0;
    }

}