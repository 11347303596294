.collaborate.warning {
    color: #40c79c;
}

body.collaborate {
    padding-top: $site_version_warning_height;
}

body.collaborate.login {
    #page-head, #page-foot, h1 {
        display: none;
    }
    #login-content {
        .buttons.row a {
            display:none;
        }
        form {
            .row {
                margin-top: 1em;
            }
        }
        .alert {
            display: none;
        }
    }
}
