ol#wo-breadcrumbs.breadcrumb{
  width:100%;
  display:block;
  float:left;
  background-color:#dfe3ec;
  list-style: none;
  margin: 0;
  padding:10px;
}

ol#wo-breadcrumbs.breadcrumb li{
  float:left;
  padding-top:5px;
  padding-bottom:5px;
}

ol#wo-breadcrumbs.breadcrumb .separator{
  padding:5px;
}