@mixin boxedBright() {
    border: solid 3px $sg_green;
    border-radius: 0.5em;
}
@mixin boxed() {
    @include boxedBright();
    padding: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
}
