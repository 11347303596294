.cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    place-content: center;
    gap: 1rem;
}

.bespoke-card {
    border-radius: 4px;
    background-color: #ffff;
    box-shadow: 0 5px 10px -3px rgba($color: #000000, $alpha: 0.5);

    img {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        width: 100%;
    }

    article {
        padding: 1em;
    }
    article.content {
        min-height: 5em;
        .title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4; /* number of lines to show */
            line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    }
    article.resource-type {
        font-weight: bold;
        margin: auto auto 0;
    }
}