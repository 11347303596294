#e-learning-module-container.module-player {
  position:relative;
  padding-top:65%;
  margin-bottom:40px;
}
#e-learning-module-container.module-player iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  //background-color: $lightgrey;
}
.e-learning-flag-container {
  .e-learning-flag {
    width: 100%;
    position: relative;
    height: 5px;
  }
    margin-bottom: 0.5em;

}
.e-learning-thumbnail{
  background-color:$lightgrey;
}
.e-learning-flag-course{
  background-color:$blue;
}
.e-learning-flag-module{
  background-color:$lightgreen;
}
.e-learning-flag-label{
  color: $white;
  padding:5px;
}
.e-learning-flag-label-course{
  background-color:$blue;
}
.e-learning-flag-label-module{
  color: $textblue;
}
.e-learning-course-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;

  article {
    width: 48%;
    h2 {
      color: $textblue;
    }
  }
  .course-detail {
    width: 48%;
    //display:block;
    img {
      width: 100%;
      display: block;
    }
  }
}
.course-button-bar {
    border-bottom: 1px solid $horizontal_divider_line;
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    button {
        margin: 0 1em 0 0 !important;
    }
    button:hover {
        cursor: pointer;
    }
    .btn {
    }
}
.module-run,
.module-intro,
.module-reflect
{
  .content-specific-nav {
    margin-bottom: 2em;
  }

  .page-title {
    margin-bottom: 0.5em;
  }

  h1, h2
  {
    color: $sg_blue;
    font-size: 1.5em;
    margin-top: 1.5em;
    margin-bottom: 1em;
  }
  h2 {
    font-size: 1.1em;
  }

    .module-not-found {
        height: 5em;
    }
}

.elearning-descriptive-text {
    p {
        margin-top: 6pt;
        margin-bottom: 6pt;
    }
    ul {
        list-style: disc inside none;
    }
    .addThis {
        margin-bottom: 0.5em;
    }
    a {
        color: $journalArticleLinkStandardColor;
        text-decoration: underline;
        @include word-wrap();
    }
    a:hover {
        color: $journalArticleLinkHoverColor;
    }
    a:active {
        color: $journalArticleLinkActiveColor;
    }
}

.elearning-module {
    margin-bottom: 3em;
    .button-bar {
        line-height: 1em;
    }
}

.module-run {
    margin-bottom: 4em;
    .button-bar {
        .addthis-container {
            .cta {
                margin-top: 1em;
                margin-left: 2em;
                margin-right: 1em;
                line-height: 34px;
            }
            a {
                position: relative;
                bottom: -4px;
                padding: 0;
            }
        }
    }
}

.elearning-courses-grid {
    .grid-item {
      background-color: unset;
      margin-bottom: 4em;
      width:48%;
      padding:0;
      img {
        width: 100%;
        display: block;
      }
    }
    .grid-item > a:nth-child(2) {
        flex-grow: 1;
    }
}


.course-browse,
.module-browse
{
  .grid-item {
    width: 32%;
    font-size: $font_size_reduced;
  }
}

.image-16x9 {
  @include force_image_16x9();
}

.elearning-registration-prompt {
    .registration-info {
        color: $textblack;
        font-weight: bold;
        margin-bottom: 0.25em;
    }
    .registration-sub-info {
        color: $textgrey;
        margin-bottom: 1.5em;
    }
    @include registration-cta-bar();
}

#elearning-proceed-completion-btn {
    a.reflection-placeholder {
        display: none;
    }
    a.reflection {
        display: inline-block;
    }
}
#elearning-proceed-completion-btn.disabled {
    a.reflection-placeholder {
        display: inline-block;
    }
    a.reflection {
        display: none;
    }
}

.group-button-wrapper {

    min-width: 5em;
    position: relative;

    button.group {
        border: solid 3px #233149;
        margin: 0!important;
        width: 100%;
        height: 100%;
        background-size: cover;
    }
    button.group.city_cancer_challenge {
        background-image: url("/images/original/42084");
        background-repeat: no-repeat;
        background-color: #21314a;
        background-position-x: center;
        background-size: contain;
        border-radius: 5px;
    }
    .overlay {
        z-index: 10;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: grey;
        @include set-opacity-fractional(0.6);
        border-radius: 6px;
    }
    .overlay:hover {
        @include set-opacity-fractional(0.3);
    }

    button.joined {
        border: solid 3px $sg_green;
    }
}

body.module-player {
  .module-run {
    width: 100%;
    height: 100%;

    .iframe-column {
      background-color: lightgrey;
      .module-player {
        height: 95%;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    .buttons-column {
      background-color: #b5b54b;
    }

  }

}

@media screen and (min-width: 0px) and (max-width: 7670px) and (orientation: portrait) {
  body.module-player {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

