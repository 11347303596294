@mixin registration-cta-bar() {
    .registration-cta-bar {
        font-size: $font_size_header;
        margin-bottom: 1.5em;
        .btn {
            margin-right: 1em;
        }
        a.login {
            span.question {
                color: $textgrey;
            }
            span.login {
                color: $textblue;
                font-weight: bold;
            }
            span.login:hover {
                @include menu-hover-state-using-margin();
            }
        }
    }
}
