@mixin sg-btn() {
    font-size: $sg_button_font-size;
    font-weight: $sg_button_font-weight;
    padding: $sg_button_padding;
    border-radius: $sg_button_border-radius;
    border: $sg_button_border;
}

@mixin sg-btn-slim() {
    font-size: $font_size_reduced;
    font-weight: $sg_button_font-weight;
    padding: .4em 1em;
    border-radius: .2em;
    border: $sg_button_border;
}

@mixin sg-btn-yellow() {
    background-color: $sg_green;
    color: $sg_blue;
}

@mixin sg-btn-blue() {
    background-color: $sg_blue;
    color: $sg_white;
}

@mixin sg-btn-blue-hover() {
    background-color: $sg_blue_button_hover;
}

@mixin footer-card-button() {
    background-color: $sg_blue;
    color: $sg_white;
    font-size: $font_size_reduced;
    font-weight: bold;
    padding: .4em 1em;
    border-radius: .2em;
    border: $sg_button_border;
    margin: auto;
    width: 7em;
}
@mixin footer-card-button-hover() {
    background-color: #1974d7;
    color: $sg_white;
}



