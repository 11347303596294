.infobox {
    border: solid 3px $sg_green;
    border-radius: 1em;
    padding: 1.5em;

    button.donate {
        //float: right;
    }
}

.static-page,
.video-copy
{
    .page-title {
        margin-bottom: 1em;
    }
    p {
        margin-bottom: 1em;
        a {
            text-decoration: underline;
        }
    }
    ul li {
        a {
            text-decoration: underline;
        }
        margin-bottom: 0.5em;
    }
    ul > li {
        margin-left: 2em;
    }
    h2 {
        color: $sg_blue;
        font-size: 1.2em;
        line-height: 1em;
        a {
            color: $sg_blue;
        }
        margin-top: 1em;
        margin-bottom: 0.5em;
    }
    table td {
        vertical-align: unset;
    }

    // Links styled as buttons with helper classes
    a.button {
        @include sg-btn-blue();
        border-radius: 0.25em;
        display: inline-block; // so margin tweaks work
        text-align: center; // looks better centered if the button text wraps (?)
    }
    a.button:hover {
        @include sg-btn-blue-hover();
    }
    a.button.underline-on-hover {
        text-decoration: none;
    }
    a.button.underline-on-hover:hover {
        text-decoration: underline;
    }
}

.static-page-container.about-us, .static-page-container.support-us {
    margin-right: 1em;
    h2.title {
        @include page-title;
        margin-bottom: 1em;

    }
    .icon {
        border-top: solid 2px lightgrey;
        padding-top: 1em;
    }
    p {
        text-align: left!important;
        a {
                font-weight: bold;
                text-decoration: none;
        }
        em.refs {
            font-weight: bold;
        }
    }
    .boxed {
        border: solid 3px $sg_green;
        border-radius: 0.5em;
        padding: 1em;
        margin-top: 2em;
        margin-bottom: 2em;
    }
    .info-donate {
        display: none;
    }
    .donate-button {
        display: initial!important;
        input[type=submit] {
            @include sg-btn-blue();
            @include sg-btn-slim();
        }
        input[type=submit]:hover {
            @include sg-btn-blue-hover();
        }
    }
    .donate-button.right {
        text-align: right;
    }
    .intro {
        img {
            display: block!important;
            height: 13em;
            float: right;
            margin-left: 0.5em;
        }
    }
    h2 {
        font-weight: bold;
        img {
            display: inline-block!important;
            height: 2em;
            float: left;
            margin-right: 1em;
        }
        line-height: 2.1em;
    }
    ul {
        list-style-position: outside;
        line-height: 1.5em;
        margin-bottom: 1.5em;
        li {
            margin-left: 2em;
            margin-bottom: 1.3em;
        }
    }
    .affiliations {
        display: block!important;
        border-top: solid 2px lightgrey;
        margin-top: 2em;
        padding-top: 1em;
        .panel {
            display: inline-block;
            vertical-align: top;
            h2 {
                border-top: initial;
                padding-top: initial;
                margin-top: 0;
                margin-bottom: 0.5em;
                line-height: 1.3em;
            }
        }
        img {
            width: 7em;
            margin-right: 1em;
            vertical-align: top;
        }
        .row2 {
            margin-top: 2em;
            .panel1 {
                max-width: 60%;
                img {
                    width: 6em;
                }
            }
            .panel2 {
                max-width: 40%;
                img {
                    width: 13em;
                }
            }
        }
    }
    .leading-block {
        @include leading-block;
    }
    .article {
        line-height: 150%;
    }
}
