.general-browse-results {
    .content-specific-nav {
        margin-bottom: 2em;
    }

    .elearning-courses-grid {
        font-size: $font_size_reduced;
        .grid-item {
            width: 32%;
        }
        .elearning-meta-row {
            .meta-detail {
                padding-right: 0.5em;
                .content {
                    padding-right: 0.5em;
                }
                .content.e-learning-percentage-complete {
                    span {
                        width: 7em;
                        bottom: 0.3em;
                    }
                }
            }
            .meta-detail:last-child .content {
                width: $percentage_complete_indicator_width_smaller;
            }
        }
    }
    .results-grid.conferences {
        font-size: inherit;
    }

}