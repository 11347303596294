.maintenance {
    .page-title {
        margin-bottom: 5em;
    }
    .disabled, .window {
        font-size: 1.5em;
        margin-bottom: 1em;
        text-align: center;
        color: darkslategrey;
    }
}

.sitewide-maintenance-message-wrapper {
    text-align: center;
    padding: 1em 1em;
    p {
        margin: 0;
    }
    background-color: grey;
    color: white;
    border-bottom: solid 2px white;
}