dialog {

    border: solid 1px $lightgrey;
    border-radius: .5em;
    width: 30em;
    z-index: 100;
    left: 16em;
    @include shadow();

    .close-button {
        text-align: right;
        color: $sg_blue;
        margin-bottom: 1em;
    }
    .close-button:hover {
        color: $sg_button_hover;
        cursor: pointer;
    }
    .modal-header {
        color: $sg_blue;
        font-size: 1.25em;
        font-weight: bold;
        margin-bottom: 1em;
    }
    .modal-sub-header {
        margin-bottom: 2em;
    }
    .modal-body {
        margin-bottom: 1em;
        border-bottom: solid 1px $sg_gradient_grey_top;
        font-size: $font_size_reduced;
        .video-rating-question-container {
            margin-bottom: 1.5em;
            .question {
                display: inline-block;
                width: 46%;
                margin-right: 4%;
            }

            .rating-container {
                display: inline-block;
                vertical-align: top;
                margin-top: 0.5em;
                .rating {
                    button {
                        background: none;
                        border: none;
                        filter: invert(83%) sepia(0%) saturate(786%) hue-rotate(135deg) brightness(101%) contrast(88%);
                        font-size: 1.5em;
                    }
                    .icon-star::after {
                        width: 1.2rem;
                        height: 1.2rem;
                        background-size: 1.2rem;
                    }
                    .icon-star:hover, .star-rating-checked, .star-rating-checked-temporary {
                        filter: invert(78%) sepia(64%) saturate(543%) hue-rotate(13deg) brightness(97%) contrast(95%);
                    }
                    button:focus {
                        outline: none;
                    }
                }
            }
        }

    }

    footer {
        float: right;
    }
}
dialog.login-modal {
    font-size: 1em;
    text-align: center;
    left: 0;
    .modal-body {
        border-bottom: none;
        font-size: 100%;
        a.btn {
            margin: 0 1em;
        }
    }
    footer {
        float: none;
    }
    @include registration-cta-bar();
}
.dialog-background-cover {
    z-index: 50;
    opacity: 0.6;
    background: white;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
}
.dialog-background-cover.hidden {
    display: none;
}
.dialog-start:hover {
    cursor: pointer;
}
dialog.article-citation {
    .modal-body {
        margin-bottom: 2em;
        border-bottom: none;

    }
    .close-button {
        margin-bottom: 0;
    }
    .btn {
        float: right;
    }
    .btn.hidden {
        display: none;
    }
}

dialog.journal-rating-modal {
    position: fixed;
    left: 10em;
    top: 0;
    margin: 1.75rem auto;
    z-index: 51;
}

dialog.group.login-modal {
    .confirm {
        margin-bottom: 2em;
        input[type='checkbox'] {
            width: 1.5em;
            height: 1.5em;
            background: white;
            border-radius: 5px;
            border: 2px solid #555;
        }
        input[type='checkbox']:checked {
            background: lightgrey;
        }
        label {
            position: relative;
            top: -0.4em;
            margin-right: 1em;
        }
    }
}