@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i&display=swap");
@import url(~dialog-polyfill/dialog-polyfill.css);
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }
  a:hover {
    color: #0a58ca; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }
  .btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #0d6efd;
    background-color: transparent; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #6c757d;
    background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #198754;
    background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #0dcaf0;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc107;
    background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f8f9fa;
    background-color: transparent; }

.btn-outline-dark {
  color: #212529;
  border-color: #212529; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #212529;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline; }
  .btn-link:hover {
    color: #0a58ca; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #0d6efd; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #0a58ca; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #0d6efd; }
  .link-primary:hover, .link-primary:focus {
    color: #0a58ca; }

.link-secondary {
  color: #6c757d; }
  .link-secondary:hover, .link-secondary:focus {
    color: #565e64; }

.link-success {
  color: #198754; }
  .link-success:hover, .link-success:focus {
    color: #146c43; }

.link-info {
  color: #0dcaf0; }
  .link-info:hover, .link-info:focus {
    color: #3dd5f3; }

.link-warning {
  color: #ffc107; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37; }

.link-light {
  color: #f8f9fa; }
  .link-light:hover, .link-light:focus {
    color: #f9fafb; }

.link-dark {
  color: #212529; }
  .link-dark:hover, .link-dark:focus {
    color: #1a1e21; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d6efd !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #212529 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/** Base colors **/
/** from style guide **/
/** style guide: buttons **/
/** style guide: gradients **/
/** TEXT **/
/** LINES **/
/** LINKS **/
/** ERRORS **/
/** Alert Success **/
/** Alert Warning **/
/** Alert Error **/
/** Journal Articles have specific requirements **/
/** Journal branding **/
/** from style guide **/
/** The percentage complete indicator **/
/* from https://css-tricks.com/snippets/css/cross-browser-opacity/ */
.lc-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 1em; }

.lc-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2em; }

.lc-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 3em; }

.lc-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 4em; }

.lc-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 5em; }

.lc-6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
  height: 6em; }

.lc-7 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  line-clamp: 7;
  -webkit-box-orient: vertical;
  height: 7em; }

.lc-8 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
  height: 8em; }

.http_code {
  margin-bottom: 150px; }
  .http_code h1, .http_code .h1 {
    margin-bottom: 1em; }
  .http_code p {
    line-height: 1.2em; }
  .http_code ol {
    list-style: disc inside; }
  .http_code a {
    color: #0056b4; }
  .http_code a:hover {
    color: #1974d7; }

@media (min-width: 992px) {
  .http_code p {
    line-height: 2em; } }

a {
  text-decoration: none;
  color: #2d2d2d; }

a.active {
  color: #0056b4; }

a:hover {
  color: #599fe2; }

.cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  place-content: center;
  gap: 1rem; }

.bespoke-card {
  border-radius: 4px;
  background-color: #ffff;
  box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.5); }
  .bespoke-card img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%; }
  .bespoke-card article {
    padding: 1em; }
  .bespoke-card article.content {
    min-height: 5em; }
    .bespoke-card article.content .title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical; }
  .bespoke-card article.resource-type {
    font-weight: bold;
    margin: auto auto 0; }

/* This is the percentage complete widget */
/* shape and colour the parent */
.pc-completeness {
  border-radius: 1em;
  border: solid 1px #ccc;
  height: 1.3em;
  /* style the background */
  /* one per percentage for precision ! */ }
  .pc-completeness .background {
    background-color: #c6d420;
    opacity: 0.5;
    height: 1.3em;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em; }
    .pc-completeness .background span {
      position: relative;
      bottom: 0.12em;
      left: 0.25em;
      width: 9em;
      display: inline-block;
      text-align: center; }
  .pc-completeness .p0 {
    width: 0%; }
  .pc-completeness .p1 {
    width: 1%; }
  .pc-completeness .p2 {
    width: 2%; }
  .pc-completeness .p3 {
    width: 3%; }
  .pc-completeness .p4 {
    width: 4%; }
  .pc-completeness .p5 {
    width: 5%; }
  .pc-completeness .p6 {
    width: 6%; }
  .pc-completeness .p7 {
    width: 7%; }
  .pc-completeness .p8 {
    width: 8%; }
  .pc-completeness .p9 {
    width: 9%; }
  .pc-completeness .p10 {
    width: 10%; }
  .pc-completeness .p11 {
    width: 11%; }
  .pc-completeness .p12 {
    width: 12%; }
  .pc-completeness .p13 {
    width: 13%; }
  .pc-completeness .p14 {
    width: 14%; }
  .pc-completeness .p15 {
    width: 15%; }
  .pc-completeness .p16 {
    width: 16%; }
  .pc-completeness .p17 {
    width: 17%; }
  .pc-completeness .p18 {
    width: 18%; }
  .pc-completeness .p19 {
    width: 19%; }
  .pc-completeness .p20 {
    width: 20%; }
  .pc-completeness .p21 {
    width: 21%; }
  .pc-completeness .p22 {
    width: 22%; }
  .pc-completeness .p23 {
    width: 23%; }
  .pc-completeness .p24 {
    width: 24%; }
  .pc-completeness .p25 {
    width: 25%; }
  .pc-completeness .p26 {
    width: 26%; }
  .pc-completeness .p27 {
    width: 27%; }
  .pc-completeness .p28 {
    width: 28%; }
  .pc-completeness .p29 {
    width: 29%; }
  .pc-completeness .p30 {
    width: 30%; }
  .pc-completeness .p31 {
    width: 31%; }
  .pc-completeness .p32 {
    width: 32%; }
  .pc-completeness .p33 {
    width: 33%; }
  .pc-completeness .p34 {
    width: 34%; }
  .pc-completeness .p35 {
    width: 35%; }
  .pc-completeness .p36 {
    width: 36%; }
  .pc-completeness .p37 {
    width: 37%; }
  .pc-completeness .p38 {
    width: 38%; }
  .pc-completeness .p39 {
    width: 39%; }
  .pc-completeness .p40 {
    width: 40%; }
  .pc-completeness .p41 {
    width: 41%; }
  .pc-completeness .p42 {
    width: 42%; }
  .pc-completeness .p43 {
    width: 43%; }
  .pc-completeness .p44 {
    width: 44%; }
  .pc-completeness .p45 {
    width: 45%; }
  .pc-completeness .p46 {
    width: 46%; }
  .pc-completeness .p47 {
    width: 47%; }
  .pc-completeness .p48 {
    width: 48%; }
  .pc-completeness .p49 {
    width: 49%; }
  .pc-completeness .p50 {
    width: 50%; }
  .pc-completeness .p51 {
    width: 51%; }
  .pc-completeness .p52 {
    width: 52%; }
  .pc-completeness .p53 {
    width: 53%; }
  .pc-completeness .p54 {
    width: 54%; }
  .pc-completeness .p55 {
    width: 55%; }
  .pc-completeness .p56 {
    width: 56%; }
  .pc-completeness .p57 {
    width: 57%; }
  .pc-completeness .p58 {
    width: 58%; }
  .pc-completeness .p59 {
    width: 59%; }
  .pc-completeness .p60 {
    width: 60%; }
  .pc-completeness .p61 {
    width: 61%; }
  .pc-completeness .p62 {
    width: 62%; }
  .pc-completeness .p63 {
    width: 63%; }
  .pc-completeness .p64 {
    width: 64%; }
  .pc-completeness .p65 {
    width: 65%; }
  .pc-completeness .p66 {
    width: 66%; }
  .pc-completeness .p67 {
    width: 67%; }
  .pc-completeness .p68 {
    width: 68%; }
  .pc-completeness .p69 {
    width: 69%; }
  .pc-completeness .p70 {
    width: 70%; }
  .pc-completeness .p71 {
    width: 71%; }
  .pc-completeness .p72 {
    width: 72%; }
  .pc-completeness .p73 {
    width: 73%; }
  .pc-completeness .p74 {
    width: 74%; }
  .pc-completeness .p75 {
    width: 75%; }
  .pc-completeness .p76 {
    width: 76%; }
  .pc-completeness .p77 {
    width: 77%; }
  .pc-completeness .p78 {
    width: 78%; }
  .pc-completeness .p79 {
    width: 79%; }
  .pc-completeness .p80 {
    width: 80%; }
  .pc-completeness .p81 {
    width: 81%; }
  .pc-completeness .p82 {
    width: 82%; }
  .pc-completeness .p83 {
    width: 83%; }
  .pc-completeness .p84 {
    width: 84%; }
  .pc-completeness .p85 {
    width: 85%; }
  .pc-completeness .p86 {
    width: 86%; }
  .pc-completeness .p87 {
    width: 87%; }
  .pc-completeness .p88 {
    width: 88%; }
  .pc-completeness .p89 {
    width: 89%; }
  .pc-completeness .p90 {
    width: 90%; }
  .pc-completeness .p91 {
    width: 91%; }
  .pc-completeness .p92 {
    width: 92%; }
  .pc-completeness .p93 {
    width: 93%; }
  .pc-completeness .p94 {
    width: 94%; }
  .pc-completeness .p95 {
    width: 95%; }
  .pc-completeness .p96 {
    width: 96%; }
  .pc-completeness .p97 {
    width: 97%; }
  .pc-completeness .p98 {
    width: 98%;
    border-radius: 0.5em; }
  .pc-completeness .p99 {
    width: 99%;
    border-radius: 0.75em; }
  .pc-completeness .p100 {
    width: 100%;
    border-radius: 1em; }

nav ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/*---------------------------- ecancer logo -----------------------------*/
.container-row.logo {
  align-items: center; }
  .container-row.logo img {
    height: 3em; }
  .container-row.logo .col_2 {
    text-align: center; }

.head-left {
  float: left;
  width: 50%; }
  .head-left img {
    height: 3em; }

/*------------------------------------------------------------------------*/
.container-logo {
  float: left;
  width: 50%; }

.container-head {
  color: white;
  padding-top: 0.6em;
  padding-bottom: 0.6em; }

.container-menu-primary {
  padding-top: 1em;
  padding-bottom: 0.25em; }
  .container-menu-primary .search-generic-inline .field-group {
    position: inherit !important; }
    .container-menu-primary .search-generic-inline .field-group input[type="text"] {
      padding: 0.2em 0.8em; }

.container-conference {
  padding: 20px 0;
  background-color: #ccc; }

#logo {
  z-index: 100; }

.container-row .btn-donate {
  margin: 0.25em 0;
  float: right; }

.donate .btn-green {
  border-radius: .25rem; }

#search-header-home {
  background-color: #DFE3EC;
  padding: 10px;
  padding-bottom: 5px;
  padding-top: 5px; }

#search-header-home form div#home {
  width: 100%; }

#search-header-home form div#home div {
  float: left;
  margin-left: 7px; }

#search-header-home .search-text {
  padding-right: 30px;
  padding-top: 10px; }

#search-header-home label, #search-header-home select, #search-header-home .search-text {
  margin-top: 5px; }

#search-header-home select {
  width: 162px; }

.hero-video {
  padding: 0; }

.hero-conference {
  padding: 0; }

.hero-conference .item-thumb {
  width: 300px; }

.hero-video .widget-contents img {
  width: 600px;
  max-height: none;
  max-width: none; }

.hero-video .arrow-bar {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 0;
  top: 100px; }

.hero-video .item-title {
  text-align: center; }

.hero-video .item-thumb {
  text-align: center; }

.hero {
  border: 1px solid #DFE3EC;
  padding: 20px;
  margin-bottom: 10px; }

.hero .video-player {
  margin-left: 20px; }

.hero h1, .hero .h1 {
  margin: 0;
  padding: 0; }

.hero-text {
  float: left;
  width: 50%; }

.hero-image-container {
  width: 300px;
  height: 150px;
  float: right;
  margin-left: 20px; }

.hero-image {
  width: 100%;
  margin-top: 10px;
  height: 150px;
  background-color: #DFE3EC; }

.hero-conference img {
  max-width: 150px;
  margin-right: 10px; }

.hero-text .btn-submit {
  width: 50px; }

.hero-text p {
  margin-top: 0; }

h1.hero-title, .hero-title.h1 {
  margin-bottom: 20px; }

div.journal-link-logos {
  margin-top: 20px;
  padding-left: 3px;
  text-align: center; }

.item-details {
  padding: 0.2em 0.3em;
  background-color: #DFE3EC; }

.item-details .article-published-date, .item-details .article-doi, .item-details .article-links {
  font-size: 0.8em; }

.article-links {
  text-align: right; }

.widget-list.widget-list-plain {
  border: none; }

.widget-list.widget-list-news {
  border-left: none;
  border-right: none; }

.widget-list.widget-list-plain .item-row {
  border: none; }

.page-title-sub {
  width: 100%;
  float: left; }

.page-title-sub-left {
  float: left;
  width: 50%;
  text-align: left; }

.page-title-sub-right {
  float: right;
  text-align: right; }

.page-title, .widget-title {
  margin: 0;
  line-height: 1em;
  font-size: 1.5em;
  font-weight: 600;
  color: #0056b4;
  border-left: solid 0.3em #cbd921;
  padding-left: 0.4em;
  margin-bottom: 0.3em; }
  .page-title a, .widget-title a {
    color: #0056b4; }
  .page-title .journal-title-branded .part2, .widget-title .journal-title-branded .part2 {
    color: #a6c7e9; }

.journal-article-title {
  margin: 0;
  line-height: 1.25em;
  font-size: 1.5em;
  color: #0056b4;
  margin-bottom: 0.3em; }

.journal-article-type {
  margin: 0.8em 0 0.3em;
  line-height: 1em;
  font-size: 1em;
  color: #0056b4;
  margin-bottom: 0.3em; }

.intro-image {
  float: right;
  margin-left: 15px;
  margin-bottom: 15px; }
  .intro-image .caption {
    margin: 0;
    font-size: 0.8em;
    font-style: italic; }

.image-hero-main {
  display: flex;
  justify-content: center;
  width: 100%; }

.image-hero-main img {
  max-height: 300px; }

#panel-hero-strap {
  background-color: #0056b4;
  color: #FFF;
  padding: 20px 0; }
  #panel-hero-strap .ms-color-2 {
    color: #cbd921; }
  #panel-hero-strap .issn {
    font-size: 0.75em;
    float: right; }

.sg-gradient {
  background: #0056b4;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwNjNjYyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc1JSIgc3RvcC1jb2xvcj0iIzAwNTZiNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDU1YmIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #0071e8 0%, #0056b4 75%, #0055bb 100%);
  /* FF3.6-15 */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #0071e8), color-stop(75%, #0056b4), color-stop(100%, #0055bb));
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(top, #0071e8 0%, #0056b4 75%, #0055bb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(top, #0071e8 0%, #0056b4 75%, #0055bb 100%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(top, #0071e8 0%, #0056b4 75%, #0055bb 100%);
  /* IE10 preview */
  background: linear-gradient(to bottom, #0071e8 0%, #0056b4 75%, #0055bb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$sg_gradient_blue_top', endColorstr='$sg_gradient_blue_bottom',GradientType=0 );
  /* IE6-8 */ }

.sg-gradient-grey {
  background: #c8ced6;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwNjNjYyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc1JSIgc3RvcC1jb2xvcj0iIzAwNTZiNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDU1YmIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #dfe3ec 0%, #c8c8c8 75%, #c8c8c8 100%);
  /* FF3.6-15 */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #dfe3ec), color-stop(75%, #c8c8c8), color-stop(100%, #c8c8c8));
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(top, #dfe3ec 0%, #c8c8c8 75%, #c8c8c8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(top, #dfe3ec 0%, #c8c8c8 75%, #c8c8c8 100%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(top, #dfe3ec 0%, #c8c8c8 75%, #c8c8c8 100%);
  /* IE10 preview */
  background: linear-gradient(to bottom, #dfe3ec 0%, #c8c8c8 75%, #c8c8c8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$sg_gradient_grey_top', endColorstr='$sg_gradient_grey_bottom',GradientType=0 );
  /* IE6-8 */ }

#page-foot p {
  color: #FFF;
  margin-top: 1px;
  width: 100%; }

#page-foot .container-conference h1, #page-foot .container-conference .h1 {
  text-align: center;
  margin: 20px 0;
  color: #FFF;
  line-height: 1.2em; }

#page-foot .container-site-links {
  padding-bottom: 2em; }
  #page-foot .container-site-links h2, #page-foot .container-site-links .h2 {
    margin: 0; }
  #page-foot .container-site-links p {
    margin: 0; }
  #page-foot .container-site-links div.col {
    padding: 2em 1em; }
    #page-foot .container-site-links div.col a {
      color: #e9ecf4; }
    #page-foot .container-site-links div.col a:hover {
      color: #2d2d2d; }
    #page-foot .container-site-links div.col p {
      color: #0056b4;
      padding-bottom: 1em;
      min-height: 2.5em; }
  #page-foot .container-site-links div.col:last-of-type {
    margin-right: 0; }
  #page-foot .container-site-links .bg-grey .btn-blue, #page-foot .container-site-links .bg-light-green .btn-blue {
    background-color: #0056b4;
    color: #e9ecf4;
    font-size: 0.8em;
    font-weight: bold;
    padding: .4em 1em;
    border-radius: .2em;
    border: 0;
    margin: auto;
    width: 7em; }
  #page-foot .container-site-links .bg-grey .btn-blue:hover, #page-foot .container-site-links .bg-light-green .btn-blue:hover {
    background-color: #1974d7;
    color: #e9ecf4; }
  #page-foot .container-site-links .bg-grey {
    background-color: #e4e7ea;
    border: solid 2px #dde1e6; }
  #page-foot .container-site-links .bg-light-green {
    background-color: #cbd921;
    border: solid 2px #dde1e6; }
  #page-foot .container-site-links form input[type="submit"].sg-btn-cta {
    background-color: #0056b4;
    color: #e9ecf4;
    font-size: 0.8em;
    font-weight: bold;
    padding: .4em 1em;
    border-radius: .2em;
    border: 0;
    margin: auto;
    width: 7em; }
  #page-foot .container-site-links form input[type="submit"].sg-btn-cta:hover {
    background-color: #1974d7;
    color: #e9ecf4; }

#page-foot .container-foot {
  padding: 20px 0 20px 0;
  background-color: #0056b4;
  color: #FFF; }
  #page-foot .container-foot div {
    font-size: 0.875em; }
  #page-foot .container-foot .foot-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  #page-foot .container-foot .foot-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end; }
  #page-foot .container-foot ul.foot-links {
    float: left;
    color: #FFF; }

#page-foot .copyright {
  text-align: right; }

/* NB: Ideally - everything in here should be within a form {} or be form specific via an id or class */
form label {
  width: 13em;
  line-height: 2em; }

form label.col-form-label.required::after {
  content: " *";
  color: red; }

form label.col-form-label.info::before {
  content: "i ";
  color: #599fe2;
  vertical-align: super;
  font-size: small;
  font-style: italic; }

input[type="text"], input[type=password], input[type=email], textarea {
  padding: 0.5em; }

input.border-round {
  border-radius: 20px;
  border: 1px solid #DDD; }

.btn-secondary {
  background-color: #FFF;
  padding: 10px;
  border: 1px solid #DDD; }

.btn-secondary:hover {
  background-color: #C8D51E;
  border-color: #C8D51E; }

input[type="submit"]:hover {
  cursor: pointer; }

/* Type Site form */
form[name="search_site"] input[type="text"] {
  width: 350px; }

form[name="search_site"] {
  margin: 0; }

#panel-search {
  text-align: center; }

#panel-search input[type=text]#news_search_keyword {
  width: 250px; }

#panel-search input[type=text]#journal_search_keyword {
  width: 200px; }

#panel-search input[type=text]#e_learning_search_keyword {
  width: 180px; }

#panel-search input[type=text]#conference_search_keyword {
  width: 200px; }

#panel-search input[type=text]#video_search_keyword {
  width: 330px; }

#panel-search form[name='news'] button,
#panel-search form[name='journal'] button #panel-search form[name='video'] button {
  padding-left: 10px;
  padding-top: 30px; }

select#news_conference,
select#video_conference {
  width: 200px; }

select#conference_region,
select#conference_country {
  width: 160px; }

select#journal_volume {
  width: 80px; }

.search-term {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border: 1px solid #DDD; }

#panel-search select {
  margin: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  height: 34px; }

#panel-search label {
  width: auto; }

#search-header-home label {
  color: #999;
  margin-right: 5px; }

#keyword-search-form .input-group-append {
  margin-left: -1px;
  position: absolute;
  right: -41px;
  height: 42px; }

#filter-container {
  padding: 5px;
  background-color: #f4f4f4; }

.filter-form .form-row {
  float: left;
  margin: 5px;
  margin-top: 10px; }

.filter-form .form-label {
  padding-top: 2px; }

#filter-container .pager {
  margin: 5px; }

#filter-container {
  margin-bottom: 20px; }

.form-input-container {
  width: 100%; }

input.form-control {
  box-sizing: border-box; }

.btn-right {
  float: right; }

.btn {
  padding: 10px; }

#page-registration, .like-registration {
  width: 100%; }
  #page-registration .page-title, .like-registration .page-title {
    margin-bottom: 2em; }
  #page-registration .regn-form-profile, #page-registration .regn-form-comms-prefs, #page-registration .regn-form-email-prefs, #page-registration .regn-form-categories, .like-registration .regn-form-profile, .like-registration .regn-form-comms-prefs, .like-registration .regn-form-email-prefs, .like-registration .regn-form-categories {
    margin-bottom: 2em; }
    #page-registration .regn-form-profile h2, #page-registration .regn-form-profile .h2, #page-registration .regn-form-comms-prefs h2, #page-registration .regn-form-comms-prefs .h2, #page-registration .regn-form-email-prefs h2, #page-registration .regn-form-email-prefs .h2, #page-registration .regn-form-categories h2, #page-registration .regn-form-categories .h2, .like-registration .regn-form-profile h2, .like-registration .regn-form-profile .h2, .like-registration .regn-form-comms-prefs h2, .like-registration .regn-form-comms-prefs .h2, .like-registration .regn-form-email-prefs h2, .like-registration .regn-form-email-prefs .h2, .like-registration .regn-form-categories h2, .like-registration .regn-form-categories .h2 {
      margin-bottom: 1em; }
  #page-registration .form-group, .like-registration .form-group {
    margin: 1em 0 0 3em; }
    #page-registration .form-group .question, .like-registration .form-group .question {
      width: 100%;
      margin-bottom: 1em; }
    #page-registration .form-group input, .like-registration .form-group input {
      border-radius: 0.3em; }
    #page-registration .form-group input[type=text], #page-registration .form-group input[type=email], #page-registration .form-group input[type=password], .like-registration .form-group input[type=text], .like-registration .form-group input[type=email], .like-registration .form-group input[type=password] {
      width: 100%; }
    #page-registration .form-group input[type=radio], .like-registration .form-group input[type=radio] {
      margin-right: 1em; }
    #page-registration .form-group label.single, .like-registration .form-group label.single {
      margin-left: 1em; }
  #page-registration .form-control label, .like-registration .form-control label {
    margin-right: 2em;
    margin-left: 0.25em; }
  #page-registration select, .like-registration select {
    border: 1px solid #ccc;
    padding: 0.5em;
    width: 100%;
    border-radius: 0.2em; }
  #page-registration .btn-row, .like-registration .btn-row {
    margin-top: 1em; }
  #page-registration #registration_email_preference_email_frequency, #page-registration .email-frequency, .like-registration #registration_email_preference_email_frequency, .like-registration .email-frequency {
    width: auto;
    margin-left: 1em; }
  #page-registration h3, #page-registration .h3, .like-registration h3, .like-registration .h3 {
    font-size: 1.1em;
    line-height: 1.1em;
    margin-bottom: 1em; }

.form-group-error label {
  color: #9a1e1e; }

.form-group-error input {
  border: 1px solid #6b1717;
  background-color: #eeabab; }

.form-group-error ul {
  list-style: none;
  color: #9a1e1e;
  margin: 0;
  padding: 0; }

.e-learning-reflect .content-specific-nav {
  margin-bottom: 3em; }

.e-learning-reflect .page-title {
  margin-bottom: 1.5em; }

.e-learning-reflect textarea#reflect_reflection {
  height: 20em;
  width: 100%;
  padding: 1em;
  margin-bottom: 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.search-generic-inline form {
  display: flex;
  text-align: center; }
  .search-generic-inline form .field-group {
    display: inline;
    float: initial; }
    .search-generic-inline form .field-group label {
      display: none; }
  .search-generic-inline form .field-group:nth-child(1),
  .search-generic-inline form .field-group:nth-child(2) {
    position: fixed;
    top: 0;
    left: 0; }
  .search-generic-inline form select {
    visibility: hidden;
    width: 0; }
  .search-generic-inline form input[type="text"] {
    padding: 0.4em 0.8em;
    text-align: right;
    border-radius: 1em;
    border: solid 1px #ccc;
    color: #646466; }
  .search-generic-inline form button {
    border: none;
    background: none;
    color: #ccc;
    font-size: 1.3em; }
    .search-generic-inline form button .icon-search::after {
      height: 1.3rem;
      width: 1.3rem;
      background-size: 1.3rem;
      filter: invert(88%) sepia(0%) saturate(0%) hue-rotate(318deg) brightness(96%) contrast(87%); }
  .search-generic-inline form button:hover {
    color: #646466; }

.content-specific-search {
  border-bottom: solid 1px #dedede;
  margin-bottom: 0.8em; }
  .content-specific-search form {
    display: flex;
    width: 100%; }
    .content-specific-search form .field-group {
      flex-shrink: 1;
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      float: none;
      padding: 0.4em 0.2em; }
      .content-specific-search form .field-group label {
        width: auto;
        font-size: 0.8em;
        color: #646466; }
      .content-specific-search form .field-group select {
        padding: 0.4em 0.8em;
        border-radius: 1em;
        background-color: white;
        border: solid 1px #ccc;
        font-size: 0.8em; }
      .content-specific-search form .field-group input[type="text"] {
        padding: 0.4em 0.8em;
        text-align: right;
        border-radius: 1em;
        border: solid 1px #ccc;
        font-size: 0.8em; }
      .content-specific-search form .field-group button {
        border: none;
        background: none;
        color: #ccc;
        font-size: 1.3em;
        flex: 1;
        padding: 0; }
    .content-specific-search form .field-group:last-child, .content-specific-search form .search-icon {
      background-color: #cbd921;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 3em; }
      .content-specific-search form .field-group:last-child .icon-search, .content-specific-search form .search-icon .icon-search {
        color: #0056b4; }
      .content-specific-search form .field-group:last-child .icon-search::after, .content-specific-search form .search-icon .icon-search::after {
        height: 1.3rem;
        width: 1.3rem;
        background-size: 1.3rem;
        filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%); }
    .content-specific-search form .field-group:last-child:hover, .content-specific-search form .search-icon:hover {
      background-color: #b3bd35;
      cursor: pointer; }
      .content-specific-search form .field-group:last-child:hover .search-icon, .content-specific-search form .search-icon:hover .search-icon {
        background-color: #b3bd35;
        cursor: pointer; }

.search-video form .field-group select {
  max-width: 11em; }

.search-news form .field-group select {
  max-width: 8em; }

.search-journal form .field-group select {
  max-width: 10em; }

.search-conference form .field-group select {
  max-width: 7em; }

.search-e_learning form .field-group select {
  max-width: 20em; }

@media (min-width: 992px) {
  .content-specific-search form .field-group:last-child, .search-icon {
    flex-basis: 2em; } }

#site-message {
  z-index: 0;
  width: 1000px;
  margin: 0 auto;
  padding-top: 1.5em;
  padding-bottom: 1.5em; }
  #site-message .site-message-wrapper {
    padding-right: 5em;
    width: 85%; }
    #site-message .site-message-wrapper p {
      color: #0056b4;
      padding-bottom: 0.5em; }
  #site-message div {
    display: inline-block; }

#site-message {
  max-height: 20em;
  animation: site-message-animation 1.5s 1;
  -webkit-animation: site-message-animation 1.5s 1;
  animation-fill-mode: forwards;
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
  /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards; }

#site-message h1, #site-message .h1, #site-message div, #site-message p {
  max-height: 20em;
  animation: site-message-animation-sub-block 1.5s 1;
  -webkit-animation: site-message-animation-sub-block 1.5s 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
  /* Safari and Chrome */ }

@keyframes site-message-animation {
  from {
    opacity: 1;
    max-height: 20em;
    padding-top: 1.5em;
    padding-bottom: 1.5em; }
  to {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0; } }

@-webkit-keyframes site-message-animation {
  from {
    opacity: 1;
    max-height: 20em;
    padding-top: 1.5em;
    padding-bottom: 1.5em; }
  to {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0; } }

@keyframes site-message-animation-sub-block {
  from {
    opacity: 1;
    max-height: 20em; }
  to {
    opacity: 0;
    max-height: 0; } }

@-webkit-keyframes site-message-animation-sub-block {
  from {
    opacity: 1;
    max-height: 20em; }
  to {
    opacity: 0;
    max-height: 0; } }

.icon-video-camera::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0.4rem 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M15.957 3.056v9.714c0 .25-.116.426-.348.527a.62.62 0 0 1-.223.045.531.531 0 0 1-.402-.17l-3.598-3.598v1.482c0 .708-.252 1.314-.755 1.817a2.478 2.478 0 0 1-1.817.755H2.528a2.478 2.478 0 0 1-1.817-.755 2.477 2.477 0 0 1-.754-1.817V4.77c0-.708.251-1.314.754-1.817a2.477 2.477 0 0 1 1.817-.754h6.286c.708 0 1.314.251 1.817.754.503.503.755 1.109.755 1.817v1.474l3.598-3.59a.53.53 0 0 1 .402-.169.64.64 0 0 1 .223.044.535.535 0 0 1 .348.527Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem; }

.icon-chevron-left::after {
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin: 0.15rem 0 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230056b4'%3e%3cpath d='M12.643 3.059 7.702 8l4.941 4.941a.572.572 0 0 1 .178.418c0 .162-.06.301-.178.419l-1.544 1.545a.573.573 0 0 1-.419.177.572.572 0 0 1-.419-.177L3.356 8.419A.576.576 0 0 1 3.18 8c0-.162.059-.301.176-.419L10.261.676A.575.575 0 0 1 10.68.5c.161 0 .301.059.419.176l1.544 1.545a.576.576 0 0 1 .178.42c0 .16-.06.3-.178.418Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem; }

.icon-chevron-right::after {
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin: 0.15rem 0 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230056b4'%3e%3cpath d='m12.644 8.419-6.905 6.904a.572.572 0 0 1-.419.177.573.573 0 0 1-.419-.177l-1.544-1.544a.573.573 0 0 1-.177-.419c0-.162.059-.301.177-.419L8.298 8 3.357 3.059a.572.572 0 0 1-.177-.419c0-.161.059-.301.177-.419L4.901.677A.573.573 0 0 1 5.32.5c.162 0 .301.059.419.177l6.905 6.904A.576.576 0 0 1 12.82 8a.576.576 0 0 1-.176.419Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem; }

.icon-star::after {
  display: inline-block;
  flex-shrink: 0;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.15rem 0 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M15.34 6.425c0 .129-.076.27-.229.423l-3.202 3.123.758 4.411c.006.041.009.101.009.177a.515.515 0 0 1-.093.312.31.31 0 0 1-.268.129.714.714 0 0 1-.354-.106l-3.96-2.082-3.961 2.082a.747.747 0 0 1-.353.106c-.124 0-.216-.043-.278-.129a.515.515 0 0 1-.093-.312c0-.036.007-.095.017-.177l.759-4.411L.881 6.848c-.146-.158-.22-.299-.22-.423 0-.218.164-.352.494-.406l4.428-.644 1.986-4.014C7.681 1.12 7.824 1 8.001 1c.175 0 .32.12.432.361l1.985 4.014 4.429.644c.328.054.493.188.493.406Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.8rem; }

.icon-star-o::after {
  display: inline-block;
  flex-shrink: 0;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.15rem 0 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='m10.69 9.574 2.7-2.62-3.723-.548-1.668-3.368-1.666 3.368-3.723.548 2.7 2.62-.644 3.715 3.333-1.756 3.327 1.756-.636-3.715Zm4.649-3.149c0 .129-.076.27-.229.423l-3.202 3.123.758 4.412c.007.041.01.1.01.176 0 .293-.122.441-.363.441a.725.725 0 0 1-.352-.106l-3.962-2.082-3.96 2.082a.767.767 0 0 1-.353.106c-.123 0-.217-.043-.278-.128a.52.52 0 0 1-.092-.313c0-.036.006-.095.018-.176l.758-4.412L.881 6.848C.733 6.69.66 6.549.66 6.425c0-.218.165-.353.494-.406l4.428-.644 1.986-4.014C7.679 1.12 7.823 1 7.999 1c.178 0 .322.12.433.361l1.985 4.014 4.428.644c.33.053.494.188.494.406Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.8rem; }

.icon-search::after {
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin: 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M10.885 6.846c0-1.112-.396-2.063-1.186-2.853-.79-.79-1.741-1.185-2.853-1.185s-2.063.395-2.853 1.185-1.185 1.741-1.185 2.853.395 2.063 1.185 2.853 1.741 1.186 2.853 1.186 2.063-.396 2.853-1.186c.79-.79 1.186-1.741 1.186-2.853Zm4.615 7.5c0 .313-.114.583-.343.811a1.105 1.105 0 0 1-.811.343c-.324 0-.595-.114-.811-.343l-3.092-3.082a6.175 6.175 0 0 1-3.597 1.117 6.226 6.226 0 0 1-2.465-.5 6.347 6.347 0 0 1-2.029-1.352A6.37 6.37 0 0 1 1 9.312a6.248 6.248 0 0 1-.5-2.466c0-.859.167-1.681.5-2.465a6.364 6.364 0 0 1 1.352-2.029A6.364 6.364 0 0 1 4.381 1 6.243 6.243 0 0 1 6.846.5c.86 0 1.681.167 2.466.5a6.37 6.37 0 0 1 2.028 1.352 6.347 6.347 0 0 1 1.352 2.029c.334.784.5 1.606.5 2.465a6.175 6.175 0 0 1-1.117 3.597l3.091 3.092c.223.222.334.493.334.811Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem; }

.icon-user::after {
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin: 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M14.666 13.24c0 .757-.216 1.406-.651 1.947-.434.542-.956.813-1.568.813H3.552c-.611 0-1.134-.271-1.568-.813-.434-.541-.651-1.19-.651-1.947 0-.591.029-1.148.088-1.673.06-.524.169-1.051.329-1.583.16-.531.362-.986.609-1.364.247-.378.573-.688.979-.928a2.714 2.714 0 0 1 1.402-.359c.909.889 1.995 1.334 3.26 1.334 1.264 0 2.35-.445 3.26-1.334.528 0 .995.12 1.401.359.406.24.733.55.979.928.247.378.45.833.61 1.364.16.532.269 1.059.328 1.583.059.525.088 1.082.088 1.673ZM12 4a3.854 3.854 0 0 1-1.172 2.828C10.046 7.609 9.104 8 8 8c-1.105 0-2.047-.391-2.829-1.172A3.857 3.857 0 0 1 4 4c0-1.104.391-2.047 1.171-2.828C5.953.391 6.895 0 8 0c1.104 0 2.046.391 2.828 1.172A3.854 3.854 0 0 1 12 4Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem; }

.icon-times-thin::after {
  display: flex;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin: 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 2rem; }

dialog {
  border: solid 1px #ccc;
  border-radius: .5em;
  width: 30em;
  z-index: 100;
  left: 16em;
  -webkit-box-shadow: 2px 2px 10px 3px #e3e3e3;
  -moz-box-shadow: 2px 2px 10px 3px #e3e3e3;
  box-shadow: 2px 2px 10px 3px #e3e3e3; }
  dialog .close-button {
    text-align: right;
    color: #0056b4;
    margin-bottom: 1em; }
  dialog .close-button:hover {
    color: #b3bd35;
    cursor: pointer; }
  dialog .modal-header {
    color: #0056b4;
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 1em; }
  dialog .modal-sub-header {
    margin-bottom: 2em; }
  dialog .modal-body {
    margin-bottom: 1em;
    border-bottom: solid 1px #dfe3ec;
    font-size: 0.8em; }
    dialog .modal-body .video-rating-question-container {
      margin-bottom: 1.5em; }
      dialog .modal-body .video-rating-question-container .question {
        display: inline-block;
        width: 46%;
        margin-right: 4%; }
      dialog .modal-body .video-rating-question-container .rating-container {
        display: inline-block;
        vertical-align: top;
        margin-top: 0.5em; }
        dialog .modal-body .video-rating-question-container .rating-container .rating button {
          background: none;
          border: none;
          filter: invert(83%) sepia(0%) saturate(786%) hue-rotate(135deg) brightness(101%) contrast(88%);
          font-size: 1.5em; }
        dialog .modal-body .video-rating-question-container .rating-container .rating .icon-star::after {
          width: 1.2rem;
          height: 1.2rem;
          background-size: 1.2rem; }
        dialog .modal-body .video-rating-question-container .rating-container .rating .icon-star:hover, dialog .modal-body .video-rating-question-container .rating-container .rating .star-rating-checked, dialog .modal-body .video-rating-question-container .rating-container .rating .star-rating-checked-temporary {
          filter: invert(78%) sepia(64%) saturate(543%) hue-rotate(13deg) brightness(97%) contrast(95%); }
        dialog .modal-body .video-rating-question-container .rating-container .rating button:focus {
          outline: none; }
  dialog footer {
    float: right; }

dialog.login-modal {
  font-size: 1em;
  text-align: center;
  left: 0; }
  dialog.login-modal .modal-body {
    border-bottom: none;
    font-size: 100%; }
    dialog.login-modal .modal-body a.btn {
      margin: 0 1em; }
  dialog.login-modal footer {
    float: none; }
  dialog.login-modal .registration-cta-bar {
    font-size: 0.875em;
    margin-bottom: 1.5em; }
    dialog.login-modal .registration-cta-bar .btn {
      margin-right: 1em; }
    dialog.login-modal .registration-cta-bar a.login span.question {
      color: #646466; }
    dialog.login-modal .registration-cta-bar a.login span.login {
      color: #0056b4;
      font-weight: bold; }
    dialog.login-modal .registration-cta-bar a.login span.login:hover {
      border-bottom: solid 2px #c6d420; }

.dialog-background-cover {
  z-index: 50;
  opacity: 0.6;
  background: white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed; }

.dialog-background-cover.hidden {
  display: none; }

.dialog-start:hover {
  cursor: pointer; }

dialog.article-citation .modal-body {
  margin-bottom: 2em;
  border-bottom: none; }

dialog.article-citation .close-button {
  margin-bottom: 0; }

dialog.article-citation .btn {
  float: right; }

dialog.article-citation .btn.hidden {
  display: none; }

dialog.journal-rating-modal {
  position: fixed;
  left: 10em;
  top: 0;
  margin: 1.75rem auto;
  z-index: 51; }

dialog.group.login-modal .confirm {
  margin-bottom: 2em; }
  dialog.group.login-modal .confirm input[type='checkbox'] {
    width: 1.5em;
    height: 1.5em;
    background: white;
    border-radius: 5px;
    border: 2px solid #555; }
  dialog.group.login-modal .confirm input[type='checkbox']:checked {
    background: lightgrey; }
  dialog.group.login-modal .confirm label {
    position: relative;
    top: -0.4em;
    margin-right: 1em; }

.flex-row {
  display: block;
  margin: 0; }

.banner {
  text-align: center; }

.widget-grid .widget-row,
.widget-grid-full .widget-row {
  margin-top: 1.5em; }

.widget-grid .widget-row:first-child {
  margin-top: 0; }

.widget-row .panel:first-child {
  margin-left: 0; }

.widget-list {
  border-bottom: solid 2px #dfe3ec; }
  .widget-list .item-row {
    padding-bottom: 0.2em; }
  .widget-list .item-row:last-child {
    border: 0; }
  .widget-list .item-thumb img {
    max-height: 150px;
    max-width: 150px;
    aspect-ratio: 31/18; }
  .widget-list .item-thumb img:hover {
    opacity: 0.8;
    filter: alpha(opacity=80);
    /* For IE8 and earlier */ }
  .widget-list .conferences .item-thumb img {
    aspect-ratio: unset; }

header.widget {
  padding-bottom: 10px; }

.widget-title-link-container {
  float: right;
  position: relative;
  top: 1px; }

.widget-horizontal-list .item-thumb img {
  max-height: 100px;
  max-width: 182px; }

.item-row .item-title {
  margin-left: 10px; }

.sidebar .item-row .item-title {
  margin-left: 0; }

.widget-list .item-row .item-title .date {
  margin-top: 10px;
  display: block; }

.widget-list footer {
  float: left;
  width: 100%; }

.widget-list footer .view-all {
  padding: 10px; }

.widget-horizontal-list .widget-contents {
  width: 100%;
  padding: 0;
  min-height: 0; }

.widget-horizontal-list .item-thumb {
  margin: 10px;
  height: 100px; }

.item-row .item-title {
  width: 100%; }

.widget-horizontal-list .menu-view-all {
  padding-left: 350px; }

.widget-horizontal-list .item-title {
  padding: 0 10px 0 10px; }

#widget-list-video_featured .col_2 {
  flex: none; }

#widget-list-video_featured .col_10 {
  flex: none; }

#widget-list-video_featured img {
  width: 100%;
  max-height: none;
  max-width: none; }

#widget-list-video_featured .widget-contents-video_featured {
  padding: 0; }

#widget-list-video_featured .item-title {
  line-height: 24px;
  padding: 10px; }

#widget-list-video_featured .item-row {
  padding: 0; }

#widget-list-video_featured h2, #widget-list-video_featured .h2 {
  margin-bottom: 0; }

#search-results .item-thumb img {
  max-width: 100px;
  max-height: 100px; }

#search-results.widget-list, #search-results.widget-list .item-row {
  border: none; }

#search-filter-form form div {
  float: left; }

.item-row {
  margin-bottom: 1.5em; }

.container-flex-wrap.social-icons {
  margin-bottom: 20px;
  justify-content: center; }

.page-title-sub .social-icons {
  margin-bottom: 10px; }

.social-icons h2, .social-icons .h2 {
  width: 100%;
  text-align: center;
  margin-top: 0; }

.social-icons .icon {
  float: left;
  height: 25px;
  width: 25px;
  background-position: center;
  margin: 2px; }

.social-icons .icon:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
  /* For IE8 and earlier */ }

.icon-twitter {
  background-image: url(/images/layout/icon-twitter.png);
  background-repeat: no-repeat;
  background-size: contain; }

.icon-linkedin {
  background-image: url(/images/layout/icon-linkedin.png);
  background-repeat: no-repeat; }

.icon-facebook {
  background-image: url(/images/layout/icon-facebook.png);
  background-repeat: no-repeat; }

.icon-youtube {
  background-image: url(/images/layout/icon-youtube.png);
  background-repeat: no-repeat; }

.icon-rss {
  background-image: url(/images/layout/icon-rss.png);
  background-repeat: no-repeat; }

.addThis {
  zoom: 0.75;
  -moz-transform: scale(0.75);
  transform-origin: top left; }
  .addThis .cta {
    float: left;
    background-color: #dfe3ec;
    border-radius: 0.25em;
    padding: 0 0.75em 0 0.4em;
    margin-right: 0.25em;
    color: #2d2d2d;
    font-size: 1.15em; }

.news-social .addThis {
  margin-right: 10px;
  float: left; }

.news-social .icon {
  float: left;
  display: block; }

.news-social .rss-link {
  float: left; }

.article-strip .item-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.article-strip .item-details {
  position: relative;
  bottom: 0.1em; }

.author-names {
  padding-top: 0.5em;
  padding-bottom: 0.5em; }

.article-content {
  width: 100%;
  word-wrap: break-word; }

.mixin-word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto; }

.article-content h1, .article-content .h1 {
  line-height: 1em; }

#widget-list-conference .results-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  #widget-list-conference .results-list .item-row {
    width: 49%; }

.widget-list.widget-list-journal {
  font-size: 0.8em;
  border-bottom: none; }
  .widget-list.widget-list-journal .item-row {
    margin-bottom: 3em; }
    .widget-list.widget-list-journal .item-row img {
      height: 100%; }
    .widget-list.widget-list-journal .item-row span.article-type {
      color: #0056b4; }
    .widget-list.widget-list-journal .item-row .item-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }

.results-list img:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  /* For IE8 and earlier */ }

.results-grid .item-thumb a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  /* For IE8 and earlier */ }

.item-thumb img {
  width: 100%; }

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 1em;
  text-align: left;
  outline: none;
  transition: 0.4s;
  border: 1px solid #ccc; }
  .accordion .indicate {
    display: inline-block;
    min-width: 1.5em;
    font-size: 1.5em; }
  .accordion .indicate:before {
    content: '+ '; }

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion.active,
.accordion:hover {
  background-color: #ccc; }

.accordion.active .indicate:before {
  content: '-  '; }

/* Style the accordion panel. Note: hidden by default */
.accordion-panel {
  padding: 0 16em;
  background-color: white;
  display: none;
  overflow: hidden; }
  .accordion-panel .form-control label {
    width: 80%;
    display: block;
    padding: 0.4em; }
  .accordion-panel .form-control input[type=checkbox] {
    display: block;
    margin-right: 1em;
    float: left;
    margin-top: 1em; }

.accordion-wrapper.slimline .accordion {
  padding: 0 0 0 1em; }

.accordion-wrapper.slimline .accordion-panel {
  padding: initial;
  overflow: initial; }
  .accordion-wrapper.slimline .accordion-panel .form-control input {
    display: inline;
    margin-right: 0.5em;
    float: none;
    margin-top: inherit;
    width: 3%; }
  .accordion-wrapper.slimline .accordion-panel .form-control label {
    width: 40%;
    display: inline-block;
    padding: initial;
    white-space: nowrap;
    font-size: 0.85em; }

.video-carousel {
  width: 100%;
  border-bottom: solid 2px #dfe3ec;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .video-carousel .item-title {
    margin-left: 0;
    width: 100%; }
  .video-carousel .item-thumb img {
    width: 100%;
    display: block; }
  .video-carousel .arrow-bar {
    padding: 0.3em 40%;
    margin-bottom: 0.7em; }
    .video-carousel .arrow-bar .arrow {
      display: inline-block; }
    .video-carousel .arrow-bar .arrow-next {
      float: right; }
    .video-carousel .arrow-bar .active {
      background-color: inherit; }
    .video-carousel .arrow-bar .invisible {
      visibility: hidden; }

.widget-contents-video_featured {
  z-index: 0; }

.video-carousel .item-thumb img {
  height: 224px; }

.video-browse-body .video-carousel .item-thumb img {
  height: 400px; }

.star-rating-checked {
  filter: invert(72%) sepia(25%) saturate(4692%) hue-rotate(358deg) brightness(99%) contrast(107%); }

.banner .banner-2nd-link {
  display: block;
  width: 114px;
  font-size: 0.7em;
  margin: 0 auto;
  background-color: #dcdcdc;
  padding: 3px;
  color: #3192bb;
  position: relative;
  top: -3px; }

.banner img {
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: none; }

.banner .banner-2nd-link:hover {
  font-weight: bold; }

.banner .banner-2nd-link.banner-id-192 {
  border: solid 1px #63194c;
  border-top: none;
  width: 112px; }

.maintenance .page-title {
  margin-bottom: 5em; }

.maintenance .disabled, .maintenance .window {
  font-size: 1.5em;
  margin-bottom: 1em;
  text-align: center;
  color: darkslategrey; }

.sitewide-maintenance-message-wrapper {
  text-align: center;
  padding: 1em 1em;
  background-color: grey;
  color: white;
  border-bottom: solid 2px white; }
  .sitewide-maintenance-message-wrapper p {
    margin: 0; }

.site-version.warning {
  background-color: black;
  color: gold;
  font-weight: bold;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  height: 3rem;
  line-height: 1rem;
  font-size: 1em; }
  .site-version.warning .warning-text {
    padding: 1rem; }
  .site-version.warning .content-status .inactive, .site-version.warning .content-status .embargoed {
    color: red; }
  .site-version.warning .select.country {
    border: solid 1px gold;
    padding: 3px 6px;
    border-radius: 5px;
    position: relative;
    top: -3px; }
  .site-version.warning .select.country:hover {
    cursor: pointer;
    border: solid 1px grey; }
  .site-version.warning .right {
    float: right;
    margin-right: 3em; }

@media (max-width: 1000px) {
  .site-version.warning {
    font-size: 1.75vw; } }

.ui-dialog.country.selector {
  background-color: grey;
  border: none;
  padding: 0;
  z-index: 5000;
  font-size: 0.85em; }
  .ui-dialog.country.selector .ui-widget-content {
    color: white; }
  .ui-dialog.country.selector .ui-dialog-titlebar {
    height: inherit; }
  .ui-dialog.country.selector .ui-dialog-titlebar-close {
    width: 30px;
    height: 30px; }
  .ui-dialog.country.selector .country.button {
    width: 4em;
    margin: 0.35em;
    text-align: center;
    padding: 0.25em; }
  .ui-dialog.country.selector .country.button.current {
    color: white;
    background-color: grey; }
  .ui-dialog.country.selector .country.button.active {
    color: white;
    background-color: #0056b4; }
  .ui-dialog.country.selector .country.button:hover {
    cursor: pointer; }
  .ui-dialog.country.selector table {
    width: 100%;
    margin-bottom: 2em; }
    .ui-dialog.country.selector table td {
      line-height: 1.3em;
      padding: 0.35em;
      border: solid 1px darkgrey; }
    .ui-dialog.country.selector table td.c1 {
      width: 20%; }
    .ui-dialog.country.selector table td.c2 {
      width: 60%; }
    .ui-dialog.country.selector table td.c3 {
      width: 10%;
      text-align: center; }
    .ui-dialog.country.selector table td.c4 {
      width: 10%;
      text-align: center; }
  .ui-dialog.country.selector button.browse {
    cursor: pointer; }
  .ui-dialog.country.selector button.browse.active {
    color: #cbd921;
    background-color: #0056b4;
    font-weight: bold; }
  .ui-dialog.country.selector .hidden {
    visibility: hidden; }
  .ui-dialog.country.selector .region {
    margin-bottom: 1em; }
  .ui-dialog.country.selector .flash-message {
    background-color: white;
    color: green;
    padding: 1em;
    font-size: 2em;
    line-height: 2em;
    width: auto;
    text-align: center;
    border-radius: 5px; }
  .ui-dialog.country.selector .flash-message.hidden {
    font-size: 0;
    height: 0;
    padding: 0; }

body.preview {
  padding-top: 3rem; }

body.preview.login #page-head, body.preview.login #page-foot, body.preview.login h1, body.preview.login .h1 {
  display: none; }

body.preview.login #login-content .buttons.row a {
  display: none; }

body.preview.login #login-content form .row {
  margin-top: 1em; }

body.preview.login #login-content .alert {
  display: none; }

.previewer.dialog.wrapper.hidden {
  visibility: hidden; }

.collaborate.warning {
  color: #40c79c; }

body.collaborate {
  padding-top: 3rem; }

body.collaborate.login #page-head, body.collaborate.login #page-foot, body.collaborate.login h1, body.collaborate.login .h1 {
  display: none; }

body.collaborate.login #login-content .buttons.row a {
  display: none; }

body.collaborate.login #login-content form .row {
  margin-top: 1em; }

body.collaborate.login #login-content .alert {
  display: none; }

.snippet {
  box-sizing: border-box;
  font-family: Lato, Arial, SansSerif; }
  .snippet h2.title, .snippet .title.h2 {
    margin: 0;
    line-height: 1em;
    font-size: 1.5em;
    font-weight: 600;
    color: #0056b4;
    border-left: solid 0.3em #cbd921;
    padding-left: 0.4em;
    margin-bottom: 0.3em;
    margin-bottom: 0; }
  .snippet p {
    line-height: 1.3em;
    margin: 1em 0; }
  .snippet .strong {
    font-weight: 700;
    color: #0056b4; }
  .snippet .donate-button {
    text-align: right; }
    .snippet .donate-button input[type=submit] {
      background-color: #0056b4;
      color: #e9ecf4;
      font-size: 0.8em;
      font-weight: bold;
      padding: .4em 1em;
      border-radius: .2em;
      border: 0; }
    .snippet .donate-button input[type=submit]:hover {
      background-color: #1974d7; }
  .snippet .donate-button.right {
    text-align: right; }
  .snippet .donate-button.left {
    text-align: left; }

.snippet-container.unresponsive .snippet {
  width: 100%; }

.snippet.boxed {
  border: solid 3px #cbd921;
  border-radius: 0.5em;
  padding: 1em;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em; }

.snippet.cta1 a {
  color: #0056b4; }

.snippet.cta1 a:hover {
  color: #599fe2;
  border-bottom: solid 2px #c6d420; }

.snippet.cta1.boxed {
  margin-top: 1em;
  margin-bottom: 0.5em; }

.snippet.generic.article {
  border: solid 1px lightgrey;
  border-radius: 5px;
  margin-bottom: 1em; }
  .snippet.generic.article .title, .snippet.generic.article .info, .snippet.generic.article .author-names {
    padding: 1em; }
  .snippet.generic.article .author-names {
    padding-top: 0; }
  .snippet.generic.article .title {
    font-weight: bold; }
  .snippet.generic.article .info {
    background-color: lightgrey;
    font-size: 0.85em; }
    .snippet.generic.article .info .date, .snippet.generic.article .info .doi {
      margin-right: 2em; }

.snippet.generic.article.simple {
  border: none;
  margin-bottom: 1em; }
  .snippet.generic.article.simple .title, .snippet.generic.article.simple .info, .snippet.generic.article.simple .authors {
    padding: 0; }
  .snippet.generic.article.simple .authors {
    padding-top: 0;
    display: inline; }
  .snippet.generic.article.simple .title {
    font-weight: bold; }
  .snippet.generic.article.simple .date {
    display: inline-block; }

.snippet.generic.article.cell a {
  text-decoration: none; }

.snippet.generic.article.image.cell {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .snippet.generic.article.image.cell a {
    text-decoration: none; }
  .snippet.generic.article.image.cell .content {
    -webkit-transition: max-height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    max-height: 0;
    overflow: hidden; }
    .snippet.generic.article.image.cell .content .title, .snippet.generic.article.image.cell .content .author-names {
      background-color: white; }

.snippet.generic.article.image.cell:hover .content {
  max-height: 5000px; }

.snippet.generic-link-card-vertical .card .title {
  color: #0056b4; }

.snippet.generic-link-card-vertical .card p {
  line-height: 1.25em; }

.snippet.generic-link-card-vertical .card .card-text.link {
  color: #0056b4;
  text-decoration: underline; }

.snippet.generic-link-card-vertical .card .card-text.description {
  min-height: 8em; }

.snippet.generic-link-card-vertical .boxed-bright {
  border: solid 3px #cbd921;
  border-radius: 0.5em; }

.bespoke-cards .snippet.generic.article {
  border: none;
  margin-bottom: 1em; }
  .bespoke-cards .snippet.generic.article .thumbnail img {
    width: 100%; }
  .bespoke-cards .snippet.generic.article .type {
    color: #0056b4; }
  .bespoke-cards .snippet.generic.article .title {
    padding: 1em 0;
    font-weight: normal; }
  .bespoke-cards .snippet.generic.article .date {
    font-size: 0.925em;
    padding-bottom: 1em;
    margin: 0; }
  .bespoke-cards .snippet.generic.article .author-names {
    background-color: lightgrey;
    font-style: italic;
    padding: 0.2em; }

.bespoke-cards.fullwidth.video .video.snippet {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-bottom: 1em; }
  .bespoke-cards.fullwidth.video .video.snippet .videoplayer {
    flex: 3;
    margin-right: 1em; }
    .bespoke-cards.fullwidth.video .video.snippet .videoplayer .kaltura_player {
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden; }
      .bespoke-cards.fullwidth.video .video.snippet .videoplayer .kaltura_player iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
  .bespoke-cards.fullwidth.video .video.snippet .info-wrapper {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .bespoke-cards.fullwidth.video .video.snippet .info-wrapper .type {
      color: #0056b4; }
    .bespoke-cards.fullwidth.video .video.snippet .info-wrapper .date {
      font-size: 0.8em; }
    .bespoke-cards.fullwidth.video .video.snippet .info-wrapper .author {
      background-color: lightgrey;
      font-style: italic;
      padding: 0.2em; }

.snippet-container.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
  justify-content: center;
  font-size: 0.9em; }
  .snippet-container.grid .cell .info {
    font-size: initial; }

.snippet-container.grid.grid-2 {
  grid-template-columns: repeat(2, 1fr); }
  .snippet-container.grid.grid-2 .title {
    height: 3em; }
  .snippet-container.grid.grid-2 .thumbnail img {
    height: 100px; }

.snippet-container.grid.grid-3 {
  grid-template-columns: repeat(3, 1fr); }
  .snippet-container.grid.grid-3 .title {
    height: 4em; }
  .snippet-container.grid.grid-3 .thumbnail img {
    height: 100px; }

.snippet-container.grid.grid-4 {
  grid-template-columns: repeat(4, 1fr); }
  .snippet-container.grid.grid-4 .title {
    height: 7em; }
  .snippet-container.grid.grid-4 .thumbnail img {
    height: 100px; }

.snippet-container.grid.grid-5 {
  grid-template-columns: repeat(5, 1fr); }
  .snippet-container.grid.grid-5 .title {
    height: 9em; }
  .snippet-container.grid.grid-5 .thumbnail img {
    height: 100px; }

.snippet-container.image.grid {
  height: 188px; }
  .snippet-container.image.grid .thumbnail img {
    height: 100px; }

.snippet-container.grid.title-height-1 .title {
  height: 1em; }

.snippet-container.grid.title-height-2 .title {
  height: 2em; }

.snippet-container.grid.title-height-3 .title {
  height: 3em; }

.snippet-container.grid.title-height-4 .title {
  height: 4em; }

.snippet-container.grid.title-height-5 .title {
  height: 5em; }

.snippet-container.grid.title-height-6 .title {
  height: 6em; }

.snippet-container.grid.title-height-7 .title {
  height: 7em; }

.snippet-container.grid.title-height-8 .title {
  height: 8em; }

.snippet-container.grid.title-height-9 .title {
  height: 9em; }

.snippet-container.grid.title-height-10 .title {
  height: 10em; }

.snippet-container.grid.date-height-1 .date {
  height: 1em; }

.snippet-container.grid.date-height-2 .date {
  height: 2em; }

.snippet-container.grid.date-height-3 .date {
  height: 3em; }

.snippet-container.grid.date-height-4 .date {
  height: 4em; }

.snippet-container.grid.date-height-5 .date {
  height: 5em; }

.snippet-container.grid.date-height-6 .date {
  height: 6em; }

.snippet-container.grid.date-height-7 .date {
  height: 7em; }

.snippet-container.grid.date-height-8 .date {
  height: 8em; }

.snippet-container.grid.date-height-9 .date {
  height: 9em; }

.snippet-container.grid.date-height-10 .date {
  height: 10em; }

.snippet-container.grid.type-height-1 .type {
  height: 1em; }

.snippet-container.grid.type-height-2 .type {
  height: 2em; }

.snippet-container.grid.type-height-3 .type {
  height: 3em; }

.snippet-container.grid.type-height-4 .type {
  height: 4em; }

.snippet-container.grid.type-height-5 .type {
  height: 5em; }

.snippet-container.grid.type-height-6 .type {
  height: 6em; }

.snippet-container.grid.type-height-7 .type {
  height: 7em; }

.snippet-container.grid.type-height-8 .type {
  height: 8em; }

.snippet-container.grid.type-height-9 .type {
  height: 9em; }

.snippet-container.grid.type-height-10 .type {
  height: 10em; }

.snippet-container.grid.author-names-height-1 .author-names {
  height: 1em; }

.snippet-container.grid.author-names-height-2 .author-names {
  height: 2em; }

.snippet-container.grid.author-names-height-3 .author-names {
  height: 3em; }

.snippet-container.grid.author-names-height-4 .author-names {
  height: 4em; }

.snippet-container.grid.author-names-height-5 .author-names {
  height: 5em; }

.snippet-container.grid.author-names-height-6 .author-names {
  height: 6em; }

.snippet-container.grid.author-names-height-7 .author-names {
  height: 7em; }

.snippet-container.grid.author-names-height-8 .author-names {
  height: 8em; }

.snippet-container.grid.author-names-height-9 .author-names {
  height: 9em; }

.snippet-container.grid.author-names-height-10 .author-names {
  height: 10em; }

.snippet-container.select .snippet {
  border: none;
  border-radius: inherit;
  margin-bottom: 0; }

.snippet-container.select select {
  padding: .4em .8em;
  border-radius: 1em;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: .8em; }
  .snippet-container.select select option {
    padding: 1em; }

h1.page-title.smaller, .page-title.smaller.h1 {
  font-size: 1.1em;
  font-weight: 200; }

h2.snippet, .snippet.h2 {
  font-size: 1.4em !important;
  font-weight: bold; }

p.snippet.text-size-reduced {
  font-size: 0.8em; }

p.half-line {
  line-height: 0.5em;
  height: 0.5em;
  margin: 0; }

p.snippet.show-more a {
  text-decoration: none;
  color: #0056b4; }

p.snippet.show-more a:hover {
  text-decoration: underline; }

.snippet.addThis {
  margin-top: 1em !important; }

.snippet.sg-btn-cta {
  padding: .3em .3em .3em .2em;
  text-align: center;
  font-weight: bold; }
  .snippet.sg-btn-cta a {
    color: #0056b4; }

.snippet-container.unresponsive .snippet.sg-btn-cta {
  width: 88%; }

.snippet.sg-btn-cta:hover {
  color: #e9ecf4; }
  .snippet.sg-btn-cta:hover a {
    color: #e9ecf4; }

.snippet.sg-btn-cta.hidden {
  visibility: hidden; }

snippets.hidden {
  display: none; }

.snippet .dialog p {
  color: black; }

.ui-dialog {
  background-color: #0056b4; }
  .ui-dialog .ui-widget-header {
    background: initial;
    border: none;
    color: white; }
  .ui-dialog .ui-dialog-titlebar {
    font-size: 2em;
    line-height: 2em;
    height: 2em;
    padding-left: 0.5em; }

.ui-widget-overlay {
  background: black;
  opacity: 0.75; }

.snippet.xmas20.inline {
  width: 1000px;
  margin: 3em auto; }
  .snippet.xmas20.inline p.closer {
    text-align: right; }
  .snippet.xmas20.inline p.donate {
    font-size: 2em; }

div.ui-dialog.xmas20.modal {
  background: none;
  border: none;
  padding: 0; }
  div.ui-dialog.xmas20.modal .ui-widget.ui-widget-content {
    border: none; }
  div.ui-dialog.xmas20.modal .ui-dialog-titlebar {
    position: absolute;
    z-index: 10000;
    right: 25px; }
  div.ui-dialog.xmas20.modal .ui-dialog-titlebar-close {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border-style: hidden; }
  div.ui-dialog.xmas20.modal .ui-dialog-content {
    overflow: hidden;
    padding: 0; }
  div.ui-dialog.xmas20.modal .donate-cover,
  div.ui-dialog.xmas20.modal .generic-cover {
    background: white;
    opacity: 0;
    bottom: 102px;
    right: 41px;
    height: 100px;
    width: 210px;
    z-index: 110;
    position: absolute; }
  div.ui-dialog.xmas20.modal .donate-cover:hover,
  div.ui-dialog.xmas20.modal .generic-cover:hover {
    border: solid 2px lightgrey;
    opacity: 0.1;
    border-radius: 10px; }

.no-close .ui-dialog-titlebar-close {
  display: none; }

.pdf-viewer-container {
  width: 100%;
  position: relative; }

#my-pdf-viewer {
  width: 100%; }
  #my-pdf-viewer .arrow {
    display: inline-block;
    width: 10%;
    font-size: 8em;
    color: lightgrey;
    position: relative;
    bottom: 1.7em;
    text-align: center; }
  #my-pdf-viewer .arrows {
    margin-top: 1em;
    height: 3em;
    display: flex;
    flex-wrap: wrap; }
    #my-pdf-viewer .arrows .arrow {
      width: 50%;
      font-size: 8em;
      height: 100%;
      color: lightgrey;
      text-align: center;
      position: initial;
      bottom: auto; }
  #my-pdf-viewer .arrow:hover {
    color: grey;
    cursor: pointer; }
  #my-pdf-viewer .page-counter {
    width: 100%;
    color: grey;
    margin-top: 4em;
    text-align: center; }
    #my-pdf-viewer .page-counter div {
      display: inline-block;
      min-width: 2em; }
  #my-pdf-viewer .page-counter.inside-arrows {
    margin-top: -2em; }

#my-pdf-viewer.hidden {
  display: none; }

.image-special-issue img {
  max-width: 307px; }

.video-special-issue-abstract {
  margin-top: 3em; }

.video-special-issue-abstract p {
  font-weight: bold;
  text-align: center; }

.pager {
  margin-top: 3em;
  padding-bottom: 1em;
  text-align: center;
  border-bottom: solid 2px #ccc; }
  .pager a, .pager span {
    display: inline-block;
    padding: 0.75em;
    color: #0056b4;
    border: solid 1px white;
    border-radius: 2px; }
  .pager a {
    text-decoration: none; }
  .pager a:hover {
    font-weight: bold;
    border: solid 1px #cccccc; }
  .pager span.disabled,
  .pager nav a:first-child,
  .pager nav a:last-child {
    border-width: 0;
    color: #0056b4;
    border-radius: 2px; }
    .pager span.disabled .icon-chevron-right::after, .pager span.disabled .icon-chevron-left::after,
    .pager nav a:first-child .icon-chevron-right::after,
    .pager nav a:first-child .icon-chevron-left::after,
    .pager nav a:last-child .icon-chevron-right::after,
    .pager nav a:last-child .icon-chevron-left::after {
      height: 0.9rem;
      background-size: 0.9rem; }
  .pager span.disabled:hover,
  .pager nav a:first-child:hover,
  .pager nav a:last-child:hover {
    font-weight: bold;
    background-color: #dfe3ec; }
  .pager span.disabled {
    display: none; }
  .pager .dots + a {
    display: none; }
  .pager .dots {
    display: none; }
  .pager .current {
    background-color: #cbd921; }
  .pager .disabled {
    border-color: #ccf;
    color: #999; }
  .pager .disabled {
    color: #2d2d2d; }

.pager.np_1 {
  display: none; }

button[type=submit].btn {
  border: none; }

.btn {
  padding: 0.5em 0.7em; }

.btn-green {
  color: #434343;
  display: block;
  background-color: #C8D51E; }
  .btn-green a {
    color: #434343; }

.btn-blue {
  color: #FFF;
  display: block;
  background-color: #0056b4; }
  .btn-blue a {
    color: #FFF; }

.btn-blue:hover {
  background-color: #1974d7;
  color: #cbd921; }

.btn-disabled {
  background-color: #BBB;
  color: #2d2d2d; }

.btn-disabled:hover {
  background-color: #DDD;
  color: #2d2d2d; }

.btn-green:hover {
  background-color: #95A200;
  color: #FFF; }

.btn-green:hover i {
  color: #FFF; }

.btn-grey {
  color: #FFF;
  background-color: #cccccc; }

.btn-grey-disabled:hover {
  color: #FFF;
  background-color: #cccccc;
  cursor: default; }

.btn-reduced {
  font-size: 0.8em; }

/* style guide button */
.sg-btn {
  font-size: 1em;
  font-weight: bold;
  padding: 0.625rem 0.95rem;
  border-radius: 0.4rem;
  border: 0; }

.sg-btn-slim {
  font-size: 0.8em;
  font-weight: bold;
  padding: .4em 1em;
  border-radius: .2em;
  border: 0; }

/*=============================== donate button start =====================================*/
#page-head .container-row.donate {
  margin-top: 0.5em; }
  #page-head .container-row.donate form .sg-btn-cta {
    width: 90%; }
  #page-head .container-row.donate .col_2 {
    text-align: center; }

/*=============================== donate button end =======================================*/
.sg-btn-cta {
  background-color: #cbd921;
  color: #0056b4; }

.sg-btn-cta:hover {
  cursor: hand;
  background-color: #b3bd35; }

input.sg-btn {
  font-family: 'Lato', sans-serif; }

ol#wo-breadcrumbs.breadcrumb {
  width: 100%;
  display: block;
  float: left;
  background-color: #dfe3ec;
  list-style: none;
  margin: 0;
  padding: 10px; }

ol#wo-breadcrumbs.breadcrumb li {
  float: left;
  padding-top: 5px;
  padding-bottom: 5px; }

ol#wo-breadcrumbs.breadcrumb .separator {
  padding: 5px; }

.content-item-title {
  color: #0056b4;
  font-size: 1.5em;
  line-height: 1.15em; }

.content-news-item .right .sidebar,
.content-news-item .right .side-menu,
.content-journal-editorial-item .right .sidebar,
.content-journal-editorial-item .right .side-menu {
  padding-left: 1em;
  font-size: 0.8em;
  border: none; }
  .content-news-item .right .sidebar h3, .content-news-item .right .sidebar .h3,
  .content-news-item .right .side-menu h3,
  .content-news-item .right .side-menu .h3,
  .content-journal-editorial-item .right .sidebar h3,
  .content-journal-editorial-item .right .sidebar .h3,
  .content-journal-editorial-item .right .side-menu h3,
  .content-journal-editorial-item .right .side-menu .h3 {
    color: #0056b4;
    background: none;
    padding: 0 0 0.3em 0;
    border-bottom: solid 1px #cccccc;
    margin-bottom: 1em; }
  .content-news-item .right .sidebar .item-row,
  .content-news-item .right .side-menu .item-row,
  .content-journal-editorial-item .right .sidebar .item-row,
  .content-journal-editorial-item .right .side-menu .item-row {
    margin-bottom: 1.7em; }
    .content-news-item .right .sidebar .item-row .item-title,
    .content-news-item .right .side-menu .item-row .item-title,
    .content-journal-editorial-item .right .sidebar .item-row .item-title,
    .content-journal-editorial-item .right .side-menu .item-row .item-title {
      margin-left: 0; }
      .content-news-item .right .sidebar .item-row .item-title a,
      .content-news-item .right .side-menu .item-row .item-title a,
      .content-journal-editorial-item .right .sidebar .item-row .item-title a,
      .content-journal-editorial-item .right .side-menu .item-row .item-title a {
        font-weight: bold; }
      .content-news-item .right .sidebar .item-row .item-title .date,
      .content-news-item .right .side-menu .item-row .item-title .date,
      .content-journal-editorial-item .right .sidebar .item-row .item-title .date,
      .content-journal-editorial-item .right .side-menu .item-row .item-title .date {
        margin-top: 0.4em; }

.news-item .content-item-title,
.news-item .content-item-date,
.news-item p {
  margin-bottom: 1em; }

.news-item .content-item-date {
  color: #646466; }

.news-item .social-icons {
  float: none;
  text-align: left;
  margin-bottom: 1em; }
  .news-item .social-icons .addThis {
    margin: 0;
    float: none; }

.news-item .article > img {
  float: right;
  margin-left: 1em;
  margin-bottom: 1em; }

.news-item .article table td {
  vertical-align: middle; }

.news-item ul,
.video-copy ul,
.future.conference.aims ul {
  list-style: disc inside; }

.news-item ol,
.video-copy ol,
.future.conference.aims ol {
  list-style: decimal inside; }
  .news-item ol li::before,
  .video-copy ol li::before,
  .future.conference.aims ol li::before {
    content: "";
    width: 0.5em;
    display: inline-block; }

.news-item ul, .news-item ol,
.video-copy ul,
.video-copy ol,
.future.conference.aims ul,
.future.conference.aims ol {
  line-height: 1em;
  margin-bottom: 1em; }
  .news-item ul li, .news-item ol li,
  .video-copy ul li,
  .video-copy ol li,
  .future.conference.aims ul li,
  .future.conference.aims ol li {
    margin-left: 2em;
    margin-bottom: 0.5em; }

.bespoke-news-item.as-journal-article > p {
  margin-top: 6pt !important;
  margin-bottom: 6pt !important;
  line-height: 150% !important; }
  .bespoke-news-item.as-journal-article > p span {
    font-size: inherit !important; }

.bespoke-news-item.as-journal-article ul {
  list-style-type: disc;
  list-style-position: outside; }
  .bespoke-news-item.as-journal-article ul li {
    margin-top: 6pt;
    margin-bottom: 6pt;
    line-height: 150%; }

.video-speaker {
  background-color: #DFE3EC;
  font-weight: bold;
  padding: 5px; }

.video-categories {
  background-color: #DFE3EC;
  padding: 5px;
  float: left;
  margin-top: 20px; }

.video-categories ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.video-categories ul li {
  list-style: none;
  display: inline;
  padding: 0;
  margin: 0; }

.video-categories ul li::after {
  content: " - "; }

.video-categories ul li:first-child::after,
.video-categories ul li:last-child::after {
  content: ""; }

.video-display-body .addThis {
  margin-bottom: 1em; }

.video-display-body .video-container {
  margin-right: 1em; }
  .video-display-body .video-container .kaltura_player {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden; }
    .video-display-body .video-container .kaltura_player iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .video-display-body .video-container .video-btn-strip a, .video-display-body .video-container .video-btn-strip button {
    display: inline; }
  .video-display-body .video-container .video-btn-strip .btn {
    margin-top: 0; }
  .video-display-body .video-container .kaltura_player.restricted {
    padding-bottom: initial; }
    .video-display-body .video-container .kaltura_player.restricted img {
      width: 100%;
      display: block; }
  .video-display-body .video-container .resource-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: black;
    opacity: 0.7;
    filter: alpha(opacity=70);
    /* IE8- */ }
    .video-display-body .video-container .resource-cover .resource-cover-content {
      width: 75%;
      margin: 0 auto;
      font-size: 1.3em;
      line-height: 1.3em;
      margin-top: 1.5em;
      color: white; }
      .video-display-body .video-container .resource-cover .resource-cover-content a {
        color: #599fe2; }
      .video-display-body .video-container .resource-cover .resource-cover-content a:hover {
        color: #62b2ff; }
  .video-display-body .video-container .covered-resource {
    position: relative; }

.video-display-body .video-detail-strip {
  font-size: 0.8em;
  padding: 0.25em 1em 0.15em;
  background-color: #dfe3ec;
  color: #646466;
  margin-bottom: 1em; }
  .video-display-body .video-detail-strip div {
    display: inline; }
  .video-display-body .video-detail-strip .video-detail-published {
    margin-right: 3em; }
  .video-display-body .video-detail-strip .video-detail-rating {
    margin-right: initial;
    float: right; }
    .video-display-body .video-detail-strip .video-detail-rating .icon-star {
      filter: invert(78%) sepia(64%) saturate(543%) hue-rotate(13deg) brightness(97%) contrast(95%); }
    .video-display-body .video-detail-strip .video-detail-rating .icon-star.white {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(248deg) brightness(107%) contrast(101%); }

.video-display-body .results-grid .grid-item h3, .video-display-body .results-grid .grid-item .h3 {
  flex-grow: 1; }

.video-display-body .conference.details {
  font-size: 0.8em; }
  .video-display-body .conference.details .intro-image {
    width: 100%;
    height: 100%;
    float: none;
    margin-left: 0;
    margin-bottom: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }

.video-display-body .results-grid,
.conference-videos-body .results-grid,
.video-browse-body .results-grid,
.general-browse-results .results-grid {
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .video-display-body .results-grid .grid-item,
  .conference-videos-body .results-grid .grid-item,
  .video-browse-body .results-grid .grid-item,
  .general-browse-results .results-grid .grid-item {
    display: initial;
    width: 28%; }
    .video-display-body .results-grid .grid-item .grid-item-title,
    .conference-videos-body .results-grid .grid-item .grid-item-title,
    .video-browse-body .results-grid .grid-item .grid-item-title,
    .general-browse-results .results-grid .grid-item .grid-item-title {
      font-size: 1.1em;
      height: 22%;
      margin-bottom: 1em; }
    .video-display-body .results-grid .grid-item .grid-item-subtitle,
    .conference-videos-body .results-grid .grid-item .grid-item-subtitle,
    .video-browse-body .results-grid .grid-item .grid-item-subtitle,
    .general-browse-results .results-grid .grid-item .grid-item-subtitle {
      font-style: italic;
      color: #646466;
      height: 5em;
      margin-bottom: 1em; }
    .video-display-body .results-grid .grid-item .grid-item-image,
    .conference-videos-body .results-grid .grid-item .grid-item-image,
    .video-browse-body .results-grid .grid-item .grid-item-image,
    .general-browse-results .results-grid .grid-item .grid-item-image {
      margin-top: 0.5em; }
      .video-display-body .results-grid .grid-item .grid-item-image img,
      .conference-videos-body .results-grid .grid-item .grid-item-image img,
      .video-browse-body .results-grid .grid-item .grid-item-image img,
      .general-browse-results .results-grid .grid-item .grid-item-image img {
        width: 100%;
        display: block; }
    .video-display-body .results-grid .grid-item .grid-item-image-details,
    .conference-videos-body .results-grid .grid-item .grid-item-image-details,
    .video-browse-body .results-grid .grid-item .grid-item-image-details,
    .general-browse-results .results-grid .grid-item .grid-item-image-details {
      padding: 0.25em 0.1em 0.15em 0.5em;
      background-color: #dfe3ec;
      color: #646466;
      margin-bottom: 1em; }
      .video-display-body .results-grid .grid-item .grid-item-image-details div,
      .conference-videos-body .results-grid .grid-item .grid-item-image-details div,
      .video-browse-body .results-grid .grid-item .grid-item-image-details div,
      .general-browse-results .results-grid .grid-item .grid-item-image-details div {
        display: inline; }
      .video-display-body .results-grid .grid-item .grid-item-image-details .icon-star,
      .conference-videos-body .results-grid .grid-item .grid-item-image-details .icon-star,
      .video-browse-body .results-grid .grid-item .grid-item-image-details .icon-star,
      .general-browse-results .results-grid .grid-item .grid-item-image-details .icon-star {
        filter: invert(78%) sepia(64%) saturate(543%) hue-rotate(13deg) brightness(97%) contrast(95%); }
      .video-display-body .results-grid .grid-item .grid-item-image-details .icon-star.white,
      .conference-videos-body .results-grid .grid-item .grid-item-image-details .icon-star.white,
      .video-browse-body .results-grid .grid-item .grid-item-image-details .icon-star.white,
      .general-browse-results .results-grid .grid-item .grid-item-image-details .icon-star.white {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(248deg) brightness(107%) contrast(101%); }
      .video-display-body .results-grid .grid-item .grid-item-image-details .video-detail-rating,
      .conference-videos-body .results-grid .grid-item .grid-item-image-details .video-detail-rating,
      .video-browse-body .results-grid .grid-item .grid-item-image-details .video-detail-rating,
      .general-browse-results .results-grid .grid-item .grid-item-image-details .video-detail-rating {
        float: right;
        margin-right: 0.5em; }

.video-display-body .results-grid .grid-item {
  width: 29%; }
  .video-display-body .results-grid .grid-item .grid-item-title {
    max-height: 17%;
    height: 17%; }
  .video-display-body .results-grid .grid-item .grid-item-subtitle {
    flex-shrink: 0; }

.video-browse-body .video-carousel {
  margin-bottom: 1em;
  line-height: 1.6em; }
  .video-browse-body .video-carousel .item-title {
    font-size: 1.25em; }
  .video-browse-body .video-carousel .item-subtitle {
    font-style: italic; }

.video-btn-strip {
  width: 100%; }

.video-btn-strip .btn {
  margin: 1.5em 0.8em 0 0.8em;
  text-align: center; }

.video-btn-strip .btn:first-child {
  margin-left: 0; }

.video-btn-strip .btn:last-child {
  margin-right: 0; }

.video-btn-strip button {
  margin-right: 0.8em !important; }

.video-btn-strip .addThis {
  margin-top: 35px; }

.video-copy .addThis {
  margin-top: 10px;
  text-align: right; }

table.vimeocuetable {
  font-size: small; }
  table.vimeocuetable td {
    padding: 0.75em; }
  table.vimeocuetable button {
    border: solid 1px grey;
    border-radius: 3px; }
  table.vimeocuetable td.sectionheader {
    font-weight: bold;
    font-size: 1.25em;
    color: black; }
  table.vimeocuetable td p {
    margin: 0; }
  table.vimeocuetable button:hover, table.vimeocuetable button.active {
    border-color: #3192bb; }

.video-speaker {
  margin-bottom: 1em; }

.video-copy {
  font-size: 0.8em; }

.video-sponsor {
  font-size: 0.8em;
  font-style: italic; }

.transcript {
  font-size: 0.8em;
  margin-bottom: 2em; }
  .transcript .content {
    -webkit-transition: max-height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    max-height: 0;
    overflow: hidden; }
    .transcript .content strong {
      margin-right: 0.5em; }
    .transcript .content p {
      margin-top: 1em;
      margin-bottom: 1em;
      line-height: 150%;
      text-align: justify; }

.transcript.active .content {
  max-height: 50000px; }

#page-user .page-title {
  margin-top: 20px; }

.widget-grid-user-dashboard {
  border: 0; }

.widget-grid-user-dashboard .col-12 {
  display: flex;
  flex-direction: column; }

.widget-grid-user-dashboard .btn {
  height: 100%;
  margin: 10px 0;
  padding: 20px;
  display: flex;
  align-items: center; }

.widget-grid-user-dashboard .btn-label {
  width: 100%;
  word-wrap: break-word;
  display: inline-block; }

.widget-grid-user-dashboard .btn i, .widget-grid-user-dashboard .btn-green svg {
  text-align: right;
  float: right;
  margin-left: 10px;
  fill: #434343;
  height: 100%; }

.widget-grid-user-dashboard .btn.icon-video-camera::after, .widget-grid-user-dashboard .btn.icon-user::after {
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
  margin: 0 0 0 0.4rem;
  filter: invert(25%) sepia(0%) saturate(1%) hue-rotate(161deg) brightness(96%) contrast(90%); }

#login-content {
  margin-top: 150px; }

#page-login {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  background-color: #f4f4f4;
  padding: 20px;
  border: 1px solid #BBB; }
  #page-login .row.buttons {
    margin-top: 2em; }
  #page-login .hidden.iframe {
    height: 0.1em; }

.forgotten-password .page-title {
  margin-bottom: 1.5em; }

.forgotten-password form label {
  width: 5em; }

.forgotten-password form input {
  width: 100%; }

.forgotten-password .buttons.row {
  margin-top: 2em; }

.container-flex.user .breadcrumbs {
  margin-bottom: 1em; }

.container-flex.user .content-specific-nav {
  margin-bottom: 1em; }

.container-flex.user .widget-list .item-row {
  width: 100%; }

.container-flex.user .icon-star::after {
  height: 1rem;
  width: 1rem;
  background-size: 1rem;
  filter: invert(10%) sepia(50%) saturate(19%) hue-rotate(316deg) brightness(98%) contrast(83%); }

.ui-autocomplete {
  max-height: 20em;
  overflow-y: auto;
  overflow-x: hidden; }

#e-learning-module-container.module-player {
  position: relative;
  padding-top: 65%;
  margin-bottom: 40px; }

#e-learning-module-container.module-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.e-learning-flag-container {
  margin-bottom: 0.5em; }
  .e-learning-flag-container .e-learning-flag {
    width: 100%;
    position: relative;
    height: 5px; }

.e-learning-thumbnail {
  background-color: #ccc; }

.e-learning-flag-course {
  background-color: #0056b4; }

.e-learning-flag-module {
  background-color: #d8e523; }

.e-learning-flag-label {
  color: #FFF;
  padding: 5px; }

.e-learning-flag-label-course {
  background-color: #0056b4; }

.e-learning-flag-label-module {
  color: #0056b4; }

.e-learning-course-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em; }
  .e-learning-course-container article {
    width: 48%; }
    .e-learning-course-container article h2, .e-learning-course-container article .h2 {
      color: #0056b4; }
  .e-learning-course-container .course-detail {
    width: 48%; }
    .e-learning-course-container .course-detail img {
      width: 100%;
      display: block; }

.course-button-bar {
  border-bottom: 1px solid #dedede;
  margin-bottom: 1em;
  padding-bottom: 0.5em; }
  .course-button-bar button {
    margin: 0 1em 0 0 !important; }
  .course-button-bar button:hover {
    cursor: pointer; }

.module-run .content-specific-nav,
.module-intro .content-specific-nav,
.module-reflect .content-specific-nav {
  margin-bottom: 2em; }

.module-run .page-title,
.module-intro .page-title,
.module-reflect .page-title {
  margin-bottom: 0.5em; }

.module-run h1, .module-run .h1, .module-run h2, .module-run .h2,
.module-intro h1,
.module-intro .h1,
.module-intro h2,
.module-intro .h2,
.module-reflect h1,
.module-reflect .h1,
.module-reflect h2,
.module-reflect .h2 {
  color: #0056b4;
  font-size: 1.5em;
  margin-top: 1.5em;
  margin-bottom: 1em; }

.module-run h2, .module-run .h2,
.module-intro h2,
.module-intro .h2,
.module-reflect h2,
.module-reflect .h2 {
  font-size: 1.1em; }

.module-run .module-not-found,
.module-intro .module-not-found,
.module-reflect .module-not-found {
  height: 5em; }

.elearning-descriptive-text p {
  margin-top: 6pt;
  margin-bottom: 6pt; }

.elearning-descriptive-text ul {
  list-style: disc inside none; }

.elearning-descriptive-text .addThis {
  margin-bottom: 0.5em; }

.elearning-descriptive-text a {
  color: #599fe2;
  text-decoration: underline;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto; }

.elearning-descriptive-text a:hover {
  color: #0056b4; }

.elearning-descriptive-text a:active {
  color: #0056b4; }

.elearning-module {
  margin-bottom: 3em; }
  .elearning-module .button-bar {
    line-height: 1em; }

.module-run {
  margin-bottom: 4em; }
  .module-run .button-bar .addthis-container .cta {
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 1em;
    line-height: 34px; }
  .module-run .button-bar .addthis-container a {
    position: relative;
    bottom: -4px;
    padding: 0; }

.elearning-courses-grid .grid-item {
  background-color: unset;
  margin-bottom: 4em;
  width: 48%;
  padding: 0; }
  .elearning-courses-grid .grid-item img {
    width: 100%;
    display: block; }

.elearning-courses-grid .grid-item > a:nth-child(2) {
  flex-grow: 1; }

.course-browse .grid-item,
.module-browse .grid-item {
  width: 32%;
  font-size: 0.8em; }

.image-16x9 {
  width: 100%;
  padding-top: 56.25%;
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: 100% 100%; }

.elearning-registration-prompt .registration-info {
  color: #2d2d2d;
  font-weight: bold;
  margin-bottom: 0.25em; }

.elearning-registration-prompt .registration-sub-info {
  color: #646466;
  margin-bottom: 1.5em; }

.elearning-registration-prompt .registration-cta-bar {
  font-size: 0.875em;
  margin-bottom: 1.5em; }
  .elearning-registration-prompt .registration-cta-bar .btn {
    margin-right: 1em; }
  .elearning-registration-prompt .registration-cta-bar a.login span.question {
    color: #646466; }
  .elearning-registration-prompt .registration-cta-bar a.login span.login {
    color: #0056b4;
    font-weight: bold; }
  .elearning-registration-prompt .registration-cta-bar a.login span.login:hover {
    border-bottom: solid 2px #c6d420; }

#elearning-proceed-completion-btn a.reflection-placeholder {
  display: none; }

#elearning-proceed-completion-btn a.reflection {
  display: inline-block; }

#elearning-proceed-completion-btn.disabled a.reflection-placeholder {
  display: inline-block; }

#elearning-proceed-completion-btn.disabled a.reflection {
  display: none; }

.group-button-wrapper {
  min-width: 5em;
  position: relative; }
  .group-button-wrapper button.group {
    border: solid 3px #233149;
    margin: 0 !important;
    width: 100%;
    height: 100%;
    background-size: cover; }
  .group-button-wrapper button.group.city_cancer_challenge {
    background-image: url("/images/original/42084");
    background-repeat: no-repeat;
    background-color: #21314a;
    background-position-x: center;
    background-size: contain;
    border-radius: 5px; }
  .group-button-wrapper .overlay {
    z-index: 10;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: grey;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity)";
    /* IE 5-7 */
    filter: alpha(opacity=60%);
    /* Netscape */
    -moz-opacity: 0.6;
    /* Safari 1.x */
    -khtml-opacity: 0.6;
    /* Good browsers */
    opacity: 0.6;
    border-radius: 6px; }
  .group-button-wrapper .overlay:hover {
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity)";
    /* IE 5-7 */
    filter: alpha(opacity=30%);
    /* Netscape */
    -moz-opacity: 0.3;
    /* Safari 1.x */
    -khtml-opacity: 0.3;
    /* Good browsers */
    opacity: 0.3; }
  .group-button-wrapper button.joined {
    border: solid 3px #cbd921; }

body.module-player .module-run {
  width: 100%;
  height: 100%; }
  body.module-player .module-run .iframe-column {
    background-color: lightgrey; }
    body.module-player .module-run .iframe-column .module-player {
      height: 95%; }
      body.module-player .module-run .iframe-column .module-player iframe {
        width: 100%;
        height: 100%; }
  body.module-player .module-run .buttons-column {
    background-color: #b5b54b; }

@media screen and (min-width: 0px) and (max-width: 7670px) and (orientation: portrait) {
  body.module-player {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0; } }

.video-categories.journal,
.video-categories.video {
  font-size: 0.8em;
  float: none;
  display: block;
  margin-bottom: 2em; }

.page-title.related {
  margin-bottom: 1em; }

.results-list.articles .item-row {
  margin-bottom: 2.5em; }
  .results-list.articles .item-row .item-thumb img {
    display: block; }
  .results-list.articles .item-row .item-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .results-list.articles .item-row .item-title .item-details {
      font-size: 0.95em; }

.interview-wrapper {
  text-align: center; }
  .interview-wrapper h2, .interview-wrapper .h2 {
    font-size: 1.2em;
    color: #0056b4;
    margin-bottom: 0.5em; }
  .interview-wrapper .author-intro {
    font-size: 0.8em;
    margin-bottom: 2em; }
  .interview-wrapper .kaltura_player iframe {
    min-height: 22em;
    width: 100%; }
  .interview-wrapper .interview {
    margin-top: 1em;
    margin-bottom: 2em;
    text-align: left; }

.embedded-media.default-height .kaltura_player iframe {
  min-height: 20em;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 1em; }

.journal-special-issue {
  margin-bottom: 3em; }
  .journal-special-issue .title {
    font-size: 1.5em;
    color: #0056b4;
    margin-bottom: 0.5em;
    line-height: 1.2em; }
  .journal-special-issue .page-title-sub {
    margin-bottom: 1em; }
    .journal-special-issue .page-title-sub .page-title-sub-left {
      float: none;
      width: 100%;
      text-align: initial;
      margin-bottom: 0.4em; }
    .journal-special-issue .page-title-sub .summary {
      font-weight: bold; }
  .journal-special-issue .item-details {
    background-color: inherit; }
  .journal-special-issue .intro .thumb {
    float: right;
    margin: 0 0 4em 4em;
    width: 40%; }
    .journal-special-issue .intro .thumb img {
      width: 100%; }
  .journal-special-issue .intro .summary p {
    margin-bottom: 1em; }
  .journal-special-issue .intro a {
    border-bottom: solid 2px #cbd921; }
  .journal-special-issue .video-special-issue-abstract {
    margin-top: 2em;
    text-align: center; }
    .journal-special-issue .video-special-issue-abstract .title {
      font-size: 1em; }
    .journal-special-issue .video-special-issue-abstract .kaltura_player iframe {
      min-height: 22em;
      width: 100%; }
  .journal-special-issue .full-article img {
    float: right;
    margin-left: 1em;
    margin-bottom: 1em; }
  .journal-special-issue .link-to-abstract {
    margin-top: 1em; }

.journal-article-item .social-icons,
.journal-editorial-item .social-icons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  float: none;
  text-align: left;
  margin-bottom: 1em; }
  .journal-article-item .social-icons .addThis,
  .journal-editorial-item .social-icons .addThis {
    margin: 0;
    float: none;
    display: inline-block; }
  .journal-article-item .social-icons .summary,
  .journal-editorial-item .social-icons .summary {
    color: #FFF;
    background-color: #0056b4; }
  .journal-article-item .social-icons .article-buttons,
  .journal-editorial-item .social-icons .article-buttons {
    display: flex;
    align-items: flex-end;
    text-align: right; }
    .journal-article-item .social-icons .article-buttons .btn,
    .journal-editorial-item .social-icons .article-buttons .btn {
      cursor: pointer;
      display: inline-block;
      margin-right: 0.5rem; }

.journal-article-item .item-details,
.journal-editorial-item .item-details {
  margin-bottom: 1em; }

.journal-article-item .article-content,
.journal-editorial-item .article-content {
  border-bottom: solid 2px lightgrey;
  padding-bottom: 1em;
  margin-bottom: 0.5em; }
  .journal-article-item .article-content p,
  .journal-editorial-item .article-content p {
    margin-top: 6pt !important;
    margin-bottom: 6pt !important;
    line-height: 150% !important; }
    .journal-article-item .article-content p span,
    .journal-editorial-item .article-content p span {
      font-size: inherit !important; }
  .journal-article-item .article-content .journal-article-type,
  .journal-editorial-item .article-content .journal-article-type {
    margin-bottom: 0.5em; }
  .journal-article-item .article-content h1, .journal-article-item .article-content .h1,
  .journal-editorial-item .article-content h1,
  .journal-editorial-item .article-content .h1 {
    margin: 0;
    line-height: 1.25em;
    font-size: 1.5em;
    color: #0056b4;
    margin-bottom: 0.3em; }
  .journal-article-item .article-content h2, .journal-article-item .article-content .h2,
  .journal-editorial-item .article-content h2,
  .journal-editorial-item .article-content .h2 {
    margin: 0.8em 0 0.3em;
    line-height: 1.2em;
    font-size: 1.15em;
    color: #0056b4;
    margin-bottom: 0.3em; }
  .journal-article-item .article-content a,
  .journal-editorial-item .article-content a {
    color: #599fe2;
    text-decoration: underline;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto; }
  .journal-article-item .article-content a:hover,
  .journal-editorial-item .article-content a:hover {
    color: #0056b4; }
  .journal-article-item .article-content a:active,
  .journal-editorial-item .article-content a:active {
    color: #0056b4; }
  .journal-article-item .article-content img,
  .journal-editorial-item .article-content img {
    width: initial !important;
    max-width: 95% !important;
    height: initial !important;
    margin-top: 1em;
    margin-bottom: 0.25em; }
  .journal-article-item .article-content ol,
  .journal-editorial-item .article-content ol {
    list-style-type: decimal;
    list-style-position: inside; }
    .journal-article-item .article-content ol li,
    .journal-editorial-item .article-content ol li {
      margin-top: 6pt;
      margin-bottom: 6pt;
      line-height: 150%; }

.journal-article-item .article-metrics,
.journal-editorial-item .article-metrics {
  display: inline-block; }
  .journal-article-item .article-metrics .details .summary,
  .journal-editorial-item .article-metrics .details .summary {
    color: inherit;
    background-color: #ccc; }
  .journal-article-item .article-metrics .details .metric,
  .journal-editorial-item .article-metrics .details .metric {
    display: inline-block;
    max-width: 7em;
    height: 4em;
    margin-left: 1em; }
    .journal-article-item .article-metrics .details .metric .value,
    .journal-editorial-item .article-metrics .details .metric .value {
      padding: 0.2em 0.7em;
      background-color: #cbd921;
      color: #FFF;
      border-bottom-right-radius: 1em;
      border-top-right-radius: 1em;
      font-weight: bold; }
    .journal-article-item .article-metrics .details .metric .image,
    .journal-editorial-item .article-metrics .details .metric .image {
      height: 3em;
      white-space: nowrap;
      margin-bottom: 0.6em; }
      .journal-article-item .article-metrics .details .metric .image .helper,
      .journal-editorial-item .article-metrics .details .metric .image .helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle; }
      .journal-article-item .article-metrics .details .metric .image img,
      .journal-editorial-item .article-metrics .details .metric .image img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle; }

.journal-article-item .summary,
.journal-editorial-item .summary {
  display: inline-block;
  vertical-align: top;
  font-size: 0.8em;
  border-radius: 0.2em;
  border: 0; }
  .journal-article-item .summary .title,
  .journal-editorial-item .summary .title {
    font-weight: bold; }
  .journal-article-item .summary .value,
  .journal-editorial-item .summary .value {
    padding-left: 0.5em;
    padding-right: 0.5em; }

.journal-editorials-list .item-row {
  margin-bottom: 2.5em;
  border: solid 2px lightgrey;
  border-radius: 0.3em; }
  .journal-editorials-list .item-row .item-title {
    margin-left: 0;
    margin-bottom: 0.35em;
    padding: .5em .5em 0;
    font-weight: bold; }
  .journal-editorials-list .item-row .author-names {
    padding: .5em .5em;
    color: #646466; }
  .journal-editorials-list .item-row .item-details {
    padding: .4em .5em .2em; }

.journal-special-issues-list .results-list .item-row {
  margin-bottom: 2.5em; }
  .journal-special-issues-list .results-list .item-row .title {
    font-weight: bold; }
  .journal-special-issues-list .results-list .item-row .guest-editors {
    font-style: italic;
    color: #646466; }

.journal-body .content-specific-search {
  margin-bottom: 1em; }

.journal-body .grid-item {
  min-height: 17em; }
  .journal-body .grid-item .grid-item-title {
    flex: 2; }
  .journal-body .grid-item .grid-item-subtitle {
    flex: 2;
    font-size: 0.8em;
    margin-bottom: 1em; }
  .journal-body .grid-item .grid-item-image {
    flex: 2; }
    .journal-body .grid-item .grid-item-image a {
      height: 8em;
      display: block;
      background-position: top;
      background-repeat: no-repeat;
      background-size: contain; }

.journal-body #metrics-full .altmetric.donut {
  display: inline-block;
  margin-left: 2em; }

.journal-titling {
  display: flex;
  align-items: center; }
  .journal-titling .submissions {
    text-align: center; }
    .journal-titling .submissions div.submit-article {
      display: inline-block; }
  .journal-titling .issn {
    padding-bottom: 0.5em;
    color: #0056b4;
    font-size: 0.8em;
    font-weight: bold; }
  .journal-titling .blurb .editor-in-chief {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1.02em; }
    .journal-titling .blurb .editor-in-chief a {
      color: #599fe2; }
    .journal-titling .blurb .editor-in-chief a:hover {
      color: #0056b4;
      border-bottom: solid 2px #c6d420; }

.journal-titling.partial {
  margin-bottom: 0.4em; }
  .journal-titling.partial .submissions {
    text-align: right; }

.journal-body.editorial-board h1, .journal-body.editorial-board .h1 {
  margin-bottom: 1em; }

.journal-body.editorial-board .editor-in-chief {
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  margin-left: 10px;
  font-size: 1.02em; }
  .journal-body.editorial-board .editor-in-chief a {
    color: #599fe2; }
  .journal-body.editorial-board .editor-in-chief a:hover {
    color: #0056b4;
    border-bottom: solid 2px #c6d420; }

.journal-body.editorial-board .results-list a {
  color: #599fe2; }

.journal-body.editorial-board .results-list a:hover {
  color: #0056b4;
  border-bottom: solid 2px #c6d420; }

#page-content .submit-article-page p, #page-user p {
  margin-bottom: 1em; }

#page-content .submit-article-page .submissions.info, #page-user .submissions.info {
  border: solid 2px #cbd921;
  padding: 1em;
  border-radius: 5px;
  margin-bottom: 1.5em; }
  #page-content .submit-article-page .submissions.info a, #page-user .submissions.info a {
    color: #0056b4; }
  #page-content .submit-article-page .submissions.info a:hover, #page-user .submissions.info a:hover {
    color: #599fe2;
    border-bottom: solid 2px #c6d420; }
  #page-content .submit-article-page .submissions.info table.author-submissions-list, #page-user .submissions.info table.author-submissions-list {
    margin-bottom: 1em; }
    #page-content .submit-article-page .submissions.info table.author-submissions-list td, #page-user .submissions.info table.author-submissions-list td {
      padding: 0.2em 0.35em; }

#page-content .content-item-conference .addThis {
  margin-bottom: 1em; }

#page-content .content-item-conference .intro-image {
  width: 100%;
  height: 100%;
  float: none;
  margin-left: 0;
  margin-bottom: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

#page-content .content-item-conference .conference.aims {
  line-height: 1.5em;
  margin-bottom: 1em; }

#page-content .content-item-conference .conference.website {
  line-height: 1.5em;
  margin-bottom: 2em; }

#page-content .content-item-conference .aims.hidden {
  display: none; }

#page-content .results-grid.conferences {
  margin-bottom: 2em; }
  #page-content .results-grid.conferences .item-row {
    margin-top: 4em;
    width: 50%;
    font-size: 0.8em; }
    #page-content .results-grid.conferences .item-row .item-title {
      margin-right: 1em; }
      #page-content .results-grid.conferences .item-row .item-title .date {
        font-style: italic; }
      #page-content .results-grid.conferences .item-row .item-title .article-title {
        font-weight: bold; }
      #page-content .results-grid.conferences .item-row .item-title .icon-video-camera {
        display: flex; }
      #page-content .results-grid.conferences .item-row .item-title .icon-video-camera:after {
        width: 0.8rem;
        height: 0.8rem;
        background-size: 0.8rem;
        margin: 0;
        filter: invert(41%) sepia(4%) saturate(168%) hue-rotate(202deg) brightness(92%) contrast(88%); }
      #page-content .results-grid.conferences .item-row .item-title .item-content-details span {
        margin-left: 0.5em; }
    #page-content .results-grid.conferences .item-row .item-thumb {
      padding-right: 1em;
      border-right: solid 1px #ccc; }
      #page-content .results-grid.conferences .item-row .item-thumb a {
        height: 7em;
        display: block;
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain; }

#page-content .conference.details {
  margin-bottom: 2em; }
  #page-content .conference.details .description h1, #page-content .conference.details .description .h1 {
    margin-bottom: 0.5em; }
  #page-content .conference.details .description .date,
  #page-content .conference.details .description .location {
    color: #646466; }
  #page-content .conference.details .description .date {
    margin-bottom: 0.1em; }
  #page-content .conference.details .description .conference.website:hover {
    text-decoration: underline;
    text-decoration-color: #cbd921; }
    #page-content .conference.details .description .conference.website:hover .content-item-title {
      color: #599fe2; }

#page-content .conference.website a {
  color: #0056b4; }

#page-content .conference.website a:hover {
  color: #599fe2;
  border-bottom: solid 2px #c6d420; }

#page-content .widget-grid.home .results-grid.conferences .item-row {
  margin-top: 2em;
  font-size: inherit; }
  #page-content .widget-grid.home .results-grid.conferences .item-row .item-title .date {
    display: none; }
  #page-content .widget-grid.home .results-grid.conferences .item-row .item-title .article-title {
    font-weight: normal; }
  #page-content .widget-grid.home .results-grid.conferences .item-row .item-thumb {
    border-right: none; }
    #page-content .widget-grid.home .results-grid.conferences .item-row .item-thumb a {
      height: 7em;
      display: block;
      background-position: top;
      background-repeat: no-repeat;
      background-size: contain; }

#page-content button {
  margin: 0 auto; }

#page-content .pager.archive a:nth-child(2) {
  display: none; }

#page-content .icon-video-camera-standard {
  display: flex; }
  #page-content .icon-video-camera-standard:after {
    width: 0.8rem;
    height: 0.8rem;
    background-size: 0.8rem;
    margin: 0;
    filter: invert(41%) sepia(4%) saturate(168%) hue-rotate(202deg) brightness(92%) contrast(88%); }

.infobox {
  border: solid 3px #cbd921;
  border-radius: 1em;
  padding: 1.5em; }

.static-page .page-title,
.video-copy .page-title {
  margin-bottom: 1em; }

.static-page p,
.video-copy p {
  margin-bottom: 1em; }
  .static-page p a,
  .video-copy p a {
    text-decoration: underline; }

.static-page ul li,
.video-copy ul li {
  margin-bottom: 0.5em; }
  .static-page ul li a,
  .video-copy ul li a {
    text-decoration: underline; }

.static-page ul > li,
.video-copy ul > li {
  margin-left: 2em; }

.static-page h2, .static-page .h2,
.video-copy h2,
.video-copy .h2 {
  color: #0056b4;
  font-size: 1.2em;
  line-height: 1em;
  margin-top: 1em;
  margin-bottom: 0.5em; }
  .static-page h2 a, .static-page .h2 a,
  .video-copy h2 a,
  .video-copy .h2 a {
    color: #0056b4; }

.static-page table td,
.video-copy table td {
  vertical-align: unset; }

.static-page a.button,
.video-copy a.button {
  background-color: #0056b4;
  color: #e9ecf4;
  border-radius: 0.25em;
  display: inline-block;
  text-align: center; }

.static-page a.button:hover,
.video-copy a.button:hover {
  background-color: #1974d7; }

.static-page a.button.underline-on-hover,
.video-copy a.button.underline-on-hover {
  text-decoration: none; }

.static-page a.button.underline-on-hover:hover,
.video-copy a.button.underline-on-hover:hover {
  text-decoration: underline; }

.static-page-container.about-us, .static-page-container.support-us {
  margin-right: 1em; }
  .static-page-container.about-us h2.title, .static-page-container.about-us .title.h2, .static-page-container.support-us h2.title, .static-page-container.support-us .title.h2 {
    margin: 0;
    line-height: 1em;
    font-size: 1.5em;
    font-weight: 600;
    color: #0056b4;
    border-left: solid 0.3em #cbd921;
    padding-left: 0.4em;
    margin-bottom: 0.3em;
    margin-bottom: 1em; }
  .static-page-container.about-us .icon, .static-page-container.support-us .icon {
    border-top: solid 2px lightgrey;
    padding-top: 1em; }
  .static-page-container.about-us p, .static-page-container.support-us p {
    text-align: left !important; }
    .static-page-container.about-us p a, .static-page-container.support-us p a {
      font-weight: bold;
      text-decoration: none; }
    .static-page-container.about-us p em.refs, .static-page-container.support-us p em.refs {
      font-weight: bold; }
  .static-page-container.about-us .boxed, .static-page-container.support-us .boxed {
    border: solid 3px #cbd921;
    border-radius: 0.5em;
    padding: 1em;
    margin-top: 2em;
    margin-bottom: 2em; }
  .static-page-container.about-us .info-donate, .static-page-container.support-us .info-donate {
    display: none; }
  .static-page-container.about-us .donate-button, .static-page-container.support-us .donate-button {
    display: initial !important; }
    .static-page-container.about-us .donate-button input[type=submit], .static-page-container.support-us .donate-button input[type=submit] {
      background-color: #0056b4;
      color: #e9ecf4;
      font-size: 0.8em;
      font-weight: bold;
      padding: .4em 1em;
      border-radius: .2em;
      border: 0; }
    .static-page-container.about-us .donate-button input[type=submit]:hover, .static-page-container.support-us .donate-button input[type=submit]:hover {
      background-color: #1974d7; }
  .static-page-container.about-us .donate-button.right, .static-page-container.support-us .donate-button.right {
    text-align: right; }
  .static-page-container.about-us .intro img, .static-page-container.support-us .intro img {
    display: block !important;
    height: 13em;
    float: right;
    margin-left: 0.5em; }
  .static-page-container.about-us h2, .static-page-container.about-us .h2, .static-page-container.support-us h2, .static-page-container.support-us .h2 {
    font-weight: bold;
    line-height: 2.1em; }
    .static-page-container.about-us h2 img, .static-page-container.about-us .h2 img, .static-page-container.support-us h2 img, .static-page-container.support-us .h2 img {
      display: inline-block !important;
      height: 2em;
      float: left;
      margin-right: 1em; }
  .static-page-container.about-us ul, .static-page-container.support-us ul {
    list-style-position: outside;
    line-height: 1.5em;
    margin-bottom: 1.5em; }
    .static-page-container.about-us ul li, .static-page-container.support-us ul li {
      margin-left: 2em;
      margin-bottom: 1.3em; }
  .static-page-container.about-us .affiliations, .static-page-container.support-us .affiliations {
    display: block !important;
    border-top: solid 2px lightgrey;
    margin-top: 2em;
    padding-top: 1em; }
    .static-page-container.about-us .affiliations .panel, .static-page-container.support-us .affiliations .panel {
      display: inline-block;
      vertical-align: top; }
      .static-page-container.about-us .affiliations .panel h2, .static-page-container.about-us .affiliations .panel .h2, .static-page-container.support-us .affiliations .panel h2, .static-page-container.support-us .affiliations .panel .h2 {
        border-top: initial;
        padding-top: initial;
        margin-top: 0;
        margin-bottom: 0.5em;
        line-height: 1.3em; }
    .static-page-container.about-us .affiliations img, .static-page-container.support-us .affiliations img {
      width: 7em;
      margin-right: 1em;
      vertical-align: top; }
    .static-page-container.about-us .affiliations .row2, .static-page-container.support-us .affiliations .row2 {
      margin-top: 2em; }
      .static-page-container.about-us .affiliations .row2 .panel1, .static-page-container.support-us .affiliations .row2 .panel1 {
        max-width: 60%; }
        .static-page-container.about-us .affiliations .row2 .panel1 img, .static-page-container.support-us .affiliations .row2 .panel1 img {
          width: 6em; }
      .static-page-container.about-us .affiliations .row2 .panel2, .static-page-container.support-us .affiliations .row2 .panel2 {
        max-width: 40%; }
        .static-page-container.about-us .affiliations .row2 .panel2 img, .static-page-container.support-us .affiliations .row2 .panel2 img {
          width: 13em; }
  .static-page-container.about-us .leading-block, .static-page-container.support-us .leading-block {
    margin: 0;
    border-left: solid 0.3em #cbd921;
    padding-left: 0.4em;
    margin-bottom: 0.3em; }
  .static-page-container.about-us .article, .static-page-container.support-us .article {
    line-height: 150%; }

#advanced-search {
  margin-top: 2em;
  margin-bottom: 2em; }

.category-search-page .search-results {
  margin-top: 4em; }

.category-search-page .accordion {
  padding: 0 0 0 1em; }

.category-search-page .accordion-panel {
  padding: initial;
  overflow: initial; }
  .category-search-page .accordion-panel .form-control input {
    display: inline;
    margin-right: 0.5em;
    float: none;
    margin-top: inherit; }
  .category-search-page .accordion-panel .form-control label {
    width: initial;
    min-width: 20%;
    display: inline-block;
    padding: initial;
    margin-right: 0.5em;
    white-space: nowrap;
    font-size: 0.7em; }

.category-search-page button.get-results {
  min-width: 8em;
  text-align: center; }

.general-browse-results .content-specific-nav {
  margin-bottom: 2em; }

.general-browse-results .elearning-courses-grid {
  font-size: 0.8em; }
  .general-browse-results .elearning-courses-grid .grid-item {
    width: 32%; }
  .general-browse-results .elearning-courses-grid .elearning-meta-row .meta-detail {
    padding-right: 0.5em; }
    .general-browse-results .elearning-courses-grid .elearning-meta-row .meta-detail .content {
      padding-right: 0.5em; }
    .general-browse-results .elearning-courses-grid .elearning-meta-row .meta-detail .content.e-learning-percentage-complete span {
      width: 7em;
      bottom: 0.3em; }
  .general-browse-results .elearning-courses-grid .elearning-meta-row .meta-detail:last-child .content {
    width: 7em; }

.general-browse-results .results-grid.conferences {
  font-size: inherit; }

.sidebar .menu-section {
  margin-bottom: 10px; }

.sidebar h3, .sidebar .h3 {
  border-bottom: 1px solid #ccc;
  margin: 0 0 10px 0;
  padding-bottom: 5px; }

.menu-section-standard ul li {
  padding: 5px 0 5px; }

nav.menu-explore h3, nav.menu-explore .h3 {
  margin: 0; }

nav.menu-explore {
  text-align: center;
  width: 100%; }

nav.menu-explore ul li {
  display: inline-block;
  margin: 10px; }

nav.menu-explore ul li a {
  font-weight: 900; }

.posts-nav {
  border-bottom: solid 2px lightgrey;
  margin-bottom: 2em; }
  .posts-nav .menu-item {
    display: inline-block;
    min-width: 5em;
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    padding: .4em 1em;
    border-radius: .2em;
    border: 0;
    background-color: #0056b4;
    color: #e9ecf4; }
    .posts-nav .menu-item a {
      color: inherit; }
  .posts-nav .menu-item.active {
    background-color: #cbd921;
    color: #0056b4; }

#main-menu-dialog {
  width: 100% !important;
  color: yellow; }

.ui-dialog.xmas-message {
  background: none;
  border: none;
  padding: 0; }
  .ui-dialog.xmas-message #xmas-message-modal, .ui-dialog.xmas-message #popup-message-modal {
    width: 100% !important;
    padding: 0; }
    .ui-dialog.xmas-message #xmas-message-modal > img, .ui-dialog.xmas-message #popup-message-modal > img {
      width: 100%; }
  .ui-dialog.xmas-message .ui-dialog-titlebar {
    padding-top: 0;
    position: absolute;
    top: -0.5rem;
    right: 0.5rem; }
    .ui-dialog.xmas-message .ui-dialog-titlebar .ui-dialog-titlebar-close {
      z-index: 2000;
      width: 1em;
      height: 1em;
      border-radius: 50%; }
  .ui-dialog.xmas-message a.hidden {
    height: 0;
    width: 0; }
  .ui-dialog.xmas-message .icons {
    background-color: #bf6666; }
    .ui-dialog.xmas-message .icons .col:hover {
      background-color: grey; }

#search-form {
  text-align: center; }

#panel-search input[type='text'] {
  padding: 10px;
  width: 200px;
  margin: 5px; }

.field-group {
  margin: 0 auto; }

.field-group label {
  display: block;
  text-align: center; }

#panel-search .search-conference select[name='region_id'],
#panel-search .search-conference select[name='country_id'],
#panel-search .search-e_learning select[name='profession'] {
  width: 125px; }

#panel-search .search-conference input[type='text'] {
  width: 250px; }

#panel-search button {
  border: none;
  background-color: transparent; }

#panel-search button i {
  font-size: 20px; }

#panel-search button:hover {
  cursor: pointer; }

#panel-search {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ccc; }

.form-inline {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.field-group {
  float: left; }

.user.dashboard.form .form-group.row {
  margin-bottom: 1em; }
  .user.dashboard.form .form-group.row input, .user.dashboard.form .form-group.row select {
    width: 100%; }
  .user.dashboard.form .form-group.row select {
    height: 2.3em; }
  .user.dashboard.form .form-group.row .form-input-container.radio {
    display: flex;
    align-items: center; }
    .user.dashboard.form .form-group.row .form-input-container.radio input {
      width: auto; }
  .user.dashboard.form .form-group.row .form-input-container.radio.collection input {
    margin-right: 0.5em; }
  .user.dashboard.form .form-group.row .form-input-container.radio.collection label {
    width: 20%; }

.user.dashboard.form .form-group.row.yes-no input {
  width: 3em; }

.user.dashboard.form .form-group.row.yes-no .form-control label {
  margin-right: 2em; }

#page-registration-validation h1, #page-registration-validation .h1 {
  margin-bottom: 2em; }

#page-registration-validation p {
  line-height: 2em; }

#page-registration form[name="registration"] .form-group.apiary {
  position: absolute;
  left: -5000px;
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Netscape */
  -moz-opacity: 0;
  /* Safari 1.x */
  -khtml-opacity: 0;
  /* Good browsers */
  opacity: 0; }

body#page-sitemap {
  margin: 5%;
  padding: 0; }

table#sitemap {
  width: 100%; }

table#sitemap td, table#sitemap th {
  padding: 10px; }

table#sitemap th {
  background-color: #0056b4;
  color: #FFF;
  text-align: left; }

table#sitemap td.cell-image {
  padding: 5px;
  width: 200px; }

table#sitemap td.cell-image img {
  width: 200px; }

table#sitemap tr:nth-child(even) {
  background: #ccc; }

table#sitemap tr:nth-child(odd) {
  background: #FFF; }

.twitter-feed {
  width: 66%;
  margin: 1em auto 2em;
  padding-top: 1em; }
  .twitter-feed iframe {
    height: 35em !important; }

.margin-below {
  margin-bottom: 1em; }

@font-face {
  font-family: 'Karbon-semibold';
  src: url("fonts/karbon/karbon-semibold-webfont.ttf");
  /* IE */
  src: local("Karbon"), url("fonts/karbon/karbon-semibold-webfont.ttf"); }

@font-face {
  font-family: 'Karbon-bold';
  src: url("fonts/karbon/karbon-bold-webfont.ttf");
  /* IE */
  src: local("Karbon"), url("fonts/karbon/karbon-bold-webfont.ttf") format("truetype"); }

@font-face {
  font-family: 'Karbon-light';
  src: url("fonts/karbon/karbon-light-webfont.ttf");
  /* IE */
  src: local("Karbon"), url("fonts/karbon/karbon-light-webfont.ttf"); }

@font-face {
  font-family: 'Karbon-medium';
  src: url("fonts/karbon/karbon-medium-webfont.ttf");
  /* IE */
  src: local("Karbon"), url("fonts/karbon/karbon-medium-webfont.ttf"); }

@font-face {
  font-family: 'Karbon';
  src: url("fonts/karbon/karbon-regular-webfont.ttf");
  /* IE */
  src: local("Karbon"), url("fonts/karbon/karbon-regular-webfont.ttf") format("truetype"); }

@font-face {
  font-family: 'Karbon-regular-italic';
  src: url("fonts/karbon/karbon-regularitalic-webfont.ttf");
  /* IE */
  src: local("Karbon"), url("fonts/karbon/karbon-light-webfont.ttf"); }

.snippet-container.eau-webcasts, .snippet-container.horia-webcasts, .snippet-container.bespoke.webcast.registration {
  text-align: center; }
  .snippet-container.eau-webcasts .card, .snippet-container.horia-webcasts .card, .snippet-container.bespoke.webcast.registration .card {
    color: white;
    padding: 1em;
    border-radius: 2rem;
    border: none; }
  .snippet-container.eau-webcasts .card.series-title, .snippet-container.horia-webcasts .card.series-title, .snippet-container.bespoke.webcast.registration .card.series-title {
    background: #004d99;
    background: linear-gradient(87deg, #004d99 0%, #72b52f 100%);
    font-size: 2.7em;
    line-height: 1.1em;
    font-weight: bold;
    font-family: Karbon-bold, sans-serif; }
  .snippet-container.eau-webcasts .card.webcast, .snippet-container.horia-webcasts .card.webcast, .snippet-container.bespoke.webcast.registration .card.webcast {
    padding: 0.5em;
    border: solid 2px white; }
    .snippet-container.eau-webcasts .card.webcast .card-title, .snippet-container.horia-webcasts .card.webcast .card-title, .snippet-container.bespoke.webcast.registration .card.webcast .card-title {
      font-family: Karbon-bold, sans-serif;
      font-size: 1.7em;
      min-height: 9em;
      line-height: 1.2em;
      padding-top: 0.5em; }
      @media (max-width: 767px) {
        .snippet-container.eau-webcasts .card.webcast .card-title, .snippet-container.horia-webcasts .card.webcast .card-title, .snippet-container.bespoke.webcast.registration .card.webcast .card-title {
          min-height: 4.5em; } }
    .snippet-container.eau-webcasts .card.webcast .webcast-date, .snippet-container.horia-webcasts .card.webcast .webcast-date, .snippet-container.bespoke.webcast.registration .card.webcast .webcast-date {
      font-family: Karbon-semibold, sans-serif;
      font-size: 1.4em;
      margin-bottom: 0.2em; }
      @media (max-width: 767px) {
        .snippet-container.eau-webcasts .card.webcast .webcast-date, .snippet-container.horia-webcasts .card.webcast .webcast-date, .snippet-container.bespoke.webcast.registration .card.webcast .webcast-date {
          margin-bottom: 1em; } }
    .snippet-container.eau-webcasts .card.webcast .webcast-time, .snippet-container.horia-webcasts .card.webcast .webcast-time, .snippet-container.bespoke.webcast.registration .card.webcast .webcast-time {
      font-family: Karbon-light, sans-serif;
      font-size: 1.3em;
      margin-bottom: 0.4em; }
      @media (max-width: 767px) {
        .snippet-container.eau-webcasts .card.webcast .webcast-time, .snippet-container.horia-webcasts .card.webcast .webcast-time, .snippet-container.bespoke.webcast.registration .card.webcast .webcast-time {
          margin-bottom: 1em; } }
    .snippet-container.eau-webcasts .card.webcast .webcast-register, .snippet-container.horia-webcasts .card.webcast .webcast-register, .snippet-container.bespoke.webcast.registration .card.webcast .webcast-register {
      font-family: Karbon, sans-serif;
      font-size: 1.7em;
      padding: 0.5em;
      background-color: white;
      color: black;
      line-height: 1em;
      width: 70%; }
    .snippet-container.eau-webcasts .card.webcast .webcast-body, .snippet-container.horia-webcasts .card.webcast .webcast-body, .snippet-container.bespoke.webcast.registration .card.webcast .webcast-body {
      margin-bottom: 1em; }
    .snippet-container.eau-webcasts .card.webcast:hover, .snippet-container.horia-webcasts .card.webcast:hover, .snippet-container.bespoke.webcast.registration .card.webcast:hover {
      border-color: grey; }
      .snippet-container.eau-webcasts .card.webcast:hover .webcast-register, .snippet-container.horia-webcasts .card.webcast:hover .webcast-register, .snippet-container.bespoke.webcast.registration .card.webcast:hover .webcast-register {
        font-family: Karbon-semibold, sans-serif; }
  .snippet-container.eau-webcasts .card.webcast-1, .snippet-container.horia-webcasts .card.webcast-1, .snippet-container.bespoke.webcast.registration .card.webcast-1 {
    background: #008c6a;
    background: linear-gradient(87deg, #008c6a 0%, #6aac4c 100%); }
  .snippet-container.eau-webcasts .card.webcast-2, .snippet-container.horia-webcasts .card.webcast-2, .snippet-container.bespoke.webcast.registration .card.webcast-2 {
    background: #004e99;
    background: linear-gradient(87deg, #004e99 0%, #0687c7 100%); }
  .snippet-container.eau-webcasts .card.webcast-3, .snippet-container.horia-webcasts .card.webcast-3, .snippet-container.bespoke.webcast.registration .card.webcast-3 {
    background: #6cad4c;
    background: linear-gradient(87deg, #6cad4c 0%, #008c6a 100%); }

.snippet-container.horia-webcasts .card.webcast-1, .snippet-container.horia-webcasts .card.webcast-2 {
  background: #004e99;
  background: linear-gradient(87deg, #004e99 0%, #0687c7 100%); }

.snippet-container.horia-webcasts .card.webcast .card-title {
  min-height: 5em; }

.snippet-container.bespoke.webcast.registration.ct-mh-5 .card.webcast .card-title {
  min-height: 5em; }

.snippet-container.bespoke.webcast.registration.bg-blue .card.webcast {
  background: #004e99;
  background: linear-gradient(87deg, #004e99 0%, #0687c7 100%); }

.snippet-container.bespoke.webcast.registration.ct-mh-6 .card.webcast .card-title {
  min-height: 6em; }

.snippet-container.bespoke.webcast.registration.cp-1 .card.webcast {
  padding: 1em; }

p:empty {
  display: none; }

body {
  font-family: "Lato", "Arial", sans-serif;
  line-height: 1.188em;
  font-size: 1em;
  color: #2d2d2d;
  margin: 0;
  height: 100%; }

h2, .h2 {
  font-size: 1.500em;
  line-height: 1.2em; }

h3, .h3 {
  font-size: 1em;
  line-height: 1.213em; }

.btn-right {
  text-align: right; }

.flash-message {
  width: 100%;
  display: block;
  margin: 1em 0 2em; }

.alert {
  padding: 10px; }

.alert-error {
  background-color: #eeabab;
  color: #9a1e1e; }

.alert-warning {
  background-color: #e7b396;
  color: #000; }

.alert-success {
  background-color: #d6ecd8;
  color: #000; }

.alert-full {
  margin-top: 20px;
  width: 50%;
  margin-right: auto;
  margin-left: auto; }

.btn-row a {
  margin-right: 10px; }

.btn-row a:last-child {
  margin-right: 0; }

.container-conference h1, .container-conference .h1 {
  color: #2d2d2d; }

.container-conference .container-row {
  text-align: center; }

.conference-footer-title {
  padding-bottom: 20px; }

.container-site-links div.col {
  border-radius: 6px;
  color: #0056b4;
  text-align: center;
  padding: 20px;
  margin-top: 10px; }

.container-conference div.col {
  border-right: 1px solid #cccccc;
  padding: 20px;
  text-align: center; }

.container-conference div.col:last-of-type {
  border-right: none; }

.bg-white {
  background-color: #FFF; }

.bg-grey {
  background-color: #ccc; }

.bg-light-grey {
  background-color: #e7e7e7; }

.bg-light-green {
  background-color: #cbd921; }

nav.menu-horizontal a.icon-flag {
  padding: 0; }

nav#menu-top.menu-horizontal a {
  color: #e9ecf4; }

nav#menu-top.menu-horizontal a.active {
  color: #cbd921;
  background-color: inherit; }

.icon-flag {
  background-image: url("/images/layout/country_flag_sprite.png");
  background-size: 250px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  border: 1px solid #d8e523;
  margin: 2px 5px 0 5px;
  padding: 1px; }

.icon-flag:last-of-type {
  margin-right: 0; }

.flag-eng {
  background-position: -10px -8px; }

.flag-es {
  background-position: 75px -8px; }

.icon-flag:hover {
  border: 1px solid #FFF; }

/* Navbar container */
/* The dropdown container */
/* Dropdown button */
.dropdown .dropbtn {
  border: none;
  outline: none;
  background-color: inherit;
  margin: 0;
  padding: 8px 5px 5px 5px; }

.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: #FFF; }

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFF;
  width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100; }

/* Links inside the dropdown */
nav#menu-top.menu-horizontal .dropdown-content a {
  width: 150px;
  float: left;
  color: #2d2d2d;
  text-decoration: none;
  display: block;
  text-align: left;
  z-index: 100;
  padding: 10px;
  font-size: 0.875em;
  line-height: 1.063em; }

.dropdown-items {
  width: 100%; }

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd; }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block; }

.blurb {
  color: #0056b4;
  padding-left: 1.5em;
  padding-bottom: 1em;
  padding-right: 0.5em; }

.blurb > p {
  margin-bottom: 0.4em; }

.video-carousel-main {
  width: auto; }

.foot-left {
  width: 100%; }

#panel-search button {
  padding-top: 30px; }

.panel {
  height: 100%; }

.container-flex {
  display: flex; }

@media (max-width: 991.98px) {
  .visually-hidden-lg-down {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important; } }

#head-panel {
  width: 100%;
  font-size: 0.875em;
  line-height: 1.0625em;
  color: white;
  z-index: 100; }

#logo img {
  aspect-ratio: 827/138;
  max-height: 42px; }

.tagline {
  margin: 0;
  border-left: solid 0.3em #cbd921;
  padding-left: 0.4em;
  margin-bottom: 0.3em; }

.disclaimer {
  font-size: 0.8em; }

@media (max-width: 767.98px) {
  .disclaimer div {
    font-size: 0.775em;
    justify-content: flex-start !important;
    margin-top: 0.8em; } }

#head-panel .language nav#menu-top a:nth-child(1) {
  margin-right: 0.4em; }

#head-panel .language nav#menu-top a:nth-child(2) {
  margin-left: 0.4em; }

#head-panel .language nav#menu-top a:active {
  color: #d8e523; }

#menu-user div.auth-options .user {
  font-size: 0.8em; }
  #menu-user div.auth-options .user i.icon-user {
    margin-left: 0.5em; }
  #menu-user div.auth-options .user i.icon-user::after {
    display: inline-block;
    height: 0.6rem;
    width: 0.6rem;
    background-size: 0.6rem;
    filter: invert(19%) sepia(94%) saturate(2212%) hue-rotate(199deg) brightness(97%) contrast(101%); }

#menu-user div.auth-options .login,
#menu-user div.auth-options .logout {
  color: #e9ecf4;
  background-color: inherit;
  font-size: 1em;
  font-weight: bold;
  padding: 0.625em 0.1em;
  border-bottom-color: #0056b4;
  border-bottom-width: 2px;
  border-bottom-style: solid; }

#menu-user div.auth-options .login:hover,
#menu-user div.auth-options .logout:hover {
  border-bottom: solid 2px #c6d420; }

#menu-user div.auth-options .disabled {
  font-weight: normal;
  opacity: 0; }

#menu-user div.auth-options .disabled:hover {
  border: none; }

.navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1); }
  .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 30 30' %3E%3Cpath stroke= '#0056b4' stroke-linecap= 'round' stroke-miterlimit= '10' stroke-width= '2' d= 'M4 7h22M4 15h22M4 23h22' /%3E%3C/svg%3E"); }

#donate_1 input[type='submit'] {
  height: 44px; }

.main.menu a {
  color: #fff; }

.main.menu .main-menu-subtitle {
  font-size: 1.5em;
  line-height: 1.5em;
  color: #fff;
  font-weight: bold;
  font-family: Verdana, Arial, sans-serif; }

.main.menu .ui-dialog-titlebar.ui-corner-all {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem; }

.sidebar-toggler {
  position: absolute;
  right: 0;
  top: 0.5rem;
  border: 1px solid transparent;
  border-color: rgba(0, 0, 0, 0.1);
  color: #0056b4;
  filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%); }

.col-8 .page-title, .col-lg-8 .page-title {
  display: none; }

.static-page-container .page-title {
  display: block; }

@media (max-width: 991.98px) {
  .blurb {
    padding-left: 0.5em; } }

.page-title.navbar-brand {
  display: block;
  margin-right: 3.5rem;
  white-space: normal; }

@media (min-width: 992px) {
  .col-8 .page-title, .col-lg-8 .page-title {
    display: block; } }

.content-specific-nav {
  display: flex;
  justify-content: center;
  color: #0056b4;
  padding: 0.5em; }
  .content-specific-nav div {
    border-right: solid 2px grey;
    padding: 0.3em 1em;
    text-align: center;
    font-size: 0.8em; }
    .content-specific-nav div a {
      color: #0056b4;
      text-decoration: none; }
    .content-specific-nav div a.active {
      font-weight: bold;
      border-bottom: solid 3px #d6e626;
      background-color: initial; }
    .content-specific-nav div a:hover {
      background-color: initial;
      border-bottom: solid 3px #d6e626; }
    .content-specific-nav div .count {
      color: #646466; }
  .content-specific-nav div:last-child {
    border-right: none; }

@media (min-width: 992px) {
  .content-specific-nav div {
    flex-basis: 18%; } }

@media (max-width: 991.98px) {
  .content-specific-nav div {
    display: flex;
    align-items: center; }
  .general-browse-results .content-specific-nav {
    margin-bottom: 0.5em;
    flex-wrap: wrap; }
    .general-browse-results .content-specific-nav div {
      border-right: none; } }

.content-specific-nav.margin-below {
  margin-bottom: 2em; }

.navbar-main-menu .navbar-brand {
  color: #0056b4; }

.navbar-main-menu a.active {
  font-weight: 700; }

.navbar-main-menu a:hover, .navbar-main-menu a.active {
  border-bottom: 2px solid #c6d420; }

.navbar-main-menu .search-generic-inline form .field-group:has(button .search-icon) {
  display: flex;
  vertical-align: middle; }

.navbar-main-menu .navbar-nav .nav-item .nav-link {
  margin: 0 0.2rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem; }

@media (min-width: 992px) {
  .navbar-main-menu .navbar-nav .nav-item {
    padding: 0 0.2rem;
    border-right: 1px solid #ccc; }
    .navbar-main-menu .navbar-nav .nav-item a {
      color: #0056b4; }
  .navbar-main-menu .navbar-nav .nav-item:last-of-type {
    border: none; } }

.icon-arrow-right::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0 0 0.4rem;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e9ecf4'%3e%3cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem; }

.icon-arrow-left::before {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0.4rem 0 0;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e9ecf4'%3e%3cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem; }

.search-generic-wrapper .categories {
  display: inline-block; }

.search-generic-wrapper .switch {
  display: inline-block;
  padding: 0 0.75rem;
  text-align: center;
  background-color: #cbd921;
  color: #0056b4;
  font-weight: bold;
  line-height: 2em;
  float: right;
  border-radius: 0.4rem; }

.search-generic-wrapper .switch:hover {
  background-color: #b3bd35;
  cursor: pointer; }

.search-generic-wrapper .search-generic-click {
  font-size: 0.7em;
  align-content: stretch;
  line-height: 2.3em; }
  .search-generic-wrapper .search-generic-click div {
    flex-grow: 1;
    flex-basis: 0;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    text-align: center;
    line-height: 3em; }
  .search-generic-wrapper .search-generic-click div:first-child {
    border-left: 2px solid #ccc; }
  .search-generic-wrapper .search-generic-click div:last-child {
    border-right: 2px solid #ccc; }
  .search-generic-wrapper .search-generic-click a {
    color: #646466; }
  .search-generic-wrapper .search-generic-click a.active, .search-generic-wrapper .search-generic-click a:hover {
    border-bottom: solid 2px #c6d420; }

.search-generic-wrapper .hidden {
  display: none; }

#generic-search-plus {
  display: none; }

#generic-search-plus.on {
  display: initial; }

#generic-search-minus {
  display: none; }

#generic-search-minus.on {
  display: initial; }

.search-generic-click-2 {
  font-size: 0.75em;
  align-content: stretch;
  line-height: 2.3em; }
  .search-generic-click-2 div {
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 1em;
    padding: 0.5em;
    margin: 0.6em 0;
    min-width: 8em; }
  .search-generic-click-2 a {
    color: #646466; }

#page-foot .container-site-links .bg-grey .btn-blue, #page-foot .container-site-links .bg-light-green .btn-blue {
  width: 10em; }

#page-foot .container-site-links form, #page-foot .container-site-links form input[type=submit].sg-btn-cta {
  width: auto;
  margin: auto; }

#page-foot .container-site-links div.col {
  padding: 20px; }

@media (min-width: 1200px) {
  #page-foot .container-site-links div.col {
    margin-right: 20px;
    padding: 2em 1em; }
  #page-foot .container-foot ul.foot-links li:last-of-type:after {
    content: ""; }
  #page-foot .container-foot ul.foot-links li:after {
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    content: "|"; } }

#page-foot .container-foot ul.foot-links a {
  color: #fff; }

.content-specific-search form .field-group {
  display: block;
  text-align: center;
  width: 100%; }
  .content-specific-search form .field-group select {
    max-width: none;
    width: 100%; }

.content-specific-search form .collapse .field-group:last-child {
  background-color: inherit; }

.content-specific-search form .search-icon {
  display: flex;
  border-radius: 50rem; }

@media (min-width: 1200px) {
  .search-news form .field-group select {
    max-width: 10em; } }

@media (min-width: 992px) {
  .content-specific-search form .search-icon {
    border-radius: unset; }
  .collapsing {
    height: inherit;
    overflow: inherit;
    transition: none; }
  .content-specific-search .modal {
    display: block !important;
    position: inherit;
    opacity: 1; }
    .content-specific-search .modal .modal-dialog {
      max-width: none;
      transform: none;
      margin: 0; }
    .content-specific-search .modal .modal-content {
      border: none; }
      .content-specific-search .modal .modal-content .modal-header {
        display: none; }
        .content-specific-search .modal .modal-content .modal-header .icon-times-thin::after {
          filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%); }
      .content-specific-search .modal .modal-content .modal-body {
        margin: 0; } }

@media (max-width: 767.98px) {
  .news-item .article > img {
    margin: 0 0 0.75rem 0;
    width: 100%;
    text-align: center; } }

@media (max-width: 991.98px) {
  .content-specific-search form {
    flex-direction: column;
    padding: 0.5rem; }
    .content-specific-search form .collapse:not(.show) {
      display: none !important; }
  .search-news form .field-group input {
    max-width: none;
    width: 100%; }
  .content-specific-search form .field-group input[type="text"] {
    width: 100%; } }

.video-carousel .item-thumb img {
  height: auto; }

@media (max-width: 991.98px) {
  #page-content .widget-grid.home .results-grid.conferences {
    margin-bottom: 0; }
    #page-content .widget-grid.home .results-grid.conferences .item-row {
      margin-top: 0; }
      #page-content .widget-grid.home .results-grid.conferences .item-row .item-thumb a {
        height: 5em; } }

.banner {
  padding-bottom: 1rem; }

@media (max-width: 767.98px) {
  #page-content .results-grid.conferences .item-row {
    width: 100%;
    margin: 0 2em 0 0; } }

#page-content .results-grid.conferences .item-row .item-thumb {
  max-width: 150px;
  width: 100%; }

.video-browse-body .video-carousel {
  aspect-ratio: 357 / 200;
  height: auto;
  width: 100%;
  margin: 0 auto; }
  .video-browse-body .video-carousel .item-thumb img {
    height: auto; }

.video-display-body .video-detail-strip {
  display: flex;
  padding: 0.25em 0.5em 0.15em;
  justify-content: space-between; }
  .video-display-body .video-detail-strip div {
    margin: 0 0.5rem; }
  .video-display-body .video-detail-strip div:first-of-type {
    margin-left: 0; }
  .video-display-body .video-detail-strip div:last-of-type {
    margin-right: 0; }
  .video-display-body .video-detail-strip .video-detail-published {
    margin-right: 0.5rem; }

#page-content .video-btn-strip .btn {
  display: inline-block;
  margin-bottom: 0.2rem; }

.video-browse-body .video-carousel .item-thumb img {
  height: auto; }

@media (min-width: 1200px) {
  .video-browse-body .video-carousel {
    height: auto;
    width: 714px; }
  .video-display-body .video-detail-strip {
    padding: 0.25em 1em 0.15em; } }

.video-browse-body .results-grid .grid-item, .video-display-body .results-grid .grid-item, .conference-videos-body .results-grid .grid-item, .journal-body .results-grid .grid-item, .general-browse-results .results-grid .grid-item {
  display: block;
  width: auto;
  background-color: #f0f0f0;
  padding: 1.5em 1em 2em;
  margin-bottom: 1.5em;
  flex-direction: column; }
  .video-browse-body .results-grid .grid-item .grid-item-title, .video-display-body .results-grid .grid-item .grid-item-title, .conference-videos-body .results-grid .grid-item .grid-item-title, .journal-body .results-grid .grid-item .grid-item-title, .general-browse-results .results-grid .grid-item .grid-item-title {
    height: 5em; }

.general-browse-results .results-grid.elearning-courses-grid .grid-item {
  background-color: unset; }

.elearning-registration-prompt .registration-cta-bar {
  line-height: 2.5em; }

.elearning-courses-grid .elearning-meta-row, .course-detail .elearning-meta-row {
  display: flex;
  flex-wrap: wrap !important;
  flex-flow: row;
  color: #646466; }
  .elearning-courses-grid .elearning-meta-row .meta-detail, .course-detail .elearning-meta-row .meta-detail {
    padding-right: 1em;
    margin-bottom: 1em;
    font-size: 0.8em; }
    .elearning-courses-grid .elearning-meta-row .meta-detail .content, .course-detail .elearning-meta-row .meta-detail .content {
      padding-right: 1em;
      border-right: solid 1px #ccc; }
  .elearning-courses-grid .elearning-meta-row .meta-detail:last-child, .course-detail .elearning-meta-row .meta-detail:last-child {
    color: #2d2d2d;
    flex-grow: 1; }
    .elearning-courses-grid .elearning-meta-row .meta-detail:last-child .content, .course-detail .elearning-meta-row .meta-detail:last-child .content {
      padding-right: 0;
      width: 9em; }
    .elearning-courses-grid .elearning-meta-row .meta-detail:last-child .content:not(.e-learning-percentage-complete), .course-detail .elearning-meta-row .meta-detail:last-child .content:not(.e-learning-percentage-complete) {
      border-right: none; }

.elearning-courses-grid .button-bar, .course-detail .button-bar {
  display: flex;
  flex-direction: row; }

.elearning-courses-grid .button-bar-centered, .course-detail .button-bar-centered {
  justify-content: center; }

.elearning-courses-grid .button-bar .btn, .course-detail .button-bar .btn {
  margin-right: 1em; }

.course-browse .grid-item, .module-browse .grid-item, .elearning-courses-grid .grid-item {
  width: auto;
  font-size: 1em; }

@media (max-width: 767.98px) {
  .e-learning-course-container article, .e-learning-course-container .course-detail {
    width: auto; }
  .e-learning-course-container {
    margin-bottom: 0; } }

.button-bar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }
  .button-bar a, .button-bar button {
    margin-right: 1em !important;
    margin-top: 1em !important; }

@media (max-width: 767.98px) {
  .static-page-container.about-us, .static-page-container.support-us {
    margin-right: auto !important; }
    .static-page-container.about-us .intro img, .static-page-container.support-us .intro img {
      float: none;
      margin-right: auto;
      margin-left: auto;
      padding-bottom: 1em; }
    .static-page-container.about-us .affiliations .panel, .static-page-container.support-us .affiliations .panel {
      margin-bottom: 1em; }
    .static-page-container.about-us .affiliations .row2 .panel1, .static-page-container.support-us .affiliations .row2 .panel1 {
      max-width: 100%; }
    .static-page-container.about-us .affiliations .row2 .panel2, .static-page-container.support-us .affiliations .row2 .panel2 {
      max-width: 100%; } }

.static-page-container.about-us, .static-page-container.support-us {
  margin-right: 1em; }

#page-registration label, .like-registration label {
  display: inline; }

#page-registration .form-group, .like-registration .form-group {
  margin: 1em 0 0 0; }

#page-registration .accordion-panel, .like-registration .accordion-panel {
  padding: 1em; }
  #page-registration .accordion-panel .form-control label, .like-registration .accordion-panel .form-control label {
    display: block; }

#page-registration .accordion-wrapper.slimline .accordion-panel .form-control input, .like-registration .accordion-wrapper.slimline .accordion-panel .form-control input {
  margin-right: 2%; }

#page-registration .accordion-wrapper.slimline .accordion-panel .form-control label, .like-registration .accordion-wrapper.slimline .accordion-panel .form-control label {
  margin-left: 1%;
  margin-right: 4%;
  width: 86%; }

#page-registration #registration_email_preference_content_type label, #page-registration #communication_preferences_all_email_updates_preference_content_type label, .like-registration #registration_email_preference_content_type label, .like-registration #communication_preferences_all_email_updates_preference_content_type label {
  margin-left: 1em; }

#page-registration #registration_email_preference_content_type label::after, #page-registration #communication_preferences_all_email_updates_preference_content_type label::after, .like-registration #registration_email_preference_content_type label::after, .like-registration #communication_preferences_all_email_updates_preference_content_type label::after {
  content: "\a";
  white-space: pre; }

@media (min-width: 768px) {
  #page-registration .form-group, .like-registration .form-group {
    margin: 1em 0 0 3em; }
  #page-registration .accordion-wrapper.slimline .accordion-panel .form-control label, .like-registration .accordion-wrapper.slimline .accordion-panel .form-control label {
    width: 40%; }
  #page-registration #registration_email_preference_content_type label::after, #page-registration #communication_preferences_all_email_updates_preference_content_type label::after, .like-registration #registration_email_preference_content_type label::after, .like-registration #communication_preferences_all_email_updates_preference_content_type label::after {
    content: "";
    white-space: normal; }
  #page-registration form .regn-form-profile label, .like-registration form .regn-form-profile label {
    display: inline-block;
    width: 13em; } }

@media (min-width: 992px) {
  #page-registration .accordion-panel, .like-registration .accordion-panel {
    padding: 0 16em; } }

#login-content {
  margin-top: 2em;
  margin-bottom: 3em; }
  #login-content #page-login {
    width: 80%; }
  #login-content label {
    display: inline;
    width: 13em; }

@media (min-width: 768px) {
  #login-content {
    margin-top: 150px;
    margin-bottom: 150px; }
    #login-content #page-login {
      width: 50%; } }

#page-content #page-user button[type=submit] {
  margin-top: 1em;
  margin-bottom: 1em; }

#page-content .user form[name="profile"] button {
  margin-top: 1em;
  margin-bottom: 1em; }

#page-content .container-flex.user .widget-list .item-row.two-col {
  width: auto; }
  #page-content .container-flex.user .widget-list .item-row.two-col img {
    max-height: 150px;
    max-width: 150px;
    width: 100%; }
  #page-content .container-flex.user .widget-list .item-row.two-col img:hover {
    opacity: 0.8;
    filter: alpha(opacity=80);
    /* For IE8 and earlier */ }
  #page-content .container-flex.user .widget-list .item-row.two-col a:hover {
    opacity: 0.8;
    filter: alpha(opacity=80);
    /* For IE8 and earlier */ }
  #page-content .container-flex.user .widget-list .item-row.two-col .date {
    margin-top: 10px;
    display: block; }

.results-list.articles .item-row .item-thumb img, .journal-special-issues-list img {
  height: auto; }

.journal-body .results-grid .grid-item .grid-item-title {
  height: auto; }

.img-responsive-aspect {
  width: 100% !important;
  height: auto !important; }

.journal-special-issue .intro .thumb, .journal-special-issue .full-article.journal-editorial-item .article img:nth-of-type(1) {
  margin: 0 0 0.75rem 0;
  width: 100% !important;
  text-align: center;
  max-width: 100% !important; }

.interview-wrapper .kaltura_player iframe,
.journal-special-issue .video-special-issue-abstract .kaltura_player iframe {
  min-height: auto; }

.journal-article-item .article-metrics .details .summary, .journal-editorial-item .article-metrics .details .summary,
.journal-article-item .article-metrics .details .metric, .journal-editorial-item .article-metrics .details .metric,
.journal-body #metrics-full .altmetric.donut {
  margin-top: 0.75rem; }

.journal-body #metrics-full {
  display: flex;
  margin-bottom: 0.75rem; }
  .journal-body #metrics-full .altmetric.donut {
    margin-left: 1.3rem; }
  .journal-body #metrics-full .details .summary {
    margin-right: 1em; }
  .journal-body #metrics-full .details .metric {
    margin-left: 0; }

.journal-article-item.abstract .item-details .article-doi, .journal-article-item.abstract .item-details .article-links, .journal-article-item.abstract .item-details .article-published-date, .journal-article-item.abstract .journal-editorial-item .article-doi, .journal-article-item.abstract .journal-editorial-item .article-links, .journal-article-item.abstract .journal-editorial-item .article-published-date, .journal-article-item.full .item-details .article-doi, .journal-article-item.full .item-details .article-links, .journal-article-item.full .item-details .article-published-date, .journal-article-item.full .journal-editorial-item .article-doi, .journal-article-item.full .journal-editorial-item .article-links, .journal-article-item.full .journal-editorial-item .article-published-date, .journal-editorial-item .item-details .article-doi, .journal-editorial-item .item-details .article-links, .journal-editorial-item .item-details .article-published-date, .journal-editorial-item .journal-editorial-item .article-doi, .journal-editorial-item .journal-editorial-item .article-links, .journal-editorial-item .journal-editorial-item .article-published-date {
  word-wrap: break-word;
  word-break: break-word;
  white-space: nowrap; }

@media (min-width: 768px) {
  .journal-special-issue .intro .thumb, .journal-special-issue .full-article.journal-editorial-item .article img:nth-of-type(1) {
    float: right;
    margin: 0 0 4em 4em;
    width: 40% !important;
    max-width: 300px !important; }
  .interview-wrapper .kaltura_player iframe,
  .journal-special-issue .video-special-issue-abstract .kaltura_player iframe {
    min-height: 22em;
    width: 100%; } }

@media (max-width: 991.98px) {
  h1.page-title .journal-title-branded, .page-title.h1 .journal-title-branded {
    display: none; } }

.modal-backdrop {
  background-color: #fff; }

.modal-dialog .modal-header {
  color: #0056b4;
  font-size: 1.25em;
  font-weight: 700; }

.modal-dialog .close-button {
  text-align: right;
  color: #0056b4;
  margin-bottom: 0.5em; }
  .modal-dialog .close-button .icon-times-thin::after {
    filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%); }

.modal-dialog .modal-header, .modal-dialog .modal-body, .modal-dialog .modal-footer {
  margin: 1rem;
  padding: 0; }

.modal-dialog .login-modal .registration-cta-bar {
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between; }
  .modal-dialog .login-modal .registration-cta-bar a.login {
    margin-left: 1em; }
    .modal-dialog .login-modal .registration-cta-bar a.login span.login {
      color: #0056b4;
      font-weight: 700; }

.modal-dialog .video-rating-question-container, .modal-dialog .article-rating-question-container {
  margin-bottom: 1.5em; }
  .modal-dialog .video-rating-question-container .rating-container .rating button, .modal-dialog .article-rating-question-container .rating-container .rating button {
    border: none;
    color: #ccc;
    font-size: 1.5em;
    background-color: pink; }
  .modal-dialog .video-rating-question-container .question, .modal-dialog .article-rating-question-container .question {
    width: 100%; }

.modal-dialog .modal-footer {
  border: none; }

#videoRatingModal dialog, #journalRatingModal dialog, #journalCiteModal dialog {
  padding: 0;
  left: 0;
  box-shadow: none; }
  #videoRatingModal dialog .modal-body .video-rating-question-container div.rating-container, #videoRatingModal dialog .modal-body .article-rating-question-container div.rating-container, #journalRatingModal dialog .modal-body .video-rating-question-container div.rating-container, #journalRatingModal dialog .modal-body .article-rating-question-container div.rating-container, #journalCiteModal dialog .modal-body .video-rating-question-container div.rating-container, #journalCiteModal dialog .modal-body .article-rating-question-container div.rating-container {
    margin: 0; }
  #videoRatingModal dialog h4.question, #videoRatingModal dialog .question.h4, #journalRatingModal dialog h4.question, #journalRatingModal dialog .question.h4, #journalCiteModal dialog h4.question, #journalCiteModal dialog .question.h4 {
    width: 100%;
    font: inherit; }
  #videoRatingModal dialog .modal-footer, #journalRatingModal dialog .modal-footer, #journalCiteModal dialog .modal-footer {
    margin-top: 0; }

.ui-dialog.main.menu .ui-dialog-titlebar-close {
  margin-right: 0.5rem;
  margin-top: -15px;
  width: 1em;
  height: 1em;
  z-index: 2000; }

.ui-dialog.main.menu .ui-dialog-titlebar {
  padding-top: 0; }

.ui-dialog.main.menu .nav-item a.active {
  color: #cbd921;
  font-weight: 700; }

.ui-dialog.main.menu .search-generic-inline {
  width: 100%; }
  .ui-dialog.main.menu .search-generic-inline .field-group:nth-child(3) {
    width: 100%; }
    .ui-dialog.main.menu .search-generic-inline .field-group:nth-child(3) input[placeholder='Search ecancer'], .ui-dialog.main.menu .search-generic-inline .field-group:nth-child(3) input[placeholder='Buscar ecancer'] {
      width: 100%; }
  .ui-dialog.main.menu .search-generic-inline form .field-group:has(button .search-icon) {
    display: flex;
    vertical-align: middle; }

@media (min-width: 992px) {
  .ui-dialog.main.menu .row .col-12 {
    text-align: center; }
    .ui-dialog.main.menu .row .col-12 .search-generic-inline {
      justify-content: center; }
  .ui-dialog.main.menu .row .col-12:last-child {
    border-left: 1px solid #fff; } }

.btn {
  line-height: normal; }

#right-col .sidebar {
  padding-bottom: 1rem; }

.content-journal-editorial-item .right .side-menu,
.content-journal-editorial-item .right .sidebar,
.content-news-item .right .side-menu,
.content-news-item .right .sidebar {
  padding-left: 0; }

@media (min-width: 992px) {
  .content-journal-editorial-item .right .side-menu,
  .content-journal-editorial-item .right .sidebar,
  .content-news-item .right .side-menu,
  .content-news-item .right .sidebar {
    padding-left: 0.5rem; } }

.pager {
  margin-bottom: 1rem; }

.back-to-top-link {
  visibility: hidden;
  opacity: 0;
  transition: .2s;
  position: fixed;
  bottom: 1.2rem;
  right: 1.5rem;
  z-index: 15; }

.back-to-top-link[data-visible=true] {
  opacity: 1;
  visibility: visible; }

.back-to-top-link svg {
  -webkit-filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2)); }

.offcanvas .offcanvas-body {
  padding: 0.5rem; }
  .offcanvas .offcanvas-body .nav-link {
    padding-left: 0.5rem; }

.offcanvas .offcanvas-header {
  color: #0056b4;
  font-size: 1.25em;
  font-weight: 700;
  padding-bottom: 0; }
  .offcanvas .offcanvas-header .icon-times-thin::after {
    filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%); }

.offcanvas .close-button {
  text-align: right;
  color: #0056b4; }
  .offcanvas .close-button .icon-times-thin::after {
    filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%); }

.toc-quick-link {
  visibility: hidden;
  opacity: 0;
  transition: .2s;
  position: fixed;
  bottom: 1.2rem;
  left: 1.5rem;
  z-index: 15;
  padding: 5px; }

.toc-quick-link[data-visible=true] {
  opacity: 1;
  visibility: visible; }

.toc-quick-link {
  -webkit-filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2)); }
