.user.dashboard.form {
    .form-group.row {
        margin-bottom: 1em;
        input, select {
            width: 100%;
        }
        select {
            height: 2.3em;
        }
        .form-input-container.radio {
            display: flex;
            align-items: center;
            input {
                width: auto;
            }
        }
        .form-input-container.radio.collection {
            input {
                margin-right: 0.5em;
            }
            label {
                width: 20%;
            }
        }
    }
    .form-group.row.yes-no {
        input {
            width: 3em;
        }
        .form-control {
            label {
                margin-right: 2em;
            }
        }
    }
}