#page-content {
    .content-item-conference {
        .addThis {
            margin-bottom: 1em;
        }
        .intro-image {
            width: 100%;
            height: 100%;
            float: none;
            margin-left: 0;
            margin-bottom: 1em;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            //border: solid 1px $lightgrey;
        }
        .conference.aims {
            line-height: 1.5em;
            margin-bottom: 1em;
        }
        .conference.website {
            line-height: 1.5em;
            margin-bottom: 2em;
        }
        .aims.hidden {
            display: none;
        }
    }

    .results-grid.conferences {
        margin-bottom: 2em;
        .item-row {
            margin-top: 4em;
            width: 50%;
            font-size: $font_size_reduced;
            .item-title {
                margin-right: 1em;
                .date {
                    font-style: italic;
                }
                .article-title {
                    font-weight: bold;
                }
                .icon-video-camera {
                    display: flex;
                }
                .icon-video-camera:after {
                    width:0.8rem;
                    height:0.8rem;
                    background-size:0.8rem;
                    margin: 0;
                    filter: invert(41%) sepia(4%) saturate(168%) hue-rotate(202deg) brightness(92%) contrast(88%);
                }
                .item-content-details {
                    span {
                        margin-left: 0.5em;
                    }
                }
            ;
            }
            .item-thumb {
                padding-right: 1em;
                border-right: solid 1px $lightgrey;
                a {
                    height: 7em;
                    display: block;
                    background-position: top;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }
    }

    .conference.details {
        margin-bottom: 2em;
        .description {
            h1 {
                margin-bottom: 0.5em;
            }
            .date,
            .location
            {
                color: $textgrey;
            }

            .date {
                margin-bottom: 0.1em;
            }
            .conference.website:hover {
                text-decoration: underline;
                text-decoration-color: $sg_green;
                .content-item-title {
                    color: $lightblue;
                }
            }
        }
    }

    .conference.website {
        a {
            color: $sg_blue;
        }
        a:hover {
            color: $lightblue;
            @include menu-hover-state-using-margin();
        }
    }

    .widget-grid.home {
        .results-grid.conferences {
            .item-row {
                margin-top: 2em;
                font-size: inherit;
                .item-title {
                    .date {
                        display: none;
                    }
                    .article-title {
                        font-weight: normal;
                    }
                }
                .item-thumb {
                    border-right: none;
                    a {
                        height: 7em;
                        display: block;
                        background-position: top;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }

    button {
        margin: 0 auto;
    }

    .pager.archive {
        a:nth-child(2) {
            display: none;
        }
    }

    .icon-video-camera-standard {
        @include iconVideoCamera();
    }
}
