button[type=submit].btn{
  border:none;
}
.btn{
  padding: 0.5em 0.7em;
}
.btn-green{
  color: $button_text;
  display: block;
  background-color: #C8D51E;
  a {
    color: $button_text;
  }
}
.btn-blue {
  color: $white;
  display:block;
  background-color: $sg_button_normal;
  a {
    color: $white;
  }
}
.btn-blue:hover {
    background-color: $sg_blue_button_hover;
    color: $sg_green;
}
.btn-disabled{
  background-color:#BBB;
  color: $body_text_color;
}
.btn-disabled:hover{
  background-color:#DDD;
  color: $body_text_color;
}
.btn-green:hover{
  background-color:#95A200;
  color:#FFF;
}
.btn-green:hover i{
  color:#FFF;
}
.btn-grey {
    color: $white;
    background-color: $grey;
}
.btn-grey-disabled:hover {
    color: $white;
    background-color: $grey;
    cursor: default;
}
.btn-reduced {
  font-size: $font_size_reduced;
}


/* style guide button */
.sg-btn {
    font-size: $sg_button_font-size;
    font-weight: $sg_button_font-weight;
    padding: $sg_button_padding;
    border-radius: $sg_button_border-radius;
    border: $sg_button_border;
}
.sg-btn-slim {
    font-size: $font_size_reduced;
    font-weight: $sg_button_font-weight;
    padding: .4em 1em;
    border-radius: .2em;
    border: $sg_button_border;
}


/*=============================== donate button start =====================================*/

#page-head .container-row.donate {

  margin-top: 0.5em;

  form {
    .sg-btn-cta {
      width: 90%;
    }
  }

  .col_2 {
    text-align: center;
  }

}

/*=============================== donate button end =======================================*/

.sg-btn-cta {
  background-color: $sg_button_selected;
  color: $sg_button_normal;
}
.sg-btn-cta:hover {
  cursor: hand;
  background-color: $sg_button_hover;
}
input.sg-btn {
  font-family: 'Lato', sans-serif;
}
