.posts-nav {
    border-bottom: solid 2px lightgrey;
    margin-bottom: 2em;
    .menu-item {
        display: inline-block;
        min-width: 5em;
        text-align: center;
        @include sg-btn-slim();
        @include sg-btn-blue();

        a {
            color: inherit;
        }
    }
    .menu-item.active {
        @include sg-btn-yellow();
    }
}