@import 'setup.scss';

@import 'helper/_functions.scss';

@import 'errors/http_codes.scss';
@import 'layout/_links.scss';
//@import 'layout/_grid.scss';
@import 'layout/_cards.scss';
@import 'layout/_percentage-complete.scss';
@import 'layout/header.scss';
@import 'layout/footer.scss';
//@import 'layout/menu.scss';
@import 'layout/form.scss';
@import 'layout/site-message.scss';
@import 'layout/icon.scss';
@import 'widget/dialog.scss';
@import 'widget/widget.scss';
@import 'widget/accordion.scss';
@import 'widget/video_carousel.scss';
@import 'widget/rating.scss';
@import 'widget/banner.scss';
@import 'widget/maintenance.scss';
@import 'widget/site-version.scss';
@import 'widget/previewer.scss';
@import 'widget/collaborate.scss';
@import 'widget/snippet.scss';
@import 'layout/_images.scss';
@import 'layout/_videos.scss';
@import 'layout/pager.scss';
@import 'layout/_btn.scss';
@import 'layout/_breadcrumb.scss';
@import 'page/news.scss';
@import 'page/video.scss';
@import 'page/user.scss';
@import 'page/elearning.scss';
@import 'page/journal.scss';
@import 'page/conference.scss';
@import 'page/static.scss';
@import 'page/advanced-search.scss';
@import 'page/browse.scss';
@import 'menus/sidebar.scss';
@import 'menus/explore.scss';
@import 'menus/posts.scss';
@import 'menus/main-menu-dialog';
@import 'forms/search.scss';
@import 'forms/user.scss';
@import 'forms/registration.scss';
@import '~dialog-polyfill/dialog-polyfill.css';
@import 'page/sitemap.scss';
@import 'iframe/twitter.scss';
@import 'layout/tweaks.scss';

// EPL-923 EAU Janssen webcasts. Can be removed after 2024-04-08.
@import 'plugins/_karbon';
@import 'bespoke/webcasts';

p:empty {
    display: none;
}

body{
    font-family: $font_family;
    line-height: 1.188em;
    font-size: 1em;
    color: $body_text_color;
    margin: 0;
    height: 100%;
}

h2{
    font-size:1.500em;
    line-height:1.2em;
}
h3{
    font-size:1em;
    line-height:1.213em;
}

.btn-right{
    text-align: right;
}
.flash-message{
    width:100%;
    display:block;
    margin: 1em 0 2em;
}
.alert{
    padding:10px;
}
.alert-error{
    background-color:$alertErrorBG;
    color:$alertErrorText;
}
.alert-warning{
    background-color:$alertWarningBG;
    color:$alertWarningText;
}
.alert-success{
    background-color:$alertSuccessBG;
    color:$alertSuccessText;
}
.alert-full {
    margin-top:20px;
    width:50%;
    margin-right:auto;
    margin-left:auto;
}

.btn-row a{
    margin-right:10px;
}
.btn-row a:last-child{
    margin-right:0;
}

.container-conference h1{
    color:$textblack;
}
.container-conference .container-row{
    text-align: center;
}
.conference-footer-title{
    padding-bottom:20px;
}
.container-site-links div.col{
    border-radius: 6px;
    color: $textblue;
    text-align: center;
    padding:20px;
    margin-top:10px;
}

.container-conference div.col{
    border-right: 1px solid $grey;
    padding:20px;
    text-align: center;
}
.container-conference div.col:last-of-type{
    border-right: none;
}

// colour
.bg-white{
    background-color: $white;
}
.bg-grey {
    background-color: $lightgrey;
}
.bg-light-grey {
    background-color: $lightergrey;
}
.bg-light-green {
    background-color: $sg_green;
}

nav.menu-horizontal a.icon-flag{
    padding:0;
}
nav#menu-top.menu-horizontal a {
    color: $sg_white;
}
nav#menu-top.menu-horizontal a.active {
    color: $sg_green;
    background-color: inherit;
}

.icon-flag{
    background-image: url("/images/layout/country_flag_sprite.png");
    background-size: 250px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: inline-block;
    font-size:0;
    border:1px solid $lightgreen;
    margin:2px 5px 0 5px;
    padding:1px;
}
.icon-flag:last-of-type{
    margin-right:0;
}
.flag-eng{
    background-position: -10px -8px;
}
.flag-es{
    background-position: 75px -8px;
}
.icon-flag:hover{
    border:1px solid $white;
}

/* Navbar container */
//.navbar-top {
//    overflow: hidden;
//}


/* The dropdown container */
//.dropdown {
//    float: left;
//    overflow: hidden;
//}

/* Dropdown button */
.dropdown .dropbtn {
    border: none;
    outline: none;
    background-color: inherit;
    margin: 0;
    padding: 8px 5px 5px 5px;
}

.navbar a:hover, .dropdown:hover .dropbtn {
    background-color: #FFF;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: $white;
    width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 100;
}

/* Links inside the dropdown */
nav#menu-top.menu-horizontal .dropdown-content a {
    width:150px;
    float: left;
    color: $body_text_color;
    text-decoration: none;
    display: block;
    text-align: left;
    z-index: 100;
    padding:10px;
    font-size: 0.875em;
    line-height: 1.063em;
}

.dropdown-items{
    width:100%;
}
/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.blurb {
    color: $sg_blue;
    padding-left: 1.5em;
    padding-bottom: 1em;
    padding-right: 0.5em;
}
.blurb > p {
    margin-bottom: 0.4em;
}

.video-carousel {
    //width: 420px;
}
.video-carousel-main {
    width:auto;
}

.foot-left{
    width:100%;
}

#panel-search button{
    padding-top:30px;
}
.panel{
    height: 100%;
}

.container-flex{
    display:flex;
}



// Bootstrap begins

@include media-breakpoint-down(lg) {
    .visually-hidden-lg-down {
        @include visually-hidden;
    }
}

// menu.scss
#head-panel {
    width:100%;
    font-size: $font-size_header;
    line-height: 1.0625em;
    color: white;
    z-index: 100;
}
#logo img {
    aspect-ratio: 827/138;
    max-height: 42px;
}
.tagline {
    @include leading-block();
}
.disclaimer {
    font-size: $font_size_reduced;
}
@include media-breakpoint-down(md) {
    .disclaimer div {
        font-size: $font-size_header - 0.1;
        justify-content: flex-start!important;
        margin-top: 0.8em;
    }
}
#head-panel {
    .language {
        nav#menu-top {
            a:nth-child(1) {
                margin-right: 0.4em;
            }

            a:nth-child(2) {
                margin-left: 0.4em;
            }

            a:active {
                color: $lightgreen;
            }
        }
    }
}
#menu-user {
    div.auth-options {

        .user {
            font-size: $font_size_reduced;
            i.icon-user {
                margin-left: 0.5em;
            }
            i.icon-user::after {
                display: inline-block;
                height: 0.6rem;
                width: 0.6rem;
                background-size: 0.6rem;
                filter: invert(19%) sepia(94%) saturate(2212%) hue-rotate(199deg) brightness(97%) contrast(101%);
            }
        }

        .login,
        .logout
        {
            color: $sg_white;
            background-color: inherit;
            font-size: $sg_button_font-size;
            font-weight: $sg_button_font-weight;
            padding: 0.625em 0.1em;
            border-bottom-color: $sg_gradient_blue_breakpoint;
            border-bottom-width: 2px;
            border-bottom-style: solid;
        }

        .login:hover,
        .logout:hover {
            @include menu-hover-state-using-margin();
        }

        .disabled {
            font-weight: normal;
            opacity: 0;
        }
        .disabled:hover {
            border: none;
        }
    }
}

.navbar-toggler {
    border-color: rgba(0,0,0,.1);

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 30 30' %3E%3Cpath stroke= '#{$sg_blue}' stroke-linecap= 'round' stroke-miterlimit= '10' stroke-width= '2' d= 'M4 7h22M4 15h22M4 23h22' /%3E%3C/svg%3E");
    }
}
#donate_1 input[type='submit'] {
    height: 44px;
}
.main.menu {
    a {
        color: #fff;
    }
    .main-menu-subtitle {
        font-size: 1.5em;
        line-height: 1.5em;
        color: #fff;
        font-weight: bold;
        font-family: Verdana, Arial, sans-serif;
    }
    .ui-dialog-titlebar.ui-corner-all {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
    }
}
.sidebar-toggler {
    position: absolute;
    right: 0;
    top: 0.5rem;
    border: 1px solid transparent;
    border-color: rgba(0,0,0,.1);
    color: $sg_blue;
    filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%);
}

// This hides a page title when in mobile responsive mode as the navbar-brand gets used.
// Unfortunately, for static pages, the page title is required -
.col-8 .page-title, .col-lg-8 .page-title {
    display: none;
}
// hence this.
.static-page-container .page-title {
    display: block;
}

@include media-breakpoint-down(lg) {
    .blurb {
        padding-left: 0.5em;
    }
}

.page-title.navbar-brand {
    display: block;
    margin-right: 3.5rem;
    white-space: normal;
}
@include media-breakpoint-up(lg) {
    .col-8 .page-title, .col-lg-8 .page-title {
        display: block;
    }
}
.content-specific-nav {
    display: flex;
    justify-content: center;
    color: $sg_blue;
    padding: 0.5em;

    div {
        border-right: solid 2px grey;
        padding: 0.3em 1em;
        text-align: center;
        font-size: $font_size_reduced;

        a {
            color: $sg_blue;
            text-decoration: none;
        }

        a.active {
            font-weight: bold;
            @include menu-hover-state-using-margin-high-contrast();
            background-color: initial;
        }

        a:hover {
            background-color: initial;
            @include menu-hover-state-using-margin-high-contrast();
        }

        .count {
            color: $textgrey;
        }
    }

    div:last-child {
        border-right: none;
    }
}
@include media-breakpoint-up(lg) {
    .content-specific-nav div {
        flex-basis: 18%;
    }
}
@include media-breakpoint-down(lg) {
    .content-specific-nav div {
        display: flex;
        align-items: center;
    }
    .general-browse-results .content-specific-nav {
        margin-bottom: 0.5em;
        flex-wrap: wrap;
        div {
            border-right: none;
        }
    }
}

.content-specific-nav.margin-below {
    margin-bottom: 2em;
}

// main menu
.navbar-main-menu {
    .navbar-brand {
        color: $sg_blue;
    }

    a.active {
        font-weight: 700;
    }

    a:hover, a.active {
        border-bottom: 2px solid #c6d420;
    }
    .search-generic-inline form .field-group:has(button .search-icon) {
        display: flex;
        vertical-align: middle;
    }

    // Override Bootstrap so bottom border is not too wide
    .navbar-nav {
        .nav-item {
            .nav-link {
                margin: 0 ($spacer * .2);
                padding-right: 0.25rem;
                padding-left: 0.25rem;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .navbar-main-menu .navbar-nav {
        .nav-item {
            padding: 0 ($spacer * .2);
            border-right: 1px solid #ccc;

            a {
                color: $sg_blue;
            }
        }

        .nav-item:last-of-type {
            border: none;
        }
    }
}
// main menu sub menu
.icon-arrow-right::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 0 0 0.4rem;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{url-encode-colour($sg_white)}'%3e%3cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
}
.icon-arrow-left::before {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 0.4rem 0 0;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{url-encode-colour($sg_white)}'%3e%3cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
}

// second level navigation
.search-generic-wrapper {
    .categories {
        display: inline-block;
    }
    .switch {
        display: inline-block;
        padding: 0 ($spacer * .75);
        text-align: center;
        background-color: $sg_green;
        color: $sg_blue;
        font-weight: bold;
        line-height: 2em;
        float: right;
        border-radius: 0.4rem;
    }
    .switch:hover {
        background-color: $sg_button_hover;
        cursor: pointer;
    }
    .search-generic-click {
        font-size: 0.7em;
        align-content: stretch;
        line-height: 2.3em;
        div {
            flex-grow: 1;
            flex-basis: 0;
            border-left: 1px solid $lightgrey;
            border-right: 1px solid $lightgrey;
            text-align: center;
            line-height: 3em;
        }
        div:first-child {
            border-left: 2px solid $lightgrey;
        }
        div:last-child {
            border-right: 2px solid $lightgrey;
        }
        a {
            color: $textgrey;
        }
        a.active, a:hover {
            @include menu-hover-state-using-margin();
        }
    }
    .hidden {
        display: none;
    }
}

#generic-search-plus {
    display: none;
}

#generic-search-plus.on {
    display: initial;
}

#generic-search-minus {
    display: none;
}

#generic-search-minus.on {
    display: initial;
}

.search-generic-click-2 {
    font-size: 0.75em;
    align-content: stretch;
    line-height: 2.3em;
    div {
        text-align: center;
        border: 1px solid $grey;
        border-radius: 1em;
        padding: 0.5em;
        margin: 0.6em 0;
        min-width: 8em;
    }
    a {
        color: $textgrey;
    }
}



// footer
#page-foot .container-site-links  {
    .bg-grey .btn-blue, .bg-light-green .btn-blue {
        width: 10em;
    }
    form, form input[type=submit].sg-btn-cta {
        width: auto;
        margin: auto;
    }
    div.col {
        padding: 20px;
    }
}
@include media-breakpoint-up(xl) {
    #page-foot .container-site-links div.col {
        margin-right: 20px;
        padding: 2em 1em;
    }
    #page-foot .container-foot ul.foot-links {
        li:last-of-type:after {
            content: "";
        }
        li:after {
            color: #fff;
            padding-left: 5px;
            padding-right: 5px;
            padding-top: 5px;
            content: "|";
        }
    }
}
#page-foot .container-foot ul.foot-links {
    a {
        color: #fff;
    }
}

// news
.content-specific-search form .field-group {
    display: block;
    text-align: center;
    width: 100%;

    select {
        max-width: none;
        width: 100%;
    }
}
.content-specific-search form .collapse .field-group:last-child {
    background-color: inherit;
}
.content-specific-search form .search-icon {
    display: flex;
    border-radius: 50rem;
}
@include media-breakpoint-up(xl) {
    .search-news form .field-group select {
        max-width: 10em;
    }
}
@include media-breakpoint-up(lg) {
    .content-specific-search form .search-icon {
        border-radius: unset;
    }
    .collapsing {
        height: inherit;
        overflow: inherit;
        transition: none;
    }
    // override modal to display on larger widths
    .content-specific-search .modal {
        display: block!important;
        position: inherit;
        opacity: 1;

        .modal-dialog {
            max-width: none;
            transform: none;
            margin: 0;
        }
        .modal-content {
            border: none;

            .modal-header {
                display: none;

                .icon-times-thin::after {
                    filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%);
                }
            }
            .modal-body {
                margin: 0;
            }
        }
    }
}
@include media-breakpoint-down(md) {
    .news-item .article>img {
        margin: 0 0 ($spacer * .75) 0;
        width: 100%;
        text-align: center;
    }
}
@include media-breakpoint-down(lg) {
    .content-specific-search form {
        flex-direction: column;
        padding: 0.5rem;

        .collapse:not(.show) {
            display: none!important;
        }
    }
    .search-news form .field-group input {
        max-width: none;
        width: 100%;
    }

    .content-specific-search form .field-group input[type=text] {
        width: 100%;
    }
}

// homepage / banner / video carousel
.video-carousel {
    .item-thumb img {
        height: auto;
    }
}
@include media-breakpoint-down(lg) {
    #page-content .widget-grid.home .results-grid.conferences {
        margin-bottom: 0;

        .item-row {
            margin-top: 0;

            .item-thumb a {
                height: 5em;
            }
        }
    }
}
.banner {
    padding-bottom: 1rem;
}


// conferences
@include media-breakpoint-down(md) {
    #page-content .results-grid.conferences .item-row {
        width: 100%;
        margin: 0 2em 0 0;
    }
}

#page-content .results-grid.conferences .item-row .item-thumb {
    max-width: 150px;
    width: 100%;
}

// video
.video-browse-body {
    .video-carousel {
        aspect-ratio: 357 / 200;
        height: auto;
        width: 100%;
        margin: 0 auto;

        .item-thumb img {
            height: auto;
        }
    }
}
.video-display-body .video-detail-strip {
    display: flex;
    padding: 0.25em 0.5em 0.15em;
    justify-content: space-between;

    div {
        margin: 0 0.5rem;
    }
    div:first-of-type {
        margin-left: 0;
    }
    div:last-of-type {
        margin-right: 0;
    }
    .video-detail-published {
        margin-right: 0.5rem;
    }
}
#page-content .video-btn-strip .btn {
    display: inline-block;
    margin-bottom: 0.2rem;
}
.video-browse-body .video-carousel .item-thumb img {
    height: auto;
}
@include media-breakpoint-up(xl) {
    .video-browse-body .video-carousel {
        height: auto;
        width: 714px;
    }
    .video-display-body .video-detail-strip {
        padding: 0.25em 1em 0.15em;
    }
}
.video-browse-body, .video-display-body, .conference-videos-body, .journal-body, .general-browse-results {
    .results-grid .grid-item {
        display: block;
        width: auto;

        background-color: #f0f0f0;
        padding: 1.5em 1em 2em;
        margin-bottom: 1.5em;
        flex-direction: column;

        .grid-item-title {
            height: 5em;
        }
    }
}
.general-browse-results .results-grid.elearning-courses-grid .grid-item {
    background-color: unset;
}

// elearning
.elearning-registration-prompt .registration-cta-bar {
    line-height: 2.5em;
}
.elearning-courses-grid, .course-detail {
    .elearning-meta-row{
        display:flex;
        flex-wrap: wrap!important;
        flex-flow: row;
        color: $textgrey;

        .meta-detail{
            padding-right: 1em;
            margin-bottom: 1em;
            font-size: $font_size_reduced;

            .content {
                padding-right: 1em;
                border-right: solid 1px $lightgrey;
            }
        }

        .meta-detail:last-child {
            color: $textblack;
            .content {
                padding-right: 0;
                width: $percentage_complete_indicator_width;
            }
            .content:not(.e-learning-percentage-complete) {
                border-right: none;
            }
            flex-grow: 1;
        }
    }
    .button-bar{
        display:flex;
        flex-direction: row;
    }
    .button-bar-centered{
        justify-content: center;
    }
    .button-bar .btn{
        margin-right: 1em;
    }
}
.course-browse .grid-item, .module-browse .grid-item, .elearning-courses-grid .grid-item {
    width: auto;
    font-size: 1em;
}
@include media-breakpoint-down(md) {
    .e-learning-course-container article, .e-learning-course-container .course-detail {
        width: auto;
    }
    .e-learning-course-container {
        margin-bottom: 0;
    }
}
.button-bar {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    a, button {
        margin-right: 1em!important;
        margin-top: 1em!important;
    }
}

// static pages
@include media-breakpoint-down(md) {
    .static-page-container.about-us, .static-page-container.support-us {
        margin-right: auto!important;

        .intro img {
            float: none;
            margin-right: auto;
            margin-left: auto;
            padding-bottom: 1em;
        }

        .affiliations {
            .panel {
                margin-bottom: 1em;
            }

            .row2 {
                .panel1 {
                    max-width: 100%;
                }

                .panel2 {
                    max-width: 100%;
                }
            }
        }
    }
}

.static-page-container.about-us, .static-page-container.support-us {
    margin-right: 1em;
}

// registration page form
#page-registration, .like-registration {
    label {
        display: inline;
    }
    .form-group {
        margin: 1em 0 0 0;
    }
    .accordion-panel {
        padding: 1em;

        .form-control label {
            display: block;
        }
    }
    .accordion-wrapper.slimline {
        .accordion-panel .form-control {
            input {
                margin-right: 2%;
            }
            label {
                margin-left: 1%;
                margin-right: 4%;
                width: 86%;
            }
        }
    }
    #registration_email_preference_content_type, #communication_preferences_all_email_updates_preference_content_type {
        label {
            margin-left: 1em;
        }
        label::after {
            content: "\a";
            white-space: pre;
        }
    }
}

@include media-breakpoint-up(md) {
    #page-registration, .like-registration {
        .form-group {
            margin: 1em 0 0 3em;
        }
        .accordion-wrapper.slimline {
            .accordion-panel .form-control {
                label {
                    width: 40%;
                }
            }
        }
        #registration_email_preference_content_type, #communication_preferences_all_email_updates_preference_content_type {
            label::after {
                content: "";
                white-space: normal;
            }
        }
    }
    #page-registration, .like-registration {
        form .regn-form-profile label {
            display: inline-block;
            width: 13em;
        }
    }
}
@include media-breakpoint-up(lg) {
    #page-registration , .like-registration {
        .accordion-panel {
            padding: 0 16em;
        }


    }
}

// login page
#login-content {
    margin-top: 2em;
    margin-bottom: 3em;
    #page-login {
        width: 80%;
    }
    label {
        display: inline;
        width: 13em;
    }
}

@include media-breakpoint-up(md) {
    #login-content {
        margin-top: 150px;
        margin-bottom: 150px;
        #page-login {
            width: 50%;
        }
    }
}


// user (logged in area) pages
#page-content {
    #page-user {

        button[type=submit] {
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }
    .user form[name="profile"] {
        button {
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }

    .container-flex.user .widget-list .item-row.two-col {
        width: auto;

        img {
            max-height: 150px;
            max-width: 150px;
            width: 100%;
        }
        img:hover {
            @include image-opacity-80-percent();
        }
        a:hover {
            @include image-opacity-80-percent();
        }
        .date{
            margin-top:10px;
            display:block;
        }
    }
}

// journal
.results-list.articles .item-row .item-thumb img, .journal-special-issues-list img {
    height: auto;
}
.journal-body .results-grid .grid-item .grid-item-title {
    height: auto;
}
.img-responsive-aspect {
    width: 100% !important;
    height: auto !important;
}
.journal-special-issue {
    .intro .thumb, .full-article.journal-editorial-item .article img:nth-of-type(1) {
        margin: 0 0 ($spacer * .75) 0;
        width: 100%!important;
        text-align: center;
        max-width: 100%!important;
    }
}
.interview-wrapper .kaltura_player iframe,
.journal-special-issue .video-special-issue-abstract .kaltura_player iframe {
    min-height: auto;
}
.journal-article-item .article-metrics .details .summary, .journal-editorial-item .article-metrics .details .summary,
.journal-article-item .article-metrics .details .metric, .journal-editorial-item .article-metrics .details .metric,
.journal-body #metrics-full .altmetric.donut {
    margin-top: 0.75rem;
}
.journal-body #metrics-full {
    display: flex;
    margin-bottom: 0.75rem;

    .altmetric.donut {
        margin-left: 1.3rem;
    }
    .details .summary {
        margin-right: 1em;
    }
    .details .metric {
        margin-left: 0;
    }
}
.journal-article-item.abstract, .journal-article-item.full, .journal-editorial-item {
    .item-details, .journal-editorial-item {
        .article-doi, .article-links, .article-published-date {
            word-wrap: break-word;
            word-break: break-word;
            white-space: nowrap;
        }
    }
}
@include media-breakpoint-up(md) {
    .journal-special-issue {
        .intro .thumb, .full-article.journal-editorial-item .article img:nth-of-type(1) {
            float: right;
            margin: 0 0 4em 4em;
            width: 40%!important;
            max-width: 300px!important;
        }
    }
    .interview-wrapper .kaltura_player iframe,
    .journal-special-issue .video-special-issue-abstract .kaltura_player iframe {
        min-height: 22em;
        width: 100%;
    }
}
@include media-breakpoint-down(lg) {
    h1.page-title .journal-title-branded {
        display: none;
    }
}

// modals
.modal-backdrop {
    background-color: #fff;
}
.modal-dialog {
    .modal-header {
        color: #0056b4;
        font-size: 1.25em;
        font-weight: 700;
    }
    .close-button {
        text-align: right;
        color: #0056b4;
        margin-bottom: 0.5em;

        .icon-times-thin::after {
            filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%);
        }
    }
    .modal-header, .modal-body, .modal-footer {
        margin: 1rem;
        padding: 0;
    }
    .login-modal .registration-cta-bar {
        width: 100%;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: space-between;

        a.login {
            margin-left: 1em;

            span.login {
                color: #0056b4;
                font-weight: 700;
            }
        }
    }
    .video-rating-question-container, .article-rating-question-container {
        margin-bottom: 1.5em;

        .rating-container .rating button {
            //background: none;
            border: none;
            color: #ccc;
            font-size: 1.5em;
            background-color: pink;
        }
        .question {
            width: 100%;
        }
    }

    .modal-footer {
        border: none;
    }
}
#videoRatingModal dialog, #journalRatingModal dialog, #journalCiteModal dialog {
    padding: 0;
    left: 0;
    box-shadow: none;

    .modal-body .video-rating-question-container div.rating-container, .modal-body .article-rating-question-container div.rating-container {
        margin: 0;
    }
    h4.question {
        width: 100%;
        font: inherit;
    }
    .modal-footer {
        margin-top: 0;
    }
}

// main menu dialog
.ui-dialog.main.menu {
    .ui-dialog-titlebar-close {
        margin-right: 0.5rem;
        margin-top: -15px;
        width: 1em;
        height: 1em;
        z-index: 2000;
    }
    .ui-dialog-titlebar {
        padding-top: 0;
    }
    .nav-item a.active {
        color: $sg_green;
        font-weight: 700;
    }
    .search-generic-inline {
        width: 100%;

        .field-group:nth-child(3) {
            width: 100%;

            input[placeholder='Search ecancer'], input[placeholder='Buscar ecancer'] {
                width: 100%;
            }
        }
        form .field-group:has(button .search-icon) {
            display: flex;
            vertical-align: middle;
        }
    }
}
@include media-breakpoint-up(lg) {
    .ui-dialog.main.menu {
        .row .col-12 {
            text-align: center;
            .search-generic-inline {
                justify-content: center;
            }
        }
        .row .col-12:last-child {
            border-left: 1px solid #fff;
        }
    }
}
.btn {
    line-height: normal;
}

// sidebar
#right-col .sidebar {
    padding-bottom: 1rem;
}
.content-journal-editorial-item .right .side-menu,
.content-journal-editorial-item .right .sidebar,
.content-news-item .right .side-menu,
.content-news-item .right .sidebar {
    padding-left: 0;
}
@include media-breakpoint-up(lg) {
    .content-journal-editorial-item .right .side-menu,
    .content-journal-editorial-item .right .sidebar,
    .content-news-item .right .side-menu,
    .content-news-item .right .sidebar {
        padding-left: 0.5rem;
    }
}

// pager
.pager {
    margin-bottom: 1rem;
}

// back to top of page button
.back-to-top-link {
    visibility:hidden;
    opacity:0;
    transition:.2s;
    position:fixed;
    bottom:1.2rem;
    right:1.5rem;
    z-index:15
}
.back-to-top-link[data-visible=true] {
    opacity:1;
    visibility:visible
}
.back-to-top-link svg {
    -webkit-filter:drop-shadow(0 2px 5px rgba(0,0,0,.2));
    filter:drop-shadow(0 2px 5px rgba(0,0,0,.2))
}

// table of contents
.offcanvas {
    .offcanvas-body {
        padding: 0.5rem;
        .nav-link {
            padding-left: 0.5rem;
        }
    }
    .offcanvas-header {
        color: #0056b4;
        font-size: 1.25em;
        font-weight: 700;
        padding-bottom: 0;

        .icon-times-thin::after {
            filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%);
        }
    }
    .close-button {
        text-align: right;
        color: #0056b4;

        .icon-times-thin::after {
            filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%);
        }
    }
}

// table of contents button
.toc-quick-link {
    visibility:hidden;
    opacity:0;
    transition:.2s;
    position:fixed;
    bottom:1.2rem;
    left:1.5rem;
    z-index:15;
    padding:5px;
}
.toc-quick-link[data-visible=true] {
    opacity:1;
    visibility:visible
}
.toc-quick-link {
    -webkit-filter:drop-shadow(0 2px 5px rgba(0,0,0,.2));
    filter:drop-shadow(0 2px 5px rgba(0,0,0,.2))
}
