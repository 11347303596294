.pager {

    margin-top: 3em;
    padding-bottom: 1em;
    text-align:center;
    border-bottom: solid 2px $lightgrey;

    a, span {
        display: inline-block;
        padding: 0.75em;
        color: $sg_blue;
        border: solid 1px white;
        border-radius: 2px;
    }

    a {
        text-decoration: none;
    }
    a:hover {
        font-weight: bold;
        border: solid 1px $grey;
    }

    span.disabled,
    nav a:first-child,
    nav a:last-child {
        border-width: 0;
        color: $sg_blue;
        border-radius: 2px;
        .icon-chevron-right::after, .icon-chevron-left::after {
            height: 0.9rem;
            background-size: 0.9rem;
        }
    }

    span.disabled:hover,
    nav a:first-child:hover,
    nav a:last-child:hover {
        font-weight: bold;
        background-color: $sg_gradient_grey_top;
    }

    span.disabled {
        display: none;
    }

    .dots + a {
        display: none;
    }

    .dots {
        display: none;
    }

    .current {
        background-color: $sg_green;
    }

    .disabled {
        border-color: #ccf;
        color: #999;
    }

    .disabled {
        color: $linkStandardColor;
    }
}

.pager.np_1 {
    display: none;
}

