@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.http_code {
    margin-bottom: 150px;

    h1 {
        margin-bottom: 1em;
    }
    p {
        line-height: 1.2em;
    }
    ol {
        list-style: disc inside;
    }
    a {
        color: $sg_blue;
    }
    a:hover {
        color: $sg_blue_button_hover;
    }
}
@include media-breakpoint-up(lg) {
    .http_code p {
        line-height: 2em;
    }
}