nav ul{
    margin:0;
    padding:0;
    list-style: none;
}
.container-row{
}
.container-row-top{
    //width:100%;
    //float:left;
}

/*---------------------------- ecancer logo -----------------------------*/

.container-row.logo {

    align-items: center;

    img {
        height: 3em;
    }

    .col_2 {
        text-align: center;
    }

}

.head-left{
    float:left;
    width:50%;

    img {
        height: 3em;
    }
}

/*------------------------------------------------------------------------*/

.container-logo{
    float:left;
    width:50%;
}
.container-head{
    color: white;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
}
.container-menu-primary{
    padding-top: 1em;
    padding-bottom: 0.25em;

    // tweak search on homepage
    .search-generic-inline {
        .field-group {
            position: inherit!important;
            input[type="text"] {
                padding: 0.2em 0.8em;
            }
        }
    }

}
.container-conference{
    padding:20px 0;
    background-color: $lightgrey;
}
#logo{
    //float:left;
    z-index: 100;
}

.donate{
    //float:right;
}
.container-row .btn-donate {
    margin: 0.25em 0;
    float: right;
}

.donate .btn-green{
    border-radius: .25rem;
}

#search-header-home{
    background-color:#DFE3EC;
    padding:10px;
    padding-bottom:5px;
    padding-top:5px;
}
#search-header-home form div#home{
    width:100%;
}
#search-header-home form div#home div{
    float:left;
    margin-left:7px;
}
#search-header-home .search-text{
    padding-right:30px;
    padding-top:10px;
}
#search-header-home label, #search-header-home select, #search-header-home .search-text{
    margin-top:5px;
}
#search-header-home select{
    width:162px;
}

#page-hero {

}

#middle-content{
    //margin-left:20px;
    //margin-right:20px;
}
#right-content{
    //margin-left:20px;
}
.hero-video {
    padding:0;
}
.hero-conference{
    padding:0;
}
.hero-conference .item-thumb{
    width:300px;
}
.hero-video .widget-contents img{
    width: 600px;
    max-height: none;
    max-width: none;
}
.hero-video .arrow-bar{
    position: relative;
    float:left;
    width:100%;
    padding-top:0;
    top:100px;
}
.hero-video .item-title{
    text-align:center;
}
.hero-video .item-thumb{
    text-align: center;
}
.hero{
    border: 1px solid #DFE3EC;
    padding:20px;
    margin-bottom: 10px;
}
.hero .video-player{
    margin-left:20px;
}
.hero h1{
    margin:0;
    padding:0;
}
.hero-text{
    float:left;
    width:50%;

}
.hero-image-container{
    width: 300px;
    height:150px;
    float:right;
    margin-left:20px;
}
.hero-image{
    width:100%;
    margin-top:10px;
    height:150px;
    background-color:#DFE3EC;

}
.hero-conference img{
    max-width:150px;
    margin-right:10px;
}
.hero-text .btn-submit{
    width:50px;
}
.hero-text p{
    margin-top:0;
}
h1.hero-title{
    margin-bottom:20px;
}
div.journal-link-logos{
    margin-top:20px;
    padding-left:3px;
    text-align:center;
}
.item-details{
    padding: 0.2em 0.3em;
    background-color:#DFE3EC;
}
.item-details .article-published-date, .item-details .article-doi, .item-details .article-links{
    font-size: 0.8em;
}
.article-links{
    text-align: right;
}
.widget-list.widget-list-plain{
    border:none;
}
.widget-list.widget-list-news{
    border-left:none;
    border-right:none;
}

.widget-list.widget-list-plain .item-row{
    border:none;
}
.static-page .page-title{
}
.page-title-sub{
    width:100%;
    float:left;
}
.page-title-sub-left{
    float:left;
    width:50%;
    text-align: left;
}
.page-title-sub-right{
    float:right;
    text-align: right;
}
.page-title, .widget-title {
    margin:0;
    line-height: 1em;
    font-size: 1.5em;
    font-weight: 600;
    color: $sg_blue;
    border-left: solid 0.3em $sg_green;
    padding-left: 0.4em;
    margin-bottom: 0.3em;

    a {
        color: $sg_blue;
    }

    .journal-title-branded {
        .part2 {
            color: $journalTitleBrandingPart2Color;
        }
    }
}

.journal-article-title {
    margin:0;
    line-height: 1.25em;
    font-size: 1.5em;
    color: $sg_blue;
    margin-bottom: 0.3em;
}

.journal-article-type {
    margin: 0.8em 0 0.3em;
    line-height: 1em;
    font-size: 1em;
    color: $sg_blue;
    margin-bottom: 0.3em;
}

.intro-image{
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
    .caption {
        margin: 0;
        font-size: $font_size_reduced;
        font-style: italic;
    }
}
.image-hero-main{
    display: flex;
    justify-content: center;
    width:100%;
}
.image-hero-main img{
    max-height: 300px;
}

#panel-hero-strap {
    background-color: $blue;
    color:$white;
    padding:20px 0;

    .ms-color-2 {
        color: $sg_green;
    }

    .issn {
        font-size: 0.75em;
        float: right;
    }
}

.sg-gradient {
    background: $sg_blue; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwNjNjYyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc1JSIgc3RvcC1jb2xvcj0iIzAwNTZiNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDU1YmIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, $sg_gradient_blue_top 0%, $sg_gradient_blue_breakpoint $sg_gradient_percent, $sg_gradient_blue_bottom 100%); /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$sg_gradient_blue_top), color-stop($sg_gradient_percent,$sg_gradient_blue_breakpoint), color-stop(100%,$sg_gradient_blue_bottom)); /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(top, $sg_gradient_blue_top 0%,$sg_gradient_blue_breakpoint $sg_gradient_percent,$sg_gradient_blue_bottom 100%); /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(top, $sg_gradient_blue_top 0%,$sg_gradient_blue_breakpoint $sg_gradient_percent,$sg_gradient_blue_bottom 100%); /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(top, $sg_gradient_blue_top 0%,$sg_gradient_blue_breakpoint $sg_gradient_percent,$sg_gradient_blue_bottom 100%); /* IE10 preview */
    background: linear-gradient(to bottom, $sg_gradient_blue_top 0%,$sg_gradient_blue_breakpoint $sg_gradient_percent,$sg_gradient_blue_bottom 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$sg_gradient_blue_top', endColorstr='$sg_gradient_blue_bottom',GradientType=0 ); /* IE6-8 */
}

.sg-gradient-grey {
    background: $sg_grey; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwNjNjYyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc1JSIgc3RvcC1jb2xvcj0iIzAwNTZiNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDU1YmIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, $sg_gradient_grey_top 0%, $sg_gradient_grey_breakpoint $sg_gradient_percent, $sg_gradient_grey_bottom 100%); /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$sg_gradient_grey_top), color-stop($sg_gradient_percent,$sg_gradient_grey_breakpoint), color-stop(100%,$sg_gradient_grey_bottom)); /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(top, $sg_gradient_grey_top 0%,$sg_gradient_grey_breakpoint $sg_gradient_percent,$sg_gradient_grey_bottom 100%); /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(top, $sg_gradient_grey_top 0%,$sg_gradient_grey_breakpoint $sg_gradient_percent,$sg_gradient_grey_bottom 100%); /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(top, $sg_gradient_grey_top 0%,$sg_gradient_grey_breakpoint $sg_gradient_percent,$sg_gradient_grey_bottom 100%); /* IE10 preview */
    background: linear-gradient(to bottom, $sg_gradient_grey_top 0%,$sg_gradient_grey_breakpoint $sg_gradient_percent,$sg_gradient_grey_bottom 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$sg_gradient_grey_top', endColorstr='$sg_gradient_grey_bottom',GradientType=0 ); /* IE6-8 */
}