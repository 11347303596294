
#search-form{
  text-align: center;
}

#panel-search input[type='text']{
  padding:10px;
  width:200px;
  margin:5px;
}
.field-group{
  margin:0 auto;
}

.field-group label{
  display: block;
  text-align: center;
}
#panel-search .search-conference select[name='region_id'],
#panel-search .search-conference select[name='country_id'],
#panel-search .search-e_learning select[name='profession']
{
  width:125px;
}
#panel-search .search-conference input[type='text']{
  width:250px;
}
#panel-search button{
  border:none;
  background-color:transparent;
}
#panel-search button i{
  font-size: 20px;
}
#panel-search button:hover{
  cursor: pointer;
}
#panel-search{
  padding-top:10px;
  padding-bottom:10px;
  background-color:$lightgrey;
}
.form-inline {
  display:block;
  margin-left:auto;
  margin-right:auto;
}
.field-group{
  float:left;
}
