@mixin lineClamp($nLines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $nLines;
    line-clamp: $nLines;
    -webkit-box-orient: vertical;
    height: #{$nLines}em;
}

.lc-1 {
    @include lineClamp(1);
}

.lc-2 {
    @include lineClamp(2);
}

.lc-3 {
    @include lineClamp(3);
}

.lc-4 {
    @include lineClamp(4);
}

.lc-5 {
    @include lineClamp(5);
}

.lc-6 {
    @include lineClamp(6);
}

.lc-7 {
    @include lineClamp(7);
}

.lc-8 {
    @include lineClamp(8);
}
