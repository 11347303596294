/* NB: Ideally - everything in here should be within a form {} or be form specific via an id or class */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

form
{
    label
    {
        width: 13em;
        line-height: 2em;
    }

    label.col-form-label.required::after
    {
        content: " *";
        color: red;
    }

    label.col-form-label.info::before
    {
        content: "i ";
        color: $lightblue;
        vertical-align: super;
        font-size: small;
        font-style: italic;
    }

}

input[type="text"], input[type=password], input[type=email], textarea{
    padding: 0.5em;
}
input.border-round{
    border-radius: 20px;
    border:1px solid #DDD;
}
input[type="submit"]{

}
.btn-primary{

}
.btn-secondary{
    background-color:#FFF;
    padding:10px;
    border:1px solid #DDD;
}
.btn-secondary:hover{
    background-color:#C8D51E;
    border-color:#C8D51E;
}

.form-search-container{
    //display: flex;
    //justify-content: center;
}
input[type="submit"]:hover{
    cursor: pointer;
}

/* Type Site form */
form[name="search_site"] input[type="text"]{
    width:350px;
}
form[name="search_site"]{
    margin:0;
}
#panel-search{
    text-align: center;
}
#panel-search input[type=text]#news_search_keyword
{
    width:250px;
}
#panel-search input[type=text]#journal_search_keyword{
    width:200px;
}
#panel-search input[type=text]#e_learning_search_keyword{
    width:180px;
}
#panel-search input[type=text]#conference_search_keyword{
    width:200px;
}
#panel-search input[type=text]#video_search_keyword{
    width:330px;
}
#panel-search form[name='news']  button,
#panel-search form[name='journal']  button
#panel-search form[name='video']  button
{
    padding-left:10px;
    padding-top:30px;
}
select#news_conference,
select#video_conference
{
    width:200px;
}
select#conference_region,
select#conference_country{
    width:160px;
}

select#journal_volume{
    width:80px;
}
.search-term{
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border:1px solid #DDD;

}
#panel-search select{
    margin:5px;
    padding: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    height: 34px;
}
#panel-search label{
    width:auto;
}
#search-header-home label{
    color:#999;
    margin-right:5px;
}

#keyword-search-form  .input-group-append {
    margin-left: -1px;
    position: absolute;
    right: -41px;
    height: 42px;
}
#filter-container{
    padding:5px;
    background-color:#f4f4f4;
}
.filter-form .form-row{
    float:left;
    margin:5px;
    margin-top:10px;
}

.filter-form .form-label{
   padding-top:2px;
}
#filter-container .pager{
    margin:5px;
}
#filter-container{
    margin-bottom: 20px;
}
.form-input-container{
    width:100%;
}
input.form-control {
    box-sizing: border-box;
}
.btn-right{
    float:right;
}
.btn{
    padding:10px;
}
#page-registration, .like-registration {
    width:100%;
    .page-title {
        margin-bottom: 2em;
    }
    .regn-form-profile, .regn-form-comms-prefs, .regn-form-email-prefs, .regn-form-categories {
        margin-bottom: 2em;
        h2 {
            margin-bottom: 1em;
        }
    }
    .form-group {
        margin: 1em 0 0 3em;
        .question {
            width: 100%;
            margin-bottom: 1em;
        }
        input {
            border-radius: 0.3em;
        }
        input[type=text], input[type=email], input[type=password] {
            width:100%;
        }
        input[type=radio] {
            margin-right: 1em;
        }
        label.single {
            margin-left: 1em;
        }
    }
    .form-control label {
        margin-right: 2em;
        margin-left: 0.25em;
    }
    select {
        border: 1px solid #ccc;
        padding: 0.5em;
        width:100%;
        border-radius: 0.2em;
    }
    .btn-row {
        margin-top: 1em;
    }
    #registration_email_preference_email_frequency, .email-frequency {
        width: auto;
        margin-left: 1em;
    }
    h3 {
        font-size: 1.1em;
        line-height: 1.1em;
        margin-bottom: 1em;
    }
}

.form-group-error label{
  color: $errorText;
}

.form-group-error input{
  border:1px solid $errorBorder;
   background-color: $errorBg;
}
.form-group-error ul{
  list-style:none;
    color:$errorText;
    margin:0;
    padding:0;
}
.e-learning-reflect {

    .content-specific-nav {
        margin-bottom: 3em;
    }

    .page-title {
        margin-bottom: 1.5em;
    }

    textarea#reflect_reflection{
        height: 20em;
        width: 100%;
        padding: 1em;
        margin-bottom: 1em;

        // stops textarea going outside container
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

}
.search-generic-inline {

    form {

        display: flex;

        .field-group {
            display: inline;
            float: initial;
            label {
                display: none;
            }
        }

        // move first and second field groups out of the flow
        .field-group:nth-child(1),
        .field-group:nth-child(2)
        {
            position: fixed;
            top: 0;
            left: 0;
        }

        select {
            visibility: hidden;
            width: 0;
        }

        text-align: center;
        input[type="text"] {
            padding: 0.4em 0.8em;
            text-align: right;
            border-radius: 1em;
            border: solid 1px $lightgrey;
            color: $textgrey;
        }
        button {
            border: none;
            background: none;
            color: $lightgrey;
            font-size: 1.3em;

            .icon-search::after {
                height: 1.3rem;
                width: 1.3rem;
                background-size: 1.3rem;
                filter: invert(88%) sepia(0%) saturate(0%) hue-rotate(318deg) brightness(96%) contrast(87%);
            }
        }
        button:hover {
            color: $textgrey;
        }
    }

}

.content-specific-search {
    form {
        display: flex;
        width: 100%;

        .field-group {

            //flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 30%;

            display: flex;
            flex-direction: column;
            float: none;
            //display: inline;
            padding: 0.4em 0.2em;

            label {
                width: auto;
                font-size: $font_size_reduced;
                color: $textgrey;
            }

            select {
                padding: 0.4em 0.8em;
                border-radius: 1em;
                background-color: white;
                border: solid 1px $lightgrey;
                font-size: $font_size_reduced;
            }

            input[type="text"] {
                padding: 0.4em 0.8em;
                text-align: right;
                border-radius: 1em;
                border: solid 1px $lightgrey;
                font-size: $font_size_reduced;
            }

            button {
                border: none;
                background: none;
                color: $lightgrey;
                font-size: 1.3em;
                flex: 1;
                padding: 0;
            }

        }

        .field-group:last-child, .search-icon {
            .icon-search {
                color: $sg_blue;
            }
            .icon-search::after {
                height: 1.3rem;
                width: 1.3rem;
                background-size: 1.3rem;
                filter: invert(17%) sepia(54%) saturate(4871%) hue-rotate(201deg) brightness(97%) contrast(101%);
            }
            background-color: $sg_green;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 3em;
        }

        .field-group:last-child:hover, .search-icon:hover {
            background-color: $sg_button_hover;
            cursor: pointer;
            .search-icon {
                background-color: $sg_button_hover;
                cursor: pointer;
            }
        }

    }
    //border-top: solid 1px #dedede; // save in case remove filter above
    border-bottom: solid 1px $horizontal_divider_line;
    margin-bottom: 0.8em;
}

.search-video
{
    form {
        .field-group {
            select {
                max-width: 11em; // critical
            }
        }
    }
}

.search-news
{
    form {
        .field-group {
            select {
                max-width: 8em; // critical
            }
        }
    }
}

.search-journal
{
    form {
        .field-group {
            select {
                max-width: 10em; // critical
            }
        }
    }
}

.search-conference
{
    form {
        .field-group {
            select {
                max-width: 7em; // critical
            }
        }
    }
}

.search-e_learning
{
    form {
        .field-group {
            select {
                max-width: 20em; // critical
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .content-specific-search form .field-group:last-child, .search-icon {
        flex-basis: 2em;
    }
}

