@mixin background-image-contained() {
    width: 100%;
    height: 100%;
    float: none;
    margin-left: 0;
    margin-bottom: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Given a parent div with defined width, and a child div inside that,
// include this mixin fill with an image (distorting the image).
// .child {
//    @include force_image_16x9();
// }
// Ensure the child has the background-image set e.g. as an inline style.
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin force_image_16x9() {
    width: 100%;
    padding-top: 56.25%;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: 100% 100%; // this is what distorts the image (use "cover" or "contain" to crop to fit)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin image-opacity-80-percent() {
    opacity: 0.8;
    filter: alpha(opacity=80); /* For IE8 and earlier */
}

@mixin set-opacity-fractional($opacity) {

    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity)";

    /* IE 5-7 */
    filter: alpha(opacity=percentage($opacity));

    /* Netscape */
    -moz-opacity: $opacity;

    /* Safari 1.x */
    -khtml-opacity: $opacity;

    /* Good browsers */
    opacity: $opacity;
}