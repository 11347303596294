.banner {
    .banner-2nd-link {
        display: block;
        width: 114px;
        font-size: 0.7em;
        margin: 0 auto;
        background-color: #dcdcdc;
        padding: 3px;
        color: #3192bb;
        position: relative;
        top: -3px;
    }

    img {
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
        border-bottom-style: none;
    }

    .banner-2nd-link:hover {
        //@include menu-hover-state-using-margin();
        font-weight: bold;
    }

    // EPL-473 style the Tecentriq banner
    .banner-2nd-link.banner-id-192 {
        border: solid 1px #63194c;
        border-top: none;
        width: 112px;
    }
}