.video-categories.journal,
.video-categories.video
{
    font-size: $font_size_reduced;
    float: none;
    display: block;
    margin-bottom: 2em;
}

.page-title.related {
    margin-bottom: 1em;
}

.results-list.articles {
    .item-row {
        .item-thumb {
            img {
                display: block;
            }
        }
        .item-title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .item-details {
                font-size: 0.95em;
            }
        }
        margin-bottom: 2.5em;
    }
}

.interview-wrapper {
    text-align: center;
    h2 {
        font-size: 1.2em;
        color: $sg_blue;
        margin-bottom: 0.5em;
    }
    .author-intro {
        font-size: $font_size_reduced;
        margin-bottom: 2em;
    }
    .kaltura_player {
        iframe {
            min-height: 22em;
            width: 100%;
        }
    }
    .interview {
        margin-top: 1em;
        margin-bottom: 2em;
        text-align: left;
    }
}

.embedded-media.default-height {
    .kaltura_player {
        iframe {
            min-height: 20em;
            width: 100%;
            margin-top: 2em;
            margin-bottom: 1em;
        }
    }
}

.journal-special-issue {
    margin-bottom: 3em;
    .title {
        font-size: 1.5em;
        color: $sg_blue;
        margin-bottom: 0.5em;
        line-height: 1.2em;
    }
    .page-title-sub {
        margin-bottom: 1em;
        .page-title-sub-left {
            float: none;
            width: 100%;
            text-align: initial;
            margin-bottom: 0.4em;
        }
        .summary {
            font-weight: bold;
        }
    }
    .item-details {
        background-color: inherit;
    }
    .intro {
        .thumb {
            float: right;
            margin: 0 0 4em 4em;
            width: 40%;
            img {
                width: 100%;
            }
        }
        .summary {
            p {
                margin-bottom: 1em;
            }
        }
        a {
            border-bottom: solid 2px $sg_green;
        }
    }
    .video-special-issue-abstract {
        margin-top: 2em;
        text-align: center;
        .title {
            font-size: 1em;
        }
        .kaltura_player {
            iframe {
                min-height: 22em;
                width: 100%;
            }
        }
    }
    .full-article {
        img {
            float: right;
            margin-left: 1em;
            margin-bottom: 1em;
        }
    }
    .link-to-abstract {
        margin-top: 1em;
    }
}

.journal-article-item,
.journal-editorial-item
{
    .social-icons {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        float: none;
        text-align: left;
        margin-bottom: 1em;
        .addThis {
            margin: 0;
            float: none;
            display: inline-block;
        }
        .summary {
            color: $white;
            background-color: $sg_blue;
        }
        .article-buttons {
            display: flex;
            align-items: flex-end;
            text-align: right;

            .btn {
                cursor: pointer;
                display: inline-block;
                margin-right: 0.5rem;
            }
        }
    }
    .item-details {
        margin-bottom: 1em;
    }
    .article-content {

        border-bottom: solid 2px lightgrey;
        padding-bottom: 1em;
        margin-bottom: 0.5em;

        // this copies what the typesetters do for the full article
        p {
            margin-top: 6pt!important;
            margin-bottom: 6pt!important;
            line-height: 150%!important;

            span {
                font-size: inherit!important;
            }
        }
        .journal-article-type {
            margin-bottom: 0.5em;
        }
        h1 {
            margin:0;
            line-height: 1.25em;
            font-size: 1.5em;
            color: $sg_blue;
            margin-bottom: 0.3em;
        }
        h2 {
            margin: 0.8em 0 0.3em;
            line-height: 1.2em;
            font-size: 1.15em;
            color: $sg_blue;
            margin-bottom: 0.3em;
        }
        a {
            color: $journalArticleLinkStandardColor;
            text-decoration: underline;
            @include word-wrap();
        }
        a:hover {
            color: $journalArticleLinkHoverColor;
        }
        a:active {
            color: $journalArticleLinkActiveColor;
        }
        img {
            width: initial!important;
            max-width: 95%!important;
            height: initial!important;
            margin-top: 1em;
            margin-bottom: 0.25em;
        }
        ol {
            list-style-type: decimal;
            list-style-position: inside;
            li {
                margin-top: 6pt;
                margin-bottom: 6pt;
                line-height: 150%;
            }
        }
    }
    .article-metrics {
        display: inline-block;
        .details {
            .summary {
                color: inherit;
                background-color: $lightgrey;
            }
            .metric {
                display: inline-block;
                max-width: 7em;
                height: 4em;
                margin-left: 1em;
                .value {
                    padding: 0.2em 0.7em;
                    background-color: $sg_green;
                    color: $white;
                    border-bottom-right-radius: 1em;
                    border-top-right-radius: 1em;
                    font-weight: bold;
                }
                .image {

                    height: 3em;
                    white-space: nowrap;
                    margin-bottom: 0.6em;

                    .helper {
                        display: inline-block;
                        height: 100%;
                        vertical-align: middle;
                    }
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .summary {
        display: inline-block;
        vertical-align: top;
        font-size: 0.8em;
        border-radius: 0.2em;
        border: 0;
        .title {
            font-weight: bold;
        }
        .value {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }

}

.journal-editorials-list {
    .item-row {
        margin-bottom: 2.5em;
        border: solid 2px lightgrey;
        border-radius: 0.3em;
        .item-title {
            margin-left: 0;
            margin-bottom: 0.35em;
            padding: .5em .5em 0;
            font-weight: bold;
        }
        .author-names {
            padding: .5em .5em;
            color: $textgrey;
        }
        .item-details {
            padding: .4em .5em .2em;
        }
    }
}

.journal-special-issues-list {
    .results-list {
        .item-row {
            margin-bottom: 2.5em;
            .title {
                font-weight: bold;
            }
            .guest-editors {
                font-style: italic;
                color: $textgrey;
            }
        }
    }
}

.journal-body {

    .content-specific-search {
        margin-bottom: 1em;
    }

    .grid-item {
        min-height: 17em;
        .grid-item-title {
            flex: 2;
            //margin-bottom: 1em;
        }
        .grid-item-subtitle {
            flex: 2;
            font-size: $font_size_reduced;
            margin-bottom: 1em;
        }
        .grid-item-image {
            flex: 2;
            a {
                height: 8em;
                display: block;
                background-position: top;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
        .grid-item-image-details {

        }
    }

    #metrics-full {
        .altmetric.donut {
            display: inline-block;
            margin-left: 2em;
        }
    }
}
.journal-titling {
    display: flex;
    align-items: center;

    .submissions {
        text-align: center;
        div.submit-article {
            display: inline-block;
        }
    }

    .issn {
        padding-bottom: 0.5em;
        color: $textblue;
        font-size: $font_size_reduced;
        font-weight: bold;
    }
    .blurb .editor-in-chief {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        font-size: 1.02em;
        @include wraps-journal-styled-links;
    }
}
.journal-titling.partial {
    margin-bottom: 0.4em;
    .submissions{
        text-align: right;
    }
}
.journal-body.editorial-board {
    h1 {
        margin-bottom: 1em;
    }

    .editor-in-chief {
        margin-top: 0.5em;
        margin-bottom: 1.5em;
        margin-left: 10px;
        font-size: 1.02em;
        @include wraps-journal-styled-links;
    }

    .results-list {
        @include wraps-journal-styled-links;
    }
}

#page-content .submit-article-page, #page-user {
    p {
        margin-bottom: 1em;
    }
    .submissions.info {

        border: solid 2px $sg_green;
        padding: 1em;
        border-radius: 5px;
        margin-bottom: 1.5em;

        a {
            color: $sg_blue;
        }
        a:hover {
            color: $lightblue;
            @include menu-hover-state-using-margin;
        }
        table.author-submissions-list {
            margin-bottom: 1em;
            td {
                padding: 0.2em 0.35em;
            }
        }
    }
}
