.sidebar .menu-section{
  margin-bottom:10px;
}
.sidebar h3{
  border-bottom: 1px solid $lightgrey;
  margin: 0 0 10px 0;
  padding-bottom: 5px;
}

.menu-section-standard ul li{
  padding: 5px 0 5px;
}