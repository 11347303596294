.video-carousel {
    width:100%;
    @include divider();
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item-title {
        margin-left: 0;
        width:100%;
    }

    .item-thumb img {
        width:100%;
        display: block;
    }

    .arrow-bar {

        padding: 0.3em 40%;
        margin-bottom: 0.7em;
        .arrow {
            display: inline-block;
        }
        .arrow-next{
            float: right;
        }
        .active {
            background-color: inherit;
        }
        .invisible {
            visibility: hidden;
        }
    }


}

.widget-contents-video_featured {
    z-index: 0;
}

.video-carousel {
    .item-thumb img {
        height: $video_carousel_homepage_thumb_height; // critical for IE 11
    }
}

.video-browse-body {
    .video-carousel {
        .item-thumb img {
            height: $video_carousel_videohomepage_thumb_height; // critical for IE 11
        }
    }
}
