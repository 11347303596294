body.preview {
    padding-top: $site_version_warning_height;
}

body.preview.login {
    #page-head, #page-foot, h1 {
        display: none;
    }
    #login-content {
        .buttons.row a {
            display:none;
        }
        form {
            .row {
                margin-top: 1em;
            }
        }
        .alert {
            display: none;
        }
    }
}

.previewer.dialog.wrapper.hidden {
    visibility: hidden;
}
