/* This is the percentage complete widget */
/* shape and colour the parent */

.pc-completeness {

    border-radius: 1em;
    border: solid 1px $lightgrey;
    height: 1.3em;

    /* style the background */
    .background {

        background-color: #c6d420;
        opacity: 0.5;
        height: 1.3em;
        border-top-left-radius: 1em;
        border-bottom-left-radius: 1em;

        // center the text vertically and horizontally
        span {
            position: relative;
            bottom: 0.12em;
            left: 0.25em;
            width: $percentage_complete_indicator_width;
            display: inline-block;
            text-align: center;
        }

    }
    .failed {
        //background-color: red;
    }

    /* one per percentage for precision ! */
    .p0   { width: 0%; }
    .p1   { width: 1%; }
    .p2   { width: 2%; }
    .p3   { width: 3%; }
    .p4   { width: 4%; }
    .p5   { width: 5%; }
    .p6   { width: 6%; }
    .p7   { width: 7%; }
    .p8   { width: 8%; }
    .p9   { width: 9%; }
    .p10  { width: 10%; }
    .p11  { width: 11%; }
    .p12  { width: 12%; }
    .p13  { width: 13%; }
    .p14  { width: 14%; }
    .p15  { width: 15%; }
    .p16  { width: 16%; }
    .p17  { width: 17%; }
    .p18  { width: 18%; }
    .p19  { width: 19%; }
    .p20  { width: 20%; }
    .p21  { width: 21%; }
    .p22  { width: 22%; }
    .p23  { width: 23%; }
    .p24  { width: 24%; }
    .p25  { width: 25%; }
    .p26  { width: 26%; }
    .p27  { width: 27%; }
    .p28  { width: 28%; }
    .p29  { width: 29%; }
    .p30  { width: 30%; }
    .p31  { width: 31%; }
    .p32  { width: 32%; }
    .p33  { width: 33%; }
    .p34  { width: 34%; }
    .p35  { width: 35%; }
    .p36  { width: 36%; }
    .p37  { width: 37%; }
    .p38  { width: 38%; }
    .p39  { width: 39%; }
    .p40  { width: 40%; }
    .p41  { width: 41%; }
    .p42  { width: 42%; }
    .p43  { width: 43%; }
    .p44  { width: 44%; }
    .p45  { width: 45%; }
    .p46  { width: 46%; }
    .p47  { width: 47%; }
    .p48  { width: 48%; }
    .p49  { width: 49%; }
    .p50  { width: 50%; }
    .p51  { width: 51%; }
    .p52  { width: 52%; }
    .p53  { width: 53%; }
    .p54  { width: 54%; }
    .p55  { width: 55%; }
    .p56  { width: 56%; }
    .p57  { width: 57%; }
    .p58  { width: 58%; }
    .p59  { width: 59%; }
    .p60  { width: 60%; }
    .p61  { width: 61%; }
    .p62  { width: 62%; }
    .p63  { width: 63%; }
    .p64  { width: 64%; }
    .p65  { width: 65%; }
    .p66  { width: 66%; }
    .p67  { width: 67%; }
    .p68  { width: 68%; }
    .p69  { width: 69%; }
    .p70  { width: 70%; }
    .p71  { width: 71%; }
    .p72  { width: 72%; }
    .p73  { width: 73%; }
    .p74  { width: 74%; }
    .p75  { width: 75%; }
    .p76  { width: 76%; }
    .p77  { width: 77%; }
    .p78  { width: 78%; }
    .p79  { width: 79%; }
    .p80  { width: 80%; }
    .p81  { width: 81%; }
    .p82  { width: 82%; }
    .p83  { width: 83%; }
    .p84  { width: 84%; }
    .p85  { width: 85%; }
    .p86  { width: 86%; }
    .p87  { width: 87%; }
    .p88  { width: 88%; }
    .p89  { width: 89%; }
    .p90  { width: 90%; }
    .p91  { width: 91%; }
    .p92  { width: 92%; }
    .p93  { width: 93%; }
    .p94  { width: 94%; }
    .p95  { width: 95%; }
    .p96  { width: 96%; }
    .p97  { width: 97%; }
    .p98  { width: 98%;  border-radius: 0.5em;}
    .p99  { width: 99%;  border-radius: 0.75em;}
    .p100 { width: 100%; border-radius: 1em;}

}
