// more generic
.content-item-title {
    color: $sg_blue;
    font-size: 1.5em;
    line-height: 1.15em;
}

// news and journal specific

.content-news-item,
.content-journal-editorial-item
{
    .right .sidebar,
    .right .side-menu
    {
        padding-left: 1em;
        font-size: $font_size_reduced;
        border: none;

        h3 {
            color: $sg_blue;
            background: none;
            padding: 0 0 0.3em 0;
            border-bottom: solid 1px $grey;
            margin-bottom: 1em;
        }
        .item-row {
            margin-bottom: 1.7em;
            .item-title {
                margin-left: 0;
                a {
                    font-weight: bold;
                }
                .date {
                    margin-top: 0.4em;
                }
            }
        }
    }
}

.news-item {
    .content-item-title,
    .content-item-date,
    p
    {
        margin-bottom: 1em;
    }

    .content-item-date {
        color: $textgrey;
    }

    .social-icons {
        float: none;
        text-align: left;
        margin-bottom: 1em;
        .addThis {
            margin: 0;
            float: none;
        }
    }

    .article > img {
        float: right;
        margin-left: 1em;
        margin-bottom: 1em;
    }

    .article {
        table {
            td {
                vertical-align: middle;
            }
        }
    }

}

.news-item,
.video-copy,
.future.conference.aims
{
    ul {
        list-style: disc inside;
    }

    ol {
        list-style: decimal inside;
        li::before {
            content: "";
            width: 0.5em;
            display: inline-block;
        }
    }

    ul,ol {
        line-height: 1em;
        margin-bottom: 1em;
        li {
            margin-left: 2em;
            margin-bottom: 0.5em;
        }
    }
}

.bespoke-news-item.as-journal-article {

    // this copies what the typesetters do for a full article
    > p {
        margin-top: 6pt!important;
        margin-bottom: 6pt!important;
        line-height: 150%!important;

        span {
            font-size: inherit!important;
        }
    }

    ul {
        list-style-type: disc;
        list-style-position: outside;
        li {
            margin-top: 6pt;
            margin-bottom: 6pt;
            line-height: 150%;
        }
    }
}
