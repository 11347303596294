#site-message {
    z-index: 0;
    width: 1000px;
    margin: 0 auto;
    padding-top: $site_message_padding_top_initial;
    padding-bottom: $site_message_padding_bottom_initial;
    .site-message-wrapper {
        padding-right: 5em;
        //width: 65%;
        width: 85%;
        p {
            color: $sg_blue;
            padding-bottom: 0.5em;
        }
    }
    div {
        display: inline-block;
    }
}

#site-message {
    max-height: $site_message_max_height_initial;

    animation:site-message-animation $site_message_animation_duration 1;
    -webkit-animation:site-message-animation $site_message_animation_duration 1;
    animation-fill-mode: forwards;

    animation-delay: $site_message_animation_delay;
    -webkit-animation-delay: $site_message_animation_delay; /* Safari and Chrome */
    -webkit-animation-fill-mode: forwards;
}

#site-message h1, #site-message div, #site-message p {
    max-height: $site_message_max_height_initial;

    animation: site-message-animation-sub-block $site_message_animation_duration 1;
    -webkit-animation: site-message-animation-sub-block $site_message_animation_duration 1;

    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    animation-delay: $site_message_animation_delay;
    -webkit-animation-delay: $site_message_animation_delay; /* Safari and Chrome */
}

@keyframes site-message-animation{
    @include site_message_transition();
}

@-webkit-keyframes site-message-animation{
    @include site_message_transition();
}

@keyframes site-message-animation-sub-block{
    @include site_message_transition_sub_block();
}

@-webkit-keyframes site-message-animation-sub-block{
    @include site_message_transition_sub_block();
}
