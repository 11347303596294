@mixin honeypot() {
    position: absolute;
    left: -5000px;
    @include transparent();
    //display: none!important; // cant do this - symfony forms complains
}

@mixin honeypot_test() {
    position: absolute;
    left: 70px;
}

/* from https://css-tricks.com/snippets/css/cross-browser-opacity/ */
@mixin transparent() {

    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

    /* IE 5-7 */
    filter: alpha(opacity=0);

    /* Netscape */
    -moz-opacity: 0;

    /* Safari 1.x */
    -khtml-opacity: 0;

    /* Good browsers */
    opacity: 0;
}