.video-speaker{
    background-color:#DFE3EC;
    font-weight:bold;
    padding:5px;
}
.video-categories{
    background-color:#DFE3EC;
    padding:5px;
    float:left;
    margin-top:20px;
}
.video-categories ul{
    list-style: none;
    padding:0;
    margin:0;
}
.video-categories ul li{
    list-style: none;
    display: inline;
    padding:0;
    margin:0;
}
.video-categories ul li{
    //padding:5px;
}

.video-categories ul li::after {
    content: " - ";
}
.video-categories ul li:first-child::after,
.video-categories ul li:last-child::after
{
    content: "";
}

.video-display-body {
    .addThis {
        margin-bottom: 1em;
    }
    // the following styling works for Vimeo because
    // Vimeo videos have a specific resolution and aspect ratio
    .video-container {
        margin-right: 1em;
        .kaltura_player {

            position: relative;
            padding-bottom: 56.25%;
            overflow: hidden;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

        }
        .video-btn-strip {
            a, button {
                display: inline;
            }
            .btn {
                margin-top: 0;
            }
        }
        // restrictions
        .kaltura_player.restricted {
            padding-bottom: initial;
            img {
                width: 100%;
                display: block;
            }
        }
        .resource-cover {

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            background-color: black;
            opacity: 0.7;
            filter: alpha(opacity=70); /* IE8- */

            .resource-cover-content {
                width: 75%;
                margin: 0 auto;
                font-size: 1.3em;
                line-height: 1.3em;
                margin-top: 1.5em;
                color: white;

                a {
                    color: $lightblue;
                }
                a:hover {
                    color: $lighterblue;
                }
            }
        }
        .covered-resource {
            position: relative;
        }
    }
    .video-detail-strip {
        font-size: $font_size_reduced;
        padding: 0.25em 1em 0.15em;
        background-color: $sg_gradient_grey_top;
        color: $textgrey;
        margin-bottom: 1em;
        div {
            display: inline;
        }
        .video-detail-published {
            margin-right: 3em;
        }
        .video-detail-rating {
            margin-right: initial;
            float: right;
            .icon-star {
                filter: invert(78%) sepia(64%) saturate(543%) hue-rotate(13deg) brightness(97%) contrast(95%);
            }
            .icon-star.white {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(248deg) brightness(107%) contrast(101%);
            }
        }
    }
    .results-grid {
        .grid-item {
            h3 {
                flex-grow: 1;
            }
        }
    }
    .conference.details {
        font-size: $font_size_reduced;
        .intro-image {
            @include background-image-contained();
        }
    }
}

// these are the video card-based grids
// for browse and related items

.video-display-body,
.conference-videos-body,
.video-browse-body,
.general-browse-results
{
    .results-grid {
        font-size: $font_size_reduced;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .grid-item {
            display: initial;
            width: 28%;
            .grid-item-title {
                font-size: 1.1em;
                height: 22%;
                margin-bottom: 1em;
            }
            .grid-item-subtitle {
                font-style: italic;
                color: $textgrey;
                height: 5em;
                margin-bottom: 1em;
                // flex-shrink:0;  // works better generally without this at getting videos to align.
            }
            .grid-item-image {
                margin-top: 0.5em;
                img {
                    width: 100%;
                    display: block;
                }
            }
            .grid-item-image-details {
                padding: 0.25em 0.1em 0.15em 0.5em;
                background-color: $sg_gradient_grey_top;
                color: $textgrey;
                margin-bottom: 1em;
                div {
                    display: inline;
                }
                .icon-star {
                    filter: invert(78%) sepia(64%) saturate(543%) hue-rotate(13deg) brightness(97%) contrast(95%);
                }
                .icon-star.white {
                    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(248deg) brightness(107%) contrast(101%);
                }
                .video-detail-rating {
                    float: right;
                    margin-right: 0.5em;
                }
            }
        }
    }
}

.video-display-body {
    .results-grid {
        .grid-item {
            width: 29%;
            .grid-item-title {
                max-height: 17%;
                height: 17%;
            }
            .grid-item-subtitle {
                flex-shrink: 0;
            }
            .grid-item-image {
            }
        }
    }
}

.video-browse-body {
    .video-carousel {
        margin-bottom: 1em;
        line-height:1.6em;
        .item-title {
            font-size: 1.25em;
        }
        .item-subtitle {
            font-style: italic;
        }
    }
}


.video-btn-strip{
    width:100%;
}
.video-btn-strip {
    .btn {
        margin: 1.5em 0.8em 0 0.8em;
        text-align: center;
    }
    .btn:first-child {
        margin-left:0;
    }
    .btn:last-child {
        margin-right:0;
    }
    button {
        margin-right: 0.8em!important;
    }
    .addThis{
        margin-top:35px;
    }
}


.video-copy .addThis{
    margin-top:10px;
    text-align: right;
}


// for Vimeo player advance buttons

table.vimeocuetable {

    font-size: small;

    td {
        padding: 0.75em;
    }

    button {
        border: solid 1px grey;
        border-radius: 3px;
    }

    td.sectionheader {
        font-weight: bold;
        font-size: 1.25em; color: black;
    }

    td p {
        margin: 0;
    }

    button:hover, button.active {
        border-color: rgb(49,146,187);
    }
}

// style static copy from database

.video-speaker {
    margin-bottom: 1em;
}

.video-copy {
    font-size: $font_size_reduced;
    p {
    }
}

.video-sponsor {
    font-size: $font_size_reduced;
    font-style: italic;
}

.transcript {
    font-size: $font_size_reduced;
    margin-bottom: 2em;

    .content {
        -webkit-transition: max-height 1s;
        -moz-transition: max-height 1s;
        -ms-transition: max-height 1s;
        -o-transition: max-height 1s;
        transition: max-height 1s;
        max-height: 0;
        overflow: hidden;

        strong {
            margin-right: 0.5em;
        }
        p {
            margin-top: 1em;
            margin-bottom: 1em;
            line-height: 150%;
            text-align: justify;
        }
    }
}
.transcript.active {
    .content {
        max-height: 50000px;
    }
}
