/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 1em;
  text-align: left;
  outline: none;
  transition: 0.4s;
  border:1px solid $lightgrey;

  .indicate {
    display: inline-block;
    min-width: 1.5em;
    font-size: 1.5em;
  }
  .indicate:before  {
    content: '+ ';
  }
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion.active,
.accordion:hover {
  background-color: #ccc;
}

.accordion.active {
  .indicate:before  {
    content: '-  ';
  }
}

/* Style the accordion panel. Note: hidden by default */
.accordion-panel {
  padding: 0 16em;
  background-color: white;
  display: none;
  overflow: hidden;

  .form-control label{
    width:80%;
    display: block;
    padding: 0.4em;
  }
  .form-control input[type=checkbox]{
    display: block;
    margin-right: 1em;
    float: left;
    margin-top: 1em;
  }
}

.accordion-wrapper.slimline {
  .accordion {
    padding: 0 0 0 1em;
  }

  .accordion-panel {
    padding: initial;
    overflow: initial;

    .form-control {
      input {
        display: inline;
        margin-right: 0.5em;
        float: none;
        margin-top: inherit;
        width: 3%;
      }
      label {
        width: 40%;
        display: inline-block;
        padding: initial;
        white-space: nowrap;
        font-size: 0.85em;
      }
    }
  }
}
