.snippet-container.grid.type-height-1 {
    .type {
        height: 1em;
    }
}

.snippet-container.grid.type-height-2 {
    .type {
        height: 2em;
    }
}

.snippet-container.grid.type-height-3 {
    .type {
        height: 3em;
    }
}

.snippet-container.grid.type-height-4 {
    .type {
        height: 4em;
    }
}

.snippet-container.grid.type-height-5 {
    .type {
        height: 5em;
    }
}

.snippet-container.grid.type-height-6 {
    .type {
        height: 6em;
    }
}

.snippet-container.grid.type-height-7 {
    .type {
        height: 7em;
    }
}

.snippet-container.grid.type-height-8 {
    .type {
        height: 8em;
    }
}

.snippet-container.grid.type-height-9 {
    .type {
        height: 9em;
    }
}

.snippet-container.grid.type-height-10 {
    .type {
        height: 10em;
    }
}

