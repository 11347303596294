#page-foot {
    //height: 100px;

    p {
        color:#FFF;
        margin-top:1px;
        width:100%;
    }

    .container-conference h1 {
        text-align:center;
        margin: 20px 0;
        color: $textwhite;
        line-height:1.2em;
    }
    .container-site-links {
        padding-bottom: 2em;
        h2 {
            margin:0;
        }
        p {
            margin: 0;
        }
        div.col {
            padding: 2em 1em;
            a {
                color:$sg_white;
            }
            a:hover {
                color: $body_text_color;
            }
            p {
                color: $textblue;
                padding-bottom: 1em;
                min-height: 2.5em;
            }
        }
        div.col:last-of-type {
            margin-right:0;
        }
        .bg-grey, .bg-light-green {
            .btn-blue {
                @include footer-card-button();
            }
            .btn-blue:hover {
                @include footer-card-button-hover();
            }
        }
        .bg-grey {
            background-color: #e4e7ea;
            border: solid 2px #dde1e6;
        }

        .bg-light-green {
            background-color: $sg_green;
            border: solid 2px #dde1e6;
        }

        form {
            input[type="submit"].sg-btn-cta {
                @include footer-card-button();
            }
            input[type="submit"].sg-btn-cta:hover {
                @include footer-card-button-hover();
            }
        }

    }

    .container-foot{
        padding:20px 0 20px 0;
        background-color: $darkblue;
        color:$white;
        div {
            font-size: 0.875em;
        }
        .foot-left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .foot-right{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
        }
        ul.foot-links{
            float:left;
            color:$white;
        }
    }

    // helps with long Spanish version which wraps
    .copyright {
        text-align: right;
    }
}

