body#page-sitemap {
    margin: 5%;
    padding: 0;
}
table#sitemap{
    width:100%;
}

table#sitemap {
    td, th {
        padding: 10px;
    }
}

table#sitemap th{
    background-color:$darkblue;
    color:$textwhite;
    text-align:left;
}
table#sitemap td.cell-image{
    padding:5px;
    width:200px;
}
table#sitemap td.cell-image img{
    width:200px;
}
table#sitemap tr:nth-child(even) {background: $lightgrey}
table#sitemap tr:nth-child(odd) {background: $white}