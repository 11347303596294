
.flex-row {
    display:block;
    margin:0;
}
.banner {
    text-align: center;
}

.widget-grid {

}
.widget-grid .widget-row,
.widget-grid-full .widget-row {
    margin-top: 1.5em;
}
.widget-grid .widget-row:first-child {
    margin-top:0;
}

.widget-row .panel:first-child {
    margin-left:0;
}
.widget-list{
    .widget-contents {
    }
    .item-row{
        padding-bottom: 0.2em;
    }
    .item-row:last-child {
        border:0;
    }
    .item-thumb {
        img {
            max-height: 150px;
            max-width: 150px;
            aspect-ratio: 31/18;
        }
        img:hover {
            @include image-opacity-80-percent();
        }
    }
    .conferences .item-thumb img {
        aspect-ratio: unset;
    }

    @include divider();
}
header.widget{
    padding-bottom: 10px;
}
.widget-title-link-container {
    float: right;
    position: relative;
    top: 1px;
}
.widget-title-container{
}

.widget-horizontal-list .item-thumb img{
    max-height:100px;
    max-width:182px;
}
.item-row .item-title{
    margin-left:10px;
}
.sidebar .item-row .item-title{
    margin-left:0;
}

.widget-list .item-row .item-title .date{
    margin-top:10px;
    display:block;
}
.widget-list footer
{
    float:left;
    width:100%;
}
.widget-list footer .view-all
{
    padding:10px;
}

.widget-horizontal-list .widget-contents{
    width:100%;
    padding:0;
    min-height:0;
}
.widget-horizontal-list .item-thumb{
    margin:10px;
    height:100px;
}
.item-thumb{


}
.item-row .item-thumb{
    //text-align:center;
}
.item-row .item-title{
    width:100%;
}
.widget-horizontal-list .menu-view-all{
    padding-left:350px;
}
.widget-horizontal-list .item-title{
    padding:0 10px 0 10px;
}


#widget-list-video_featured .col_2{
    flex: none;
}

#widget-list-video_featured .col_10{
    flex: none;
}
#widget-list-video_featured img{
    width:100%;
    max-height: none;
    max-width: none;
}
#widget-list-video_featured .widget-contents-video_featured{
    padding:0;
}
#widget-list-video_featured .item-title{
    line-height:24px;
    padding:10px;

}

#widget-list-video_featured .item-row{
    padding:0;
}
#widget-list-video_featured h2{
    margin-bottom: 0;
}

#search-results .item-thumb img{
    max-width:100px;
    max-height:100px;
}
#search-results.widget-list, #search-results.widget-list .item-row{
    border:none;
}
#search-filter-form form div{
   float: left;
}
.item-row {
    margin-bottom: 1.5em;
}

.container-flex-wrap.social-icons{
    margin-bottom:20px;
    justify-content:center;
}
.page-title-sub .social-icons{
    margin-bottom: 10px;
}
.social-icons h2{
    width:100%;
    text-align: center;
    margin-top:0;
}
.social-icons .icon{
    float: left;
    height: 25px;
    width: 25px;
    background-position: center;
    margin:2px;
}
.social-icons .icon:hover{
    opacity: 0.5;
    filter: alpha(opacity=50); /* For IE8 and earlier */
}
.icon-twitter {
    background-image: url(/images/layout/icon-twitter.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.icon-linkedin {
    background-image: url(/images/layout/icon-linkedin.png);
    background-repeat: no-repeat;
}

.icon-facebook {
    background-image: url(/images/layout/icon-facebook.png);
    background-repeat: no-repeat;
}
.icon-youtube {
    background-image: url(/images/layout/icon-youtube.png);
    background-repeat: no-repeat;
}
.icon-rss {
    background-image: url(/images/layout/icon-rss.png);
    background-repeat: no-repeat;
}

.addThis {
    zoom: 0.75;
    -moz-transform: scale(0.75);
    transform-origin: top left;
    .cta {
        float: left;
        background-color: $sg_gradient_grey_top;
        border-radius: 0.25em;
        padding: 0 0.75em 0 0.4em;
        margin-right: 0.25em;
        color: $textblack;
        font-size: 1.15em; // to compensate for the zoom
    }
}

.news-social .addThis{
    margin-right:10px;
    float:left;
}
.news-social{
}

.news-social .icon{
    float:left;
    display: block;
}
.news-social .rss-link{
    float:left;
}
.article-strip {
    .item-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    // this is a hack to remove tiny offset
    .item-details {
        position: relative;
        bottom: 0.1em;
    }
}
.author-names{
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.article-content{
    width:100%;
    word-wrap: break-word;
}
.mixin-word-wrap {
    @include word-wrap();
}
.article-content h1{
    line-height: 1em;
}

#widget-list-conference {

    .results-list {

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item-row {
            width: 49%; // critical
        }

    }

}

.widget-list.widget-list-journal {
    font-size: $font_size_reduced;
    border-bottom: none;
    .item-row {
        margin-bottom: 3em;
        img {
            height: 100%;
        }
        span.article-type {
            color: $sg_blue;
        }
        .item-title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

.results-list {
    img:hover {
        @include image-opacity-80-percent();
    }
}

.results-grid {
    .item-thumb {
        a:hover {
            @include image-opacity-80-percent();
        }
    }
}

// These are required for IE
.item-thumb {
    img {
        width: 100%;
    }
}

