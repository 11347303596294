.snippet {
    box-sizing: border-box; // essential otherwise padding causes snippet to grow outside 100% width
    font-family: Lato, Arial, SansSerif;
    //width: 100%;
    h2.title {
        @include page-title;
        margin-bottom: 0;
    }
    p {
        line-height: 1.3em;
        margin: 1em 0;
    }
    .strong {
        font-weight: 700;
        color: $sg_blue;
    }
    .donate-button  {
        text-align: right;
        input[type=submit]{
            @include sg-btn-blue();
            @include sg-btn-slim();
        }
        input[type=submit]:hover {
            @include sg-btn-blue-hover();
        }
    }
    .donate-button.right {
        text-align: right;
    }
    .donate-button.left {
        text-align: left;
    }
}
.snippet-container.unresponsive {
    .snippet {
        width: 100%
    }
}

.snippet.boxed {
    @include boxed;
    padding: 1em;
}
.snippet.cta1 {
    a {
        color: $sg_blue;
    }
    a:hover {
        color: $lightblue;
        @include menu-hover-state-using-margin;
    }
}
.snippet.cta1.boxed {
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.snippet.generic.article {

    border: solid 1px lightgrey;
    border-radius: 5px;
    margin-bottom: 1em;

    .title, .info, .author-names {
        padding: 1em;
    }
    .author-names {
        padding-top: 0;
    }
    .title {
        font-weight: bold;
    }
    .info {
        background-color: lightgrey;
        font-size: 0.85em;
        .date, .doi {
            margin-right: 2em;
        }

    }
}

.snippet.generic.article.simple {

    border: none;
    margin-bottom: 1em;

    .title, .info, .authors {
        padding: 0;
    }
    .authors {
        padding-top: 0;
        display: inline;
    }
    .title {
        font-weight: bold;
    }
    .date {
        display: inline-block;
    }
}

.snippet.generic.article.cell {
    a {
        text-decoration: none;
    }
}

.snippet.generic.article.image.cell {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    a {
        text-decoration: none;
    }
    .content {
        -webkit-transition: max-height 1s;
        -moz-transition: max-height 1s;
        -ms-transition: max-height 1s;
        -o-transition: max-height 1s;
        transition: max-height 1s;
        max-height: 0;
        overflow: hidden;

        .title, .author-names {
            background-color: white;
        }
    }
}

.snippet.generic.article.image.cell:hover {
    .content {
        max-height: 5000px;
    }
}

.snippet.generic-link-card-vertical {
    .card {

        .title {
            color: $sg_blue;
        }

        p {
            line-height: 1.25em;
        }

        .card-text.link {
            color: $sg_blue;
            text-decoration: underline;
        }

        .card-text.description {
            min-height: 8em;
        }
    }

    .boxed-bright {
        @include boxedBright();
    }
}

.bespoke-cards {

    .snippet.generic.article {

        border: none;
        margin-bottom: 1em;

        .thumbnail img {
            width: 100%;
        }

        .type {
            color: $blue;
        }

        .title {
            padding: 1em 0;
            font-weight: normal;
        }

        .date {
            font-size: 0.925em;
            padding-bottom: 1em;
            margin: 0;
        }

        .author-names {
            background-color: lightgrey;
            font-style: italic;
            padding: 0.2em;
        }
    }

}

.bespoke-cards.fullwidth.video {
    .video.snippet {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-bottom: 1em;

        .videoplayer {
            flex: 3;
            margin-right: 1em;

            .kaltura_player {

                position: relative;
                padding-bottom: 56.25%;
                overflow: hidden;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .info-wrapper {

            flex: 4;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .type {
                color: $blue;
            }

            .title {
            }

            .date {
                font-size: $font_size_reduced;
            }

            .author {
                background-color: lightgrey;
                font-style: italic;
                padding: 0.2em;
            }
        }

    }
}


.snippet-container.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1em;
    justify-content: center;
    font-size: 0.9em;

    .cell .info {
        font-size: initial;
    }
}

.snippet-container.grid.grid-2 {
    grid-template-columns: repeat(2, 1fr);
    .title {
        height: 3em;
    }
    .thumbnail img {
        height: 100px;
    }

}

.snippet-container.grid.grid-3 {
    grid-template-columns: repeat(3, 1fr);
    .title {
        height: 4em;
    }
    .thumbnail img {
        height: 100px;
    }
}

.snippet-container.grid.grid-4 {
    grid-template-columns: repeat(4, 1fr);
    .title {
        height: 7em;
    }
    .thumbnail img {
        height: 100px;
    }
}

.snippet-container.grid.grid-5 {
    grid-template-columns: repeat(5, 1fr);
    .title {
        height: 9em;
    }
    .thumbnail img {
        height: 100px;
    }
}

.snippet-container.image.grid {
    height: 188px;
    .thumbnail img {
        height: 100px;
    }
}

@import 'snippet-card-design-elements/title-height';
@import 'snippet-card-design-elements/date-height';
@import 'snippet-card-design-elements/type-height';
@import 'snippet-card-design-elements/author-names-height';

.snippet-container.select {
    .snippet {
        border: none;
        border-radius: inherit;
        margin-bottom: 0;
    }
    select {
        padding: .4em .8em;
        border-radius: 1em;
        background-color: #fff;
        border: 1px solid #ccc;
        font-size: .8em;
        option {
            padding: 1em;
        }
    }
}

h1.page-title.smaller {
    font-size: 1.1em;
    font-weight: 200;
}

h2.snippet {
    font-size: 1.4em!important;
    font-weight: bold;
}

p.snippet.text-size-reduced {
    font-size: $font_size_reduced;
}

p.half-line {
    line-height: 0.5em;
    height: 0.5em;
    margin: 0;
}

p.snippet.show-more a {
    text-decoration: none;
    color: $blue;
}

p.snippet.show-more a:hover {
    text-decoration: underline;
}

.snippet.addThis {
    margin-top: 1em!important;
}

.snippet.sg-btn-cta {
    padding: .3em .3em .3em .2em;
    text-align: center;
    font-weight: bold;

    a {
        color: $blue;
    }
}
.snippet-container.unresponsive {
    .snippet.sg-btn-cta {
        width: 88%;
    }
}

.snippet.sg-btn-cta:hover {
    color: $sg_white;
    a {
        color: $sg_white;
    }
}
.snippet.sg-btn-cta.hidden {
    visibility: hidden;
}

// sitewide modals and dialogs

snippets.hidden {
    display: none;
}

.snippet {
    .dialog p {
        color: black;
    }
}

// jquery UI dialog

.ui-dialog {
    background-color: $sg_blue;
    //margin-top: 15px; // moves it slightly further away form the top blue section

    .ui-widget-header {
        background: initial;
        border: none;
        color: white;
    }

    .ui-dialog-titlebar {
        font-size: 2em;
        line-height: 2em;
        height: 2em;
        padding-left: 0.5em;
    }
}

.ui-widget-overlay {
    background: black;
    opacity: 0.75;
}

.snippet.xmas20.inline {
    width: 1000px;
    margin: 3em auto;
    p.closer {
        text-align: right;
    }
    p.donate {
        font-size: 2em;
    }
}
div.ui-dialog.xmas20.modal {

    background: none;

    border: none;
    padding: 0;

    .ui-widget.ui-widget-content {
        border: none;
    }

    .ui-dialog-titlebar {
        position: absolute;
        z-index: 10000;
        right: 25px;
    }

    .ui-dialog-titlebar-close {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border-style: hidden;
    }

    .ui-dialog-content {
        overflow: hidden;
        padding: 0;
    }

    .donate-cover,
    .generic-cover
    {
        background: white;
        opacity: 0;
        bottom: 102px;
        right: 41px;
        height: 100px;
        width: 210px;
        z-index: 110;
        position: absolute;
    }

    .donate-cover:hover,
    .generic-cover:hover
    {
        border: solid 2px lightgrey;
        opacity: 0.1;
        border-radius: 10px;
    }

}

.no-close .ui-dialog-titlebar-close {
    display: none;
}

.pdf-viewer-container {
    width: 100%;
    position: relative;
}

#my-pdf-viewer {
    width: 100%;
    .arrow {
        display: inline-block;
        width: 10%;
        font-size: 8em;
        color: lightgrey;
        position: relative;
        bottom: 1.7em;
        text-align: center;
    }
    .arrows {
        margin-top: 1em;
        height: 3em;
        display: flex;
        flex-wrap: wrap;
        .arrow {
            width: 50%;
            font-size: 8em;
            height: 100%;
            color: lightgrey;
            text-align: center;
            position: initial;
            bottom: auto;
        }
    }
    .arrow:hover {
        color: grey;
        cursor: pointer;
    }
    .page-counter {
        width: 100%;
        color: grey;
        margin-top: 4em;
        text-align: center;
        div {
            display: inline-block;
            min-width: 2em;
        }
    }
    .page-counter.inside-arrows {
        margin-top: -2em;
    }
}
#my-pdf-viewer.hidden {
    display: none;
}


.snippet.generic.article.responsive {
    //border: none;
    //border-radius: 0;
    //margin-bottom: 0;
}

// Why is this required?
// taking this out as it is breaking Bootstrap responsive grid (row-cols-*) [JP 2022-07-11]
.snippet.responsive {
    //box-sizing: initial;
    //font-family: initial;
}
