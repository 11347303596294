#page-registration-validation {
    h1 {
        margin-bottom: 2em;
    }
    p {
        line-height: 2em;
    }
}

#page-registration {
    form[name="registration"] {
        .form-group.apiary {
            @include honeypot();
        }
    }
}