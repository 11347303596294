@font-face {
  font-family: 'Karbon-semibold';
  src: url('fonts/karbon/karbon-semibold-webfont.ttf'); /* IE */
  src: local('Karbon'), url('fonts/karbon/karbon-semibold-webfont.ttf');
}
@font-face {
  font-family: 'Karbon-bold';
  src: url('fonts/karbon/karbon-bold-webfont.ttf'); /* IE */
  src: local('Karbon'), url('fonts/karbon/karbon-bold-webfont.ttf') format('truetype');
}
@font-face {
  font-family: 'Karbon-light';
  src: url('fonts/karbon/karbon-light-webfont.ttf'); /* IE */
  src: local('Karbon'), url('fonts/karbon/karbon-light-webfont.ttf');
}
@font-face {
  font-family: 'Karbon-medium';
  src: url('fonts/karbon/karbon-medium-webfont.ttf'); /* IE */
  src: local('Karbon'), url('fonts/karbon/karbon-medium-webfont.ttf');
}
@font-face {
  font-family: 'Karbon';
  src: url('fonts/karbon/karbon-regular-webfont.ttf'); /* IE */
  src: local('Karbon'), url('fonts/karbon/karbon-regular-webfont.ttf') format('truetype');
}
@font-face {
  font-family: 'Karbon-regular-italic';
  src: url('fonts/karbon/karbon-regularitalic-webfont.ttf'); /* IE */
  src: local('Karbon'), url('fonts/karbon/karbon-light-webfont.ttf');
}
