@mixin word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin menu-hover-state() {
    text-decoration: underline;
    text-decoration-color: $text_underline_color;
    //text-underline-position: under; // this is not working in Chrome !?
}

@mixin menu-hover-state-using-margin() {
    border-bottom: solid 2px $text_underline_color;
}
@mixin menu-hover-state-using-margin-high-contrast() {
    border-bottom: solid 3px #d6e626;
}
@mixin menu-hover-state-using-margin-dummy() {
    border-bottom: solid 2px $white;
}

@mixin page-title() {
    margin:0;
    line-height: 1em;
    font-size: 1.5em;
    font-weight: 600;
    color: $sg_blue;
    border-left: solid 0.3em $sg_green;
    padding-left: 0.4em;
    margin-bottom: 0.3em;
}

@mixin leading-block() {
    margin:0;
    border-left: solid 0.3em $sg_green;
    padding-left: 0.4em;
    margin-bottom: 0.3em;
}

@mixin site_message_transition() {
    from {opacity : 1; max-height: $site_message_max_height_initial; padding-top: $site_message_padding_top_initial; padding-bottom: $site_message_padding_bottom_initial;}
    to {opacity : 0; max-height: 0; padding-top: 0; padding-bottom: 0;}
}

@mixin site_message_transition_sub_block() {
    from {opacity : 1; max-height: $site_message_max_height_initial;}
    to {opacity : 0; max-height: 0;}
}

