/** Base colors **/
$lightgreen : #d8e523;
$darkblue : #0056b4;
$darkerblue: #003e82;
$lightblue : #599fe2;
$lighterblue : #62b2ff;
$verylightgrey : #f8f8f8;
$quitelightgrey : #f0f0f0;
$lightgrey : #ccc;
$lightergrey : #e7e7e7;
$blue : #0056b4;
$grey : #cccccc;
$white: #FFF;
$black: #000;
$orange: orange;

/** from style guide **/
$sg_green : #cbd921;
$sg_blue : #0056b4;
$sg_white : #e9ecf4;
$sg_grey : #c8ced6;

/** style guide: buttons **/
$sg_button_normal : $sg_blue;
$sg_blue_button_hover : #1974d7; // !?
$sg_button_selected : $sg_green;
$sg_button_hover : #b3bd35;
$sg_button_disabled : $sg_grey;

/** style guide: gradients **/
$sg_gradient_grey_top : #dfe3ec;
$sg_gradient_grey_bottom : #c8c8c8;
$sg_gradient_blue_top : #0071e8;
$sg_gradient_blue_bottom : #0055bb;
$sg_gradient_grey_breakpoint : #c8c8c8;
$sg_gradient_blue_breakpoint : #0056b4;
$sg_gradient_percent : 75%;
$button_text : #434343;

/** TEXT **/
$text_underline_color: #c6d420;
$body_text_color: #2d2d2d;
$textblue :  $darkblue;
$textgrey : #646466;
$textblack: $body_text_color;
$textwhite: $white;
$texthover : $blue;

/** LINES **/
$horizontal_divider_line : #dedede;

/** LINKS **/
$linkHoverColor: $lightblue;
$linkActiveColor: $darkblue;
$linkStandardColor: $textblack;

/** ERRORS **/
$errorBg: #eeabab;
$errorText: #9a1e1e;
$errorBorder: #6b1717;

/** Alert Success **/
$alertSuccessBG: #d6ecd8;
$alertSuccessText: $black;

/** Alert Warning **/
$alertWarningBG: #e7b396;
$alertWarningText: $black;

/** Alert Error **/
$alertErrorBG: $errorBg;
$alertErrorText: $errorText;

/** Journal Articles have specific requirements **/
$journalArticleLinkStandardColor: $lightblue;
$journalArticleLinkHoverColor: $darkblue;
$journalArticleLinkActiveColor: $darkblue;

/** Journal branding **/
$journalTitleBrandingPart2Color: #a6c7e9;