#page-user .page-title{
  margin-top: 20px;
}
.widget-grid-user-dashboard{
 border:0;
}
.widget-grid-user-dashboard {
    .col-12 {
        display: flex;
        flex-direction: column;
    }

    .btn{
        height:100%;
        margin:10px 0;
        padding:20px;
        display: flex;
        align-items: center;
    }
    .btn-label {
        width: 100%;
        word-wrap: break-word;
        display: inline-block;
    }
    .btn i, .btn-green svg {
        text-align: right;
        float:right;
        margin-left:10px;
        fill:$button_text;
        height: 100%;
    }
    .btn.icon-video-camera::after, .btn.icon-user::after {
        width:1rem;
        height:1rem;
        background-size:1rem;
        margin: 0 0 0 0.4rem;
        filter: invert(25%) sepia(0%) saturate(1%) hue-rotate(161deg) brightness(96%) contrast(90%);
    }
}

.widget-grid-user-dashboard , .widget-grid-user-dashboard {

}

#login-content{
    margin-top:150px;
}
#page-login{
    margin-top:20px;
    margin-left:auto;
    margin-right:auto;
    width:50%;
    background-color:#f4f4f4;
    padding:20px;
    border:1px solid #BBB;

    .row.buttons {
        margin-top: 2em;
    }

    .hidden.iframe {
        height: 0.1em;
    }
}

.forgotten-password {
    .page-title {
        margin-bottom: 1.5em;
    }
    form {
        label {
            width: 5em;
        }
        input {
            width: 100%;
        }
    }
    .buttons.row {
        margin-top: 2em;
    }
}

// dashboard
.container-flex.user {

    .breadcrumbs {
        margin-bottom: 1em;
    }
    .content-specific-nav {
        margin-bottom: 1em;
    }

    // EPL-275 This ensures thumbnails fill column even when some older thumbnails are under-sized.
    .widget-list {
        .item-row {
            width: 100%;
        }
    }

    .icon-star::after {
        height: 1rem;
        width: 1rem;
        background-size: 1rem;
        filter: invert(10%) sepia(50%) saturate(19%) hue-rotate(316deg) brightness(98%) contrast(83%);
    }
}

.ui-autocomplete { max-height: 20em; overflow-y: auto; overflow-x: hidden;}
